<ng-container *ngIf="orderReady && authenticated">    
    <div class="compact padded">    
        <div class="page">
            <div class="page-left">
                <div class="simplemenu">
                    <ul>
                        <li *ngFor="let p of pagesAcc"><a [routerLink]="'/'+currentLang.slug+'/account/'+p.slug">{{p.name[currentLang.slug]}}</a></li>
                    </ul>
                </div>
                <div class="slidemenu">
                    <slide-menu [head]="currentLang.s['history']['history']" urlprefix="account" [pages]="pagesAcc"></slide-menu>
                </div>
            </div>
            <div class="page-right">
                <div id="breadcrumbs">
                    <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>            
                    <span><a routerLink="/{{currentLang.slug}}/account/history">{{currentLang.s['history']['history']}}</a></span>            
                </div>        
                <h1>{{currentLang.s['history']['order']}} #{{order.id}}</h1>                                
                <div id="orderitems">
                    <div class="oi" *ngFor="let oi of order.orderitems">
                        <div class="oi-img" [style.background-image]="'url(/images/'+oi.img+')'"></div>
                        <div class="oi-name" [innerHTML]="oi.name[currentLang.slug]"></div>
                        <div class="oi-price">{{oi.q}} x {{oi.price}} {{oi.currency}}</div>                        
                        <div class="oi-code">{{currentLang.s['item']['code']}}: {{oi.code}}</div>
                        <!--<div class="oi-review" *ngIf="oi.catslug && oi.itemslug"><a href="/{{currentLang.slug}}/shop/{{oi.catslug}}/{{oi.itemslug}}#reviews"><button>{{currentLang.s['history']['review']}}</button></a></div>-->
                        <div class="oi-return"><a routerLink="/{{currentLang.slug}}/account/history/{{order.id}}/retturn/{{oi.id}}">{{currentLang.s['history']['return']}}</a></div>
                    </div>
                </div>                
            </div>        
        </div>            
    </div>        
</ng-container>