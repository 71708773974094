import { Injectable } from "@angular/core";

import { DataService } from './data.service';
import { AppService } from './app.service';
import { User } from '../model/orm/user.model';
import { ILoginDTO } from '../model/dto/login.dto';
import { IRegisterDTO } from '../model/dto/register.dto';
import { IAnswer } from '../model/answer.interface';

@Injectable()
export class AuthService {
    public user: User = null;    
    
    constructor(
        private dataService: DataService,
        private appService: AppService,
    ) {
        this.init();
    }

    get authenticated(): boolean {return this.user !== null;}

    private init(): void {
        if (this.appService.isBrowser) {
            const data: string = localStorage.getItem("user");
            data ? this.user = JSON.parse(data) : null;            
        }   
    }

    public login(email: string, password: string): Promise<number> {
        return new Promise((resolve, reject) => {
            let dto: ILoginDTO = {email, password};
            this.dataService.userLogin(dto).subscribe(res => {
                if (res.statusCode === 200) {                                  
                    this.user = new User().build(res.data);
                    this.save();                       
                } 

                resolve(res.statusCode);
            }, err => {
                reject(err.message);
            });
        });        
    }

    public register(dto: IRegisterDTO): Promise<number> {
        return new Promise((resolve, reject) => {            
            this.dataService.userRegister(dto).subscribe(res => {                                
                if (res.statusCode === 200) {
                    this.user = new User().build(res.data);
                    this.save();    
                } else {
                    console.log(res);
                }

                resolve(res.statusCode);
            }, err => {
                reject(err.message);
            });
        });
    }

    public registerNotification(dto: IRegisterDTO): Promise<IAnswer<void>> {
        return new Promise((resolve, reject) => {
            this.dataService.userNotifyAboutRegistration(dto).subscribe(res => resolve(res), err => reject(err.message));
        });
    }

    public recover(email: string): Promise<number> {
        return new Promise((resolve, reject) => {
            this.dataService.userRecover(email).subscribe(res => {
                resolve(res.statusCode);
            }, err => {
                reject(err.message);
            });
        });
    }

    public update(user: User): Promise<void> {
        return new Promise((resolve, reject) => {
            this.dataService.userUpdate(user).subscribe(res => {
                if (res.statusCode === 200) {
                    resolve();
                } else {
                    reject(res.error);
                }
            }, err => {
                reject(err.message);
            });
        });
    }
    
    public logout(): void {
        this.user = null;
        localStorage.removeItem("user");                        
    }

    public save(): void {
        this.user ? localStorage.setItem("user", JSON.stringify(this.user)) : null;        
    } 
}