import { Component } from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: "login-panel",
    templateUrl: "./loginpanel.component.html",
    styleUrls: ["../../common.styles/account.panel.scss"]
})
export class LoginPanelComponent {
    public email: string = "";
    public password: string = "";
    public loading: boolean = false;
    public msg: string = "&nbsp;"
    public error: boolean = false;
    
    constructor(
        private appService: AppService,
        private authService: AuthService,
        private router: Router,
    ) {}

    get active(): boolean {return this.appService.loginpanelActive;}
    set active(v: boolean) {this.appService.loginpanelActive = v;}
    get currentLang(): Lang {return this.appService.currentLang.value;}
    set registerpanelActive(v: boolean) {this.appService.registerpanelActive = v;}
    set recoverpanelActive(v: boolean) {this.appService.recoverpanelActive = v;}

    public async login(): Promise<void> {
        if (!this.validate()) {
            return;
        }

        try {
            this.loading = true;
            const status: number = await this.authService.login(this.email, this.password);
            this.loading = false;

            if (status === 200) {  
                this.msg = this.currentLang.s['user']['login-ok'];              
                setTimeout(() => {this.msg = "&nbsp;"; this.active = false;}, 1000);   
                this.appService.url[3] === "checkout-start" ? this.router.navigateByUrl(`/${this.currentLang.slug}/shop/checkout-continue`) : null;
            } else {
                this.msg = this.currentLang.s['user']['login-failed'];
                this.error = true;
                setTimeout(() => {this.msg = "&nbsp;"; this.error = false;}, 3000);
            }
        } catch (err) {
            this.appService.showNotification(err, "error");
            this.loading = false;
        }
    }

    private validate(): boolean {
        this.email = this.email.trim();
        this.password = this.password.trim();        
        
        if (!this.email.length || !this.password.length) {
            return false;
        }

        return true;
    }
}