<div *ngIf="ready && guides.length" class="sizeguides" [class.active]="active" (click)="onClick($event);">
    <div class="sg-win">
        <div class="sg-close" (click)="close();"></div>
        <div class="sg-content">
            <div class="sg-head">{{currentLang.s['item']['sizeguide']}}</div>
            <div class="sg-desktop-buttons">
                <div class="sgd-button" *ngFor="let g of guides; let i = index;" [class.active]="i === current" (click)="current = i;">{{g.name[currentLang.slug]}}</div>                
            </div>
            <div class="sg-mobile-buttons">
                <div class="sgm-current" [class.active]="mobileButtonsActive" (click)="mobileButtonsActive = !mobileButtonsActive;">{{guides[current].name[currentLang.slug]}}</div>
                <div class="sgm-buttonswrap" [class.active]="mobileButtonsActive">
                    <div class="sgm-buttonswrap2">
                        <div class="sgm-button" *ngFor="let g of guides; let i = index;" [class.active]="i === current" (click)="current = i; mobileButtonsActive = false;">{{g.name[currentLang.slug]}}</div>                    
                    </div>                        
                </div>
            </div>
            <div class="sg-text">
                <div class="sg-textwrap" [innerHTML]="guides[current].content[currentLang.slug]"></div>
            </div>            
        </div>
    </div>
</div>
