export class StatisticsGoogleProductView {
    constructor(
        public currencyCode: string = '',
        public impressions: {
            id: string,
            name: string,         // Name or ID is required.
            price: string,
            category: string,
            variant?: string
       }[]
    ) {}
}
