import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IOrderitem } from 'src/app/model/orm/orderitem.interface';
import { Order } from 'src/app/model/orm/order.model';
import { AppService } from 'src/app/services/app.service';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { OrdersRepository } from 'src/app/services/repositories/orders.repository';
import { AuthService } from 'src/app/services/auth.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { Page } from 'src/app/model/orm/page.model';
import { User } from 'src/app/model/orm/user.model';
import { RetturnDTO } from 'src/app/model/dto/retturn.dto';
import { CartService } from 'src/app/services/cart.service';

@Component({
    selector: "retturn-page",
    templateUrl: "./retturn.page.html",
    styleUrls: ['../../../common.styles/userdata.scss'],
})
export class RetturnPage implements OnInit {
    public page: Page = null;
    public pageReady: boolean = false;
    public order: Order = null;    
    public orderReady: boolean = false;    
    public orderitem: IOrderitem = null;
    public orderitemReady: boolean = false;    

    public dto: RetturnDTO = new RetturnDTO();
    public formLoading: boolean = false;
    public formMsg: string = "&nbsp;"
    public formError: boolean = false;  
    public formAgree1: boolean = true;
    public formAgree2: boolean = true;
    public formSent: boolean = false;
    public errorQ: string = "&nbsp;";
    public errorReason: string = "&nbsp;";
    public errorBankdetails: string = "&nbsp;";

    constructor(
		private appService: AppService,		
		private route: ActivatedRoute,
        private router: Router,        
        private pagesRepository: PagesRepository,	
        private ordersRepository: OrdersRepository,		
        private authService: AuthService,
        private cartService: CartService,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}	
    get authenticated(): boolean {return this.authService.authenticated;}
	get pagesAcc(): Page[] {return this.pagesRepository.pagesAcc;}
    get user(): User {return this.authService.user;}	
    
    public ngOnInit(): void {
        try {
            this.appService.headerAlwaysOpaque = true;		
            this.appService.catmenumobileActive = false;	
            this.appService.collmenumobileActive = false;
            this.page = this.pagesRepository.xl.find(x => x.slug === "retturn") || null;

            if (!this.page) {
                this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
            } else {
                this.pageReady = true;
                this.route.params.subscribe(async p => {
                    this.setMeta();

                    if (this.authenticated) {
                        this.orderReady = false;
                        this.orderitemReady = false;
                        let orderId: number = parseInt(p['orderid']);
                        let orderitemId: number = parseInt(p['orderitemid']);
                        await this.ordersRepository.load(this.user.id);
                        this.order = this.ordersRepository.xl.find(x => x.id === orderId) || null;

                        if (!this.order) {
                            this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
                        } else {
                            this.orderReady = true;
                            this.orderitem = this.order.orderitems.find(oi => oi.id === orderitemId) || null;

                            if (!this.orderitem) {
                                this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
                            } else {
                                this.dto.orderitem_id = orderitemId;
                                this.orderitemReady = true;
                            }
                        }
                    }                    
                });
            }                
        } catch (err) {
            this.appService.showNotification(err, "error");
        }
    }

    private setMeta(): void {
        this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);
    }   
    
    public async send(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            this.formLoading = true;
            this.formMsg = this.currentLang.s['history']['return-sending'];
            let retturnId: number = await this.cartService.sendRetturn(this.dto);            
            this.cartService.sendRetturnNotification(retturnId);
            this.formLoading = false;
            this.formMsg = "&nbsp;";
            this.formSent = true;
        } catch (err) {
            this.appService.showNotification(err, "error");
            this.formLoading = false;
            this.formMsg = "&nbsp;";
        }
    }

    private validate(): boolean {
        let error: boolean = false;
        this.dto.q = Math.round(this.dto.q);
        this.dto.bankdetails = this.dto.bankdetails.trim();
        this.dto.reason = this.dto.reason.trim();

        if (!Number.isInteger(this.dto.q) || this.dto.q <= 0) {
            this.errorQ = this.currentLang.s['history']['return-q-error'];
            error = true;
        } else {
            this.errorQ = "&nbsp;";
        }

        if (!this.dto.reason.length) {
            this.errorReason = this.currentLang.s['common']['error-required'];
            error = true;
        } else {
            this.errorReason = "&nbsp;"
        }

        if (!this.dto.bankdetails.length) {
            this.errorBankdetails = this.currentLang.s['common']['error-required'];
            error = true;
        } else {
            this.errorBankdetails = "&nbsp;"
        }

        return !error;
    }
}