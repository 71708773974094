<div class="wishlist-toggle" [class.wishlist-saved]="hasItem" [class.wishlist-toggle-single]="getIsOnSingleItem" (click)="toggleWishlist($event)">
    <svg *ngIf="!hasItem" width="15px" height="100%" fill="white" viewBox="0 0 1054.86 965.31">
      <defs>
        <style>.cls-empty {
          stroke: white;
          stroke-miterlimit: 10;
          stroke-width: 34px;
        }</style>
      </defs>
      <path class="cls-empty"
            d="M1039.66,296.18h0c.15-3.51.28-7,.28-10.58,0-142.47-120.16-266-268.39-266-108.37,0-201.68,69.78-244,158.66h-.06C485,89.41,391.72,19.63,283.35,19.63,135.12,19.63,15,143.13,15,285.6c0,3.55.13,7.07.28,10.58h0S.05,465.43,188.4,650.21C274,736,369.8,817.55,527.43,945.67c157.63-128.12,253.4-209.66,339-295.46C1054.81,465.43,1039.66,296.18,1039.66,296.18Z"/>
    </svg>
    <svg *ngIf="hasItem" width="15px" height="100%" fill="#cd2027" viewBox="0 0 1054.86 965.31">
      <defs>
        <style>.cls-filled {
          stroke: #cd2027;
          stroke-miterlimit: 10;
          stroke-width: 34px;
        }</style>
      </defs>
      <path class="cls-filled" d="M1039.66,296.18h0c.15-3.51.28-7,.28-10.58,0-142.47-120.16-266-268.39-266-108.37,0-201.68,69.78-244,158.66h-.06C485,89.41,391.72,19.63,283.35,19.63,135.12,19.63,15,143.13,15,285.6c0,3.55.13,7.07.28,10.58h0S.05,465.43,188.4,650.21C274,736,369.8,817.55,527.43,945.67c157.63-128.12,253.4-209.66,339-295.46C1054.81,465.43,1039.66,296.18,1039.66,296.18Z"/>
      <animateTransform
        attributeName="transform"
        type="scale"
        values="1; 1.3; 1.15; 1.3; 1.15; 1;"
        dur="1s"
        repeatCount="1">
      </animateTransform>
    </svg>
</div>
