export class StatisticsGooglePurchase {
    constructor(
      public actionField: {
        id: string,                         // Transaction ID. Required for purchases and refunds.
        affiliation?: string,
        revenue?: string,                     // Total transaction value (incl. tax and shipping)
        tax?: string,
        shipping?: string,
        coupon?: string
      },
      public products: {                            // List of productFieldObjects.
        name: string,     // Name or ID is required.
        id: string,
        price?: string,
        brand?: string,
        category?: string,
        variant?: string,
        quantity?: number,
        coupon?: string                           // Optional fields may be omitted or set to empty string.
       }[]
    ) {}
}
