import { Component, Input, AfterViewInit, ViewChild, ElementRef, OnInit, Output, EventEmitter } from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { Currency } from 'src/app/model/orm/currency.model';

@Component({
    selector: "range-slider",
    templateUrl: "./rangeslider.component.html",
    styleUrls: ["./rangeslider.component.scss"]
})
export class RangeSliderComponent implements OnInit, AfterViewInit {
    @Input() min: number;
    @Input() max: number;
    @Input() umin: number;
    @Input() umax: number;    
    @Output() uminChange: EventEmitter<number> = new EventEmitter();
    @Output() umaxChange: EventEmitter<number> = new EventEmitter();
    public current: number[] = [-1, -1];    
    public ready: boolean = false;
    private w: number;
    @ViewChild("wrap", {static: false}) wrapRef: ElementRef;
    private wrap: HTMLElement = null;
    public left: number[] = [-1, -1];    
    private a: number;
    private b: number;
    // movement    
    private activeHandle: number = -1;
    private startX: number = 0;
    private startXOffsetted: number = 0;   
    
    constructor(private appService: AppService) {}

    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}

    public ngOnInit(): void {
        this.onDragMove = this.onDragMove.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.current[0] = this.umin;
        this.current[1] = this.umax;
    }

    public ngAfterViewInit(): void {        
        setTimeout(() => {
            if (this.max !== this.min) {
                this.wrap = this.wrapRef.nativeElement;
                this.w = this.wrap.offsetWidth;

                if (this.w) {
                    this.b = -this.min;
                    this.a = this.w / (this.max - this.min);
                    this.left[0] = Math.round(this.toLocalCoord(this.current[0]));
                    this.left[1] = Math.round(this.toLocalCoord(this.current[1]));
                    this.ready = true;
                }               
            }
        }, 1);
    }

    public toLocalCoord(x: number): number {
        return this.a * (x + this.b);
    }

    public onDragStart(n: number, event: MouseEvent | TouchEvent) {        
        this.startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        this.startXOffsetted = this.startX - this.wrap.offsetLeft;          
        this.activeHandle = n; 
        event.cancelable ? event.preventDefault() : null; // sometimes touch events throws errors on "preventDefault" call without such check
        
        if (event instanceof MouseEvent) {
            window.addEventListener("mousemove", this.onDragMove);
            window.addEventListener("mouseup", this.onDragEnd);            
        } else {            
            window.addEventListener("touchmove", this.onDragMove);
            window.addEventListener("touchend", this.onDragEnd);    
        }        
    }

    private onDragMove(event: MouseEvent | TouchEvent): void {        
        const x: number = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        let nextX: number = x - (this.startX - this.startXOffsetted);
        let canMove: boolean = false;
        
        if (this.activeHandle === 0) {
            if (nextX >= 0 && nextX <= this.left[1] - 20) {
                canMove = true;       
            }
        } 

        if (this.activeHandle === 1) {
            if (nextX >= this.left[0] + 20 && nextX <= this.w) {
                canMove = true;
            }
        }
        
        if (canMove) {
            this.left[this.activeHandle] = nextX;
            this.current[this.activeHandle] = Math.round(nextX / this.a - this.b);
        }
    } 
    
    private onDragEnd(): void {
        window.removeEventListener("mousemove", this.onDragMove);
        window.removeEventListener("touchmove", this.onDragMove);
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("touchend", this.onDragEnd);
        
        if (this.activeHandle === 0) {
            this.uminChange.emit(this.current[0]);
        }

        if (this.activeHandle === 1) {
            this.umaxChange.emit(this.current[1]);
        }

        this.activeHandle = -1;        
    }  
    
    public formatPrice(p: number): string {
        return `${Math.ceil(p * this.currentCurrency.rate)} ${this.currentCurrency.name}`;
    }
}
