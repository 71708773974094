<ng-container *ngIf="pageReady">    
    <div class="compact padded">    
        <div id="breadcrumbs">
            <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>                        
        </div>        
        <h1 [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></h1>
        <div class="content" >
            <div>{{currentLang.s['checkout']['orderno']}}: {{orderNo}}</div>
            <div [innerHTML]="page.content[currentLang.slug]"></div>
        </div>
    </div>        
</ng-container>
