<header
    [class.opaque]="opaque || alwaysOpaque || catmenudesktopActive || collmenudesktopActive || collmenudesktopActive1 || searchActive"
    [class.compact]="compact"
    [class.with-pre-header]="isShowPreHeader"
>
    <div class="h-top">
        <div class="h-logo">
            <a *ngIf="!isShowOnCheckoutPage" [routerLink]="currentLang.defended ? '/' : '/'+currentLang.slug" (click)="onLinkClick();"><div class="hl-img"></div></a>
            <div *ngIf="isShowOnCheckoutPage"><div class="hl-img"></div></div>
        </div>
        <div *ngIf="!isShowOnCheckoutPage" class="h-right">
            <ul class="wide">
                <li *ngIf="!authenticated && false" (click)="onLoginClick();">{{currentLang.s['user']['login']}}</li>
                <li *ngIf="authenticated && false" (click)="onLinkClick();"><a routerLink="/{{currentLang.slug}}/account/private">{{currentLang.s['user']['acc']}}</a></li>
                <li (click)="onLinkClick();"><a routerLink="/{{currentLang.slug}}/shop/wishlist"><span>{{currentLang.s['wishlist']['title']}}</span>&nbsp;<span *ngIf="wishlist.records.length">({{wishlistQ}})</span></a></li>
                <li (click)="onCartClick();"><span>{{currentLang.s['common']['cart']}}</span>&nbsp;<span *ngIf="cart.records.length">({{cartQ}})</span></li>
            </ul>
            <ul class="compact">
                <li class="wishlist" [class.wishlist-not-empty]="wishlist.records.length" (click)="onLinkClick();">
                  <a routerLink="/{{currentLang.slug}}/shop/wishlist">
                    <svg *ngIf="!wishlist.records.length" width="22px" height="100%" fill="transparent" viewBox="0 0 1054.86 965.31">
                      <defs>
                        <style>.cls-h-empty {
                          stroke: black;
                          stroke-miterlimit: 10;
                          stroke-width: 34px;
                        }</style>
                      </defs>
                      <path class="cls-h-empty"
                            d="M1039.66,296.18h0c.15-3.51.28-7,.28-10.58,0-142.47-120.16-266-268.39-266-108.37,0-201.68,69.78-244,158.66h-.06C485,89.41,391.72,19.63,283.35,19.63,135.12,19.63,15,143.13,15,285.6c0,3.55.13,7.07.28,10.58h0S.05,465.43,188.4,650.21C274,736,369.8,817.55,527.43,945.67c157.63-128.12,253.4-209.66,339-295.46C1054.81,465.43,1039.66,296.18,1039.66,296.18Z"/>
                    </svg>
                    <svg *ngIf="wishlist.records.length" width="22px" height="100%" fill="#cd2027" viewBox="0 0 1054.86 965.31">
                      <defs>
                        <style>.cls-h-filled {
                          stroke: #cd2027;
                          stroke-miterlimit: 10;
                          stroke-width: 34px;
                        }</style>
                      </defs>
                      <path class="cls-h-filled" d="M1039.66,296.18h0c.15-3.51.28-7,.28-10.58,0-142.47-120.16-266-268.39-266-108.37,0-201.68,69.78-244,158.66h-.06C485,89.41,391.72,19.63,283.35,19.63,135.12,19.63,15,143.13,15,285.6c0,3.55.13,7.07.28,10.58h0S.05,465.43,188.4,650.21C274,736,369.8,817.55,527.43,945.67c157.63-128.12,253.4-209.66,339-295.46C1054.81,465.43,1039.66,296.18,1039.66,296.18Z"/>
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        values="1; 1.3; 1.15; 1.3; 1.15; 1;"
                        dur="1s"
                        repeatCount="1">
                      </animateTransform>
                    </svg>
                  </a>
                </li>
                <li class="cart" (click)="onCartClick();">
                    <div class="h-cartq" *ngIf="cart.records.length">{{cartQ}}</div>
                </li>
            </ul>
        </div>
        <div class="h-left-desktop">
            <div class="hld-logo">
                <a *ngIf="!isShowOnCheckoutPage" [routerLink]="currentLang.defended ? '/' : '/'+currentLang.slug" (click)="onLinkClick();"><img src="/images/templates/visitor/logo-chocolate.svg"></a>
                <div *ngIf="isShowOnCheckoutPage"><img src="/images/templates/visitor/logo-chocolate.svg"></div>
            </div>
            <ul *ngIf="!isShowOnCheckoutPage">
                <li><a [routerLink]="currentLang.defended ? '/' : '/'+currentLang.slug" (click)="onLinkClick();">{{currentLang.s['common']['home']}}</a></li>
                <li *ngFor="let c of topcats"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="onLinkClick();">{{c.name[currentLang.slug]}}</a></li>
                <ng-container *ngFor="let p of pages">
                    <ng-container *ngIf="p.slug !== 'shop' && p.slug !== 'collections' && p.slug !== 'collections1'">
                        <li>
                            <a [routerLink]="'/'+currentLang.slug+'/'+p.slug" (click)="onLinkClick();">{{p.name[currentLang.slug]}}</a>
                        </li>
                    </ng-container>

                    <ng-container *ngIf="p.slug === 'shop'">
                        <li (mouseover)="catmenudesktopMouseIn()" (mouseout)="catmenudesktopMouseOut()">
                            <a>
                                {{p.name[currentLang.slug]}}
                            </a>
                            <div class="h-catmenu-desktop">
                                <div class="hcd-content">
                                    <ul>
                                        <li *ngFor="let c of cats"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="catmenudesktopActive = false;">{{c.name[currentLang.slug]}}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ng-container>


                    <ng-container *ngIf="p.slug === 'collections1'">
                        <li (mouseover)="collmenudesktopMouseIn1()" (mouseout)="collmenudesktopMouseOut1()">
                            <a>
                                {{p.name[currentLang.slug]}}
                            </a>
                            <div class="h-catmenu-desktop">
                                <div class="hcd-content">
                                    <ul>
                                        <li *ngFor="let c of cats1;let i = index" [class.current]="i === 0"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="collmenudesktopActive1 = false;">{{c.name[currentLang.slug]}}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ng-container>

                    <ng-container *ngIf="p.slug === 'collections'">
                        <li (mouseover)="collmenudesktopMouseIn()" (mouseout)="collmenudesktopMouseOut()">
                            <a>
                                {{p.name[currentLang.slug]}}
                            </a>
                            <div class="h-catmenu-desktop">
                                <div class="hcd-content">
                                    <ul>
                                        <li *ngFor="let c of collections"><a routerLink="/{{currentLang.slug}}/collections/{{c.slug}}" (click)="collmenudesktopActive = false;">{{c.name[currentLang.slug]}}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
        <div *ngIf="!isShowOnCheckoutPage" class="h-left-mobile">
            <div class="hlm-menubtn" (click)="mobmenuActive = true;"></div>
        </div>
    </div>
    <div *ngIf="!isShowOnCheckoutPage" class="h-bottom">
        <ul>
            <li><a [routerLink]="currentLang.defended ? '/' : '/'+currentLang.slug" (click)="onLinkClick();">{{currentLang.s['common']['home']}}</a></li>
            <li *ngFor="let c of topcats"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="onLinkClick();">{{c.name[currentLang.slug]}}</a></li>

            <ng-container *ngFor="let p of pages">
                <ng-container *ngIf="p.slug !== 'shop' && p.slug !== 'collections' && p.slug !== 'collections1'">
                    <li>
                        <a [routerLink]="'/'+currentLang.slug+'/'+p.slug" (click)="onLinkClick();">{{p.name[currentLang.slug]}}</a>
                    </li>
                </ng-container>

                <ng-container *ngIf="p.slug === 'shop'">
                    <li (mouseover)="catmenudesktopMouseIn()" (mouseout)="catmenudesktopMouseOut()">
                        <a>
                            {{p.name[currentLang.slug]}}
                        </a>
                        <div class="h-catmenu-desktop">
                            <div class="hcd-content">
                                <ul>
                                    <li *ngFor="let c of cats"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="catmenudesktopActive = false;">{{c.name[currentLang.slug]}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ng-container>

                <ng-container *ngIf="p.slug === 'collections1'">
                    <li (mouseover)="collmenudesktopMouseIn1()" (mouseout)="collmenudesktopMouseOut1()">
                        <a>
                            {{p.name[currentLang.slug]}}
                        </a>
                        <div class="h-catmenu-desktop">
                            <div class="hcd-content">
                                <ul>
                                    <li *ngFor="let c of cats1;let i = index" [class.current]="i === 0"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}" (click)="collmenudesktopActive1 = false;">{{c.name[currentLang.slug]}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ng-container>


                <ng-container *ngIf="p.slug === 'collections'">
                    <li (mouseover)="collmenudesktopMouseIn()" (mouseout)="collmenudesktopMouseOut()">
                        <a>
                            {{p.name[currentLang.slug]}}
                        </a>
                        <div class="h-catmenu-desktop">
                            <div class="hcd-content">
                                <ul>
                                    <li *ngFor="let c of collections"><a routerLink="/{{currentLang.slug}}/collections/{{c.slug}}" (click)="collmenudesktopActive = false;">{{c.name[currentLang.slug]}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
    <div class="h-search" [class.active]="searchActive">
        <div class="h-searchdash">
            <div class="h-searchinputs">
                <form (submit)="goSearch();">
                    <label [class.minimized]="search">{{currentLang.s['common']['search']}}</label>
                    <input type="text" [(ngModel)]="search" name="search">
                    <div class="h-searchbtns">
                        <div class="h-searchreset" [class.active]="search" (click)="search = '';"><span></span></div>
                        <div class="h-searchexec" (click)="goSearch();"><span></span></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</header>
<div class="h-catmenu-bg" [class.active]="catmenudesktopActive || collmenudesktopActive || collmenudesktopActive1 || searchActive" (click)="catmenudesktopActive = false; collmenudesktopActive = false; collmenudesktopActive1 = false; searchActive = false;"></div>
