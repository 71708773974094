import { Model } from '../model';

export class Discount extends Model {
    public id: number;
    public ends_at: string;
    public starts_at: string;
    public duration_hours: number;        
    public percent: number;
    public color: boolean;
    public future: boolean;
    
    public getTimeLeft(): any {
      let threshold;
      let currentTime = new Date().getTime();
      let difference = 0;

      if (this.future && new Date(this.starts_at.replace(' ', 'T')).getTime() > currentTime) {
        threshold = new Date(this.starts_at.replace(' ', 'T')).getTime();
      } else {
        this.future = false;
        threshold = new Date(this.ends_at.replace(' ', 'T')).getTime();
      }

      difference = threshold - currentTime;

      let days =  Math.floor(difference / (1000*60*60*24));
      let hours = Math.floor((difference - days*(1000*60*60*24)) / (1000*60*60));
      let minutes = Math.floor((difference - days*(1000*60*60*24) - hours*(1000*60*60)) / (1000*60));
      let seconds = Math.floor((difference - days*(1000*60*60*24) - hours*(1000*60*60) - minutes * (1000*60)) / 1000);

      return {
        days: (days < 10 ? '0' + days : days),
        hours: (hours < 10 ? '0' + hours : hours),
        minutes: (minutes < 10 ? '0' + minutes : minutes),
        seconds: (seconds < 10 ? '0' + seconds : seconds)
      };
    }
}
