import { Injectable, Output, EventEmitter } from "@angular/core";

import { Wishlist } from '../model/wishlist';
import { Item } from '../model/orm/item.model';
import { IWishlistRecord } from '../model/wishlistrecord.interface';
import { AppService } from './app.service';

@Injectable()
export class WishlistService {
    public cartUserId: number = Date.now();
    public wishlist: Wishlist = new Wishlist();

    constructor(
        private appService: AppService,
    ) {
        this.init();
    }
    get q(): number {return this.wishlist.records.length;}

    private async init(): Promise<void> {

        if (this.appService.isBrowser) {
            let cartUserId = localStorage.getItem('cartUserId');
            if(cartUserId) {
                this.cartUserId = parseInt(cartUserId);
            } else {
                localStorage.setItem('cartUserId', this.cartUserId + '');
            }
            let data: string = localStorage.getItem("wishlist");
            this.wishlist = data ? JSON.parse(data) : new Wishlist();
            this.wishlist.cartUserId = this.cartUserId;
        }
    }

    public hasItem(item: Item): boolean {
        let record: IWishlistRecord = this.wishlist.records.find(r => r.item.id === item.id);

        return !!record;
    }

    public toggle(item: Item): void {
        let record: IWishlistRecord = this.wishlist.records.find(r => r.item.id === item.id);

        if (record) {
            let index: number = this.wishlist.records.findIndex(r => r.item.id === record.item.id);
            this.wishlist.records.splice(index, 1);
        } else {
            this.wishlist.records.push({
                id: Date.now(),
                item
            });
        }
        this.save();
    }

    public save(): void {
        if (this.appService.isBrowser) {
            if(localStorage) localStorage.setItem("wishlist", JSON.stringify(this.wishlist));
        }
    }

    public clear(): void {
        this.wishlist.records = [];
        this.save();
    }
}
