<div class="delete-reserve-popup" [class.active]="active" (click)="onClick($event);">
  <div class="drp-win">
    <div class="drp-close" (click)="close();"></div>
    <div class="drp-content">
      <div class="drp-head">Ви справді хочете відредагувати замовлення? <br>В цьому випадку ваш резерв буде скасований.</div>

      <div class="drp-btnwrap">
        <button class="drp-button" (click)="onSubmitDeleteReserve()">так</button>
        <button class="drp-button drp-button--active" (click)="close()">НІ</button>
      </div>
    </div>
  </div>
</div>
