import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { Item } from 'src/app/model/orm/item.model';
import { ISize } from 'src/app/model/orm/size.interface';
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { WaitlistService } from './waitlist.service';

@Component({
    selector: "the-waitlist",
    templateUrl: "./waitlist.component.html",
    styleUrls: ["./waitlist.component.scss"]
})
export class WaitlistComponent implements OnChanges {
    @Input() item: Item;
    @Input() active: boolean;
    @Input() sizeId: number;
    @Input() colorId: number | null;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter();
    public sizes: ISize[] = [];
    public sent: boolean = false;
    public sending: boolean = false;
    public phone: string = "";
    public subscribe: boolean = true;
    public isValidFlg:boolean = true;

    constructor(
        private appService: AppService,
        private waitlistService: WaitlistService,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}

    public ngOnChanges(changes: SimpleChanges): void {
        this.sizes = this.item.sizes.filter(s => s.q <= 0);
        this.sent = false;
    }

    public close() {
        this.activeChange.emit(false);
    }

    public onClick(event: MouseEvent | TouchEvent): void {
        let target: HTMLElement = event.target as HTMLElement;
        target.classList.contains("waitlist") ? this.close() : null;
    }

    public async send(): Promise<void> {
        try {
            this.phone = this.phone.trim();

            if (this.phone.length) {
                this.sending = true;
                this.waitlistService.dto = {itemId: this.item.id, sizeId: this.sizeId, phone: this.phone, subscribe: this.subscribe, colorId: this.colorId};
                await this.waitlistService.sendJoin();
                this.sent = true;
                this.sending = false;
            }
        } catch (err) {
            this.appService.showNotification(err, "error");
        }
    }

  public validatePhoneNo(event, field) {
    let phoneNumDigits = field.value.replace(/\D/g, '');

    if (phoneNumDigits.substring(0, 2) === '38') {
      phoneNumDigits = phoneNumDigits.substring(2);
    }

    this.isValidFlg = (phoneNumDigits.length === 0 || phoneNumDigits.length === 10);

    let formattedNumber = phoneNumDigits;

    if (phoneNumDigits.length <= 3) {
      formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3);
    }
    if (phoneNumDigits.length <= 6 && phoneNumDigits.length > 3) {
      formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6);
    }
    if (phoneNumDigits.length <= 8 && phoneNumDigits.length > 6) {
      formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6) + ' ' + phoneNumDigits.substring(6, 8);
    }
    if (phoneNumDigits.length > 8) {
      formattedNumber = '+38 ' + phoneNumDigits.substring(0, 3) + ' ' + phoneNumDigits.substring(3, 6) + ' ' + phoneNumDigits.substring(6, 8) + ' ' + phoneNumDigits.substring(8);
    }

    if (formattedNumber.length > 17) {
      field.value = formattedNumber.slice(0, 17);
      return;
    } else {
      field.value = formattedNumber;
    }
  }
}
