<div class="welcome-popup" [class.active]="active" (click)="onClick($event)">
  <div class="drp-win">
    <div class="drp-close" (click)="close();"></div>
    <div class="drp-content">
      <div class="drp-head" [innerHTML]="content"></div>

      <div class="drp-btnwrap">
        <button class="drp-button drp-button--active" (click)="close()">Закрити</button>
      </div>
    </div>
  </div>
</div>
