<div class="loginpanel" [class.active]="active">
    <div class="lp-left" (click)="active = false;"></div>
    <div class="lp-right">
        <div class="lp-head1">            
            <div class="lp-close" (click)="active = false;"></div>
        </div>
        <div class="lp-head2 big">
            <div class="lp-head21">{{currentLang.s['user']['recover-welcome1']}}</div>
            <div class="lp-head22">{{currentLang.s['user']['recover-welcome2']}}</div>
        </div>
        <div class="lp-form">
            <form (submit)="recover();">
                <div class="lpf-inputwrap"><input type="email" name="email" placeholder="{{currentLang.s['user']['email']}}" [(ngModel)]="email"></div>
                <div class="lpf-error" [innerHTML]="errorEmail"></div>
                
                <div class="lpf-btnwrap"><button type="submit" [disabled]="formLoading">{{currentLang.s['user']['recover']}}</button></div>
                <div class="lpf-msg" [innerHTML]="formMsg" [class.error]="formError"></div>
            </form>
        </div>
        <div class="lp-foot">            
            <div class="lp-foot-btnwrap"><button type="button" (click)="active = false; loginpanelActive = true;">{{currentLang.s['user']['login']}}</button></div>
        </div>
    </div>
</div>