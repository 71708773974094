import { Injectable } from "@angular/core";

import { Item } from 'src/app/model/orm/item.model';
import { IFilter } from 'src/app/model/filter.interface';
import { ISize } from 'src/app/model/orm/size.interface';
import { IColor } from 'src/app/model/orm/color.interface';
import { Collection } from 'src/app/model/orm/collection.model';
import { IFeature } from 'src/app/model/orm/feature.interface';

@Injectable()
export class ItemsService {
    public applyFilters(items: Item[], filter: IFilter): Item[] {        
        if (filter.sizes.find(s => s.selected)) {
            items = items.filter(i => this.doesItemSatisfySizes(i, filter));
        }

        if (filter.colors.find(c => c.selected)) {
            items = items.filter(i => this.doesItemSatisfyColors(i, filter));
        }

        if (filter.collections.find(c => c.selected)) {
            items = items.filter(i => this.doesItemSatisfyCollections(i, filter));
        }

        if (filter.features.find(f => f.selected)) {
            items = items.filter(i => this.doesItemSatisfyFeatures(i, filter));
        }

        if (filter.selectedMinPrice !== filter.minPrice || filter.selectedMaxPrice !== filter.maxPrice) {
            items = items.filter(i => this.doesItemSatisfyPrices(i, filter));
        }

        if(filter.sortBy !== 'default'){
            items = this.sortItems(items, filter.sortBy);
        }

        return items;
    }

    private sortItems(items: Item[] = [], sortBy: string){
        if(sortBy === 'price_asc'){
            items.sort((a: Item, b: Item) => a.price - b.price);
        }

        if(sortBy === 'price_desc'){
          items.sort((a: Item, b: Item) => b.price - a.price);
        }

        return items;
    }

    private doesItemSatisfySizes(item: Item, filter: IFilter): boolean {
        let sizes: ISize[] = filter.sizes.filter(s => s.selected);

        for (let s of sizes) {
            if (item.variations.length) {
                for (let v of item.variations) {
                    if (v.sizes.find(size => size.id === s.id && size.q > 0)) {
                        return true;
                    }
                }            
            } else {
                if (item.sizes.find(size => size.id === s.id && size.q > 0)) {
                    return true;
                }
            }
        }        

        return false;
    }

    private doesItemSatisfyColors(item: Item, filter: IFilter): boolean {
        let colors: IColor[] = filter.colors.filter(c => c.selected);

        for (let c of colors) {
            if (item.variations.length) {
                for (let v of item.variations) {
                    if (v.color.code === c.code) {
                        return true;
                    }
                }
            }
        }
        
        return false;
    }

    private doesItemSatisfyCollections(item: Item, filter: IFilter): boolean {
        let collections: Collection[] = filter.collections.filter(c => c.selected);

        for (let c of collections) {
            if (item.variations.length) {
                for (let v of item.variations) {
                    if (v.collections.find(collection => collection.id === c.id)) {
                        return true;
                    }
                }   
            } else {
                if (item.collections.find(collection => collection.id === c.id)) {
                    return true;
                }
            }
        }
        
        return false;
    }

    private doesItemSatisfyFeatures(item: Item, filter: IFilter): boolean {
        let features: IFeature[] = filter.features.filter(f => f.selected);

        for (let f of features) {
            if (item.variations.length) {
                for (let v of item.variations) {
                    if (v.features.find(feature => feature.id === f.id)) {
                        return true;
                    }
                }                
            } else {
                if (item.features.find(feature => feature.id === f.id)) {
                    return true;
                }
            }
        }
        
        return false;
    }

    private doesItemSatisfyPrices(item: Item, filter: IFilter): boolean {
        if (item.variations.length) {
            if (item.variations.find(child => child.price >= filter.selectedMinPrice && child.price <= filter.selectedMaxPrice)) {
                return true;
            }            
        } else {
            if (item.price >= filter.selectedMinPrice && item.price <= filter.selectedMaxPrice) {
                return true;    
            }
        }
        
        return false;
    }    
}
