import { Injectable } from "@angular/core";

import { SimpleRepository } from './_simple.repository';
import { Item } from 'src/app/model/orm/item.model';
import { DataService } from '../data.service';
import { IItemsGetallDTO } from 'src/app/model/dto/items.getall.dto';

@Injectable()
export class ItemsSearchRepository extends SimpleRepository<Item> {
    public filterName: string = "";    
    public filterLang: number = 0;

    constructor(protected dataService: DataService) {
        super();         
    }

    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            let dto: IItemsGetallDTO = {
                filterName: this.filterName,
                filterLang: this.filterLang,
            };
            this.dataService.itemsSearch(dto).subscribe(res => {                      
                if (res.statusCode === 200) {                    
                    this.xl =  res.data.length ? res.data.map(d => new Item().build(d)) : [];                                          
                    resolve();
                } else {                        
                    reject(res.statusCode+": "+res.error);
                }
            }, err => {
                reject(err.message);
            });            
        });
    }
}
