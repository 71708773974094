import { Component, OnInit } from "@angular/core";
import { Order } from 'src/app/model/orm/order.model';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersRepository } from 'src/app/services/repositories/orders.repository';
import { AuthService } from 'src/app/services/auth.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { Page } from 'src/app/model/orm/page.model';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { User } from 'src/app/model/orm/user.model';

@Component({
    selector: "order-page",
    templateUrl: "./order.page.html",
    styleUrls: ["./order.page.scss"],
})
export class OrderPage implements OnInit {
    public order: Order = null;
    public orderReady: boolean = false;
    
    constructor(
		private appService: AppService,		
		private route: ActivatedRoute,
        private router: Router,        
        private pagesRepository: PagesRepository,	
        private ordersRepository: OrdersRepository,		
		private authService: AuthService,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}	
    get authenticated(): boolean {return this.authService.authenticated;}
	get pagesAcc(): Page[] {return this.pagesRepository.pagesAcc;}
    get user(): User {return this.authService.user;}	
    
    public ngOnInit(): void {
        try {
            this.appService.headerAlwaysOpaque = true;		
            this.appService.catmenumobileActive = false;	
            this.appService.collmenumobileActive = false;
            
            if (this.authenticated) {
                this.route.params.subscribe(async p => {                    
                    this.orderReady = false;
                    let orderId: number = parseInt(p['orderid']);
                    await this.ordersRepository.load(this.user.id);
                    this.order = this.ordersRepository.xl.find(x => x.id === orderId) || null;

                    if (!this.order) {                            
                        this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
                    } else {
                        this.orderReady = true;
                        this.setMeta();
                    }                    
                });
            }            
        } catch (err) {
            this.appService.showNotification(err, "error");
        }
    }

    private setMeta(): void {
        this.appService.setTitle(`${this.currentLang.s['history']['order']} #${this.order.id}`);
    }    
}
