export class StatisticsGoogleProductDetailView {
    constructor(
        public actionField: {
            list?: string
        },
        public products: {
            name: string,         // Name or ID is required.
            id: string,
            price?: string,
            brand?: string,
            category?: string,
            variant?: string
        }[]
    ) {}
}
