import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { Cart } from 'src/app/model/cart';
import { Wishlist } from 'src/app/model/wishlist';
import { Cat } from 'src/app/model/orm/cat.model';
import { Collection } from 'src/app/model/orm/collection.model';
import { Lang } from 'src/app/model/orm/lang.model';
import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { CatsRepository } from 'src/app/services/repositories/cats.repository';
import { CollectionsRepository } from 'src/app/services/repositories/collections.repository';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';

@Component({
    selector: "the-header2",
    templateUrl: "./header2.component.html",
    styleUrls: ["./header2.component.scss"],
})
export class Header2Component implements OnInit {
    @Input() isShowPreHeader: boolean;
    public opaque: boolean = false;
    public compact: boolean = false;
    public pages: Page[] = [];
    public cats: Cat[] = [];
    public cats1: Cat[] = [];
    public topcats: Cat[] = [];
    public collections: Collection[] = [];
    public search: string = "";
    public collmenudesktopOut=0;
    public collmenudesktopOut1=0;
    public catmenudesktopOut=0;
    public isShowOnCheckoutPage: boolean;

    constructor(
        private appService: AppService,
        private pagesRepository: PagesRepository,
        private catsRepository: CatsRepository,
        private collectionsRepository: CollectionsRepository,
        private cartService: CartService,
        private wishlistService: WishlistService,
        private authService: AuthService,
        private router: Router,
    ) {}

    get mobmenuActive(): boolean {return this.appService.mobmenuActive;}
    set mobmenuActive(v: boolean) {this.appService.mobmenuActive = v;}
    get currentLang(): Lang {return this.appService.currentLang.value;}
    get alwaysOpaque(): boolean {return this.appService.headerAlwaysOpaque;}
    get catmenudesktopActive(): boolean {return this.appService.catmenudesktopActive;}
    set catmenudesktopActive(v: boolean) {this.appService.catmenudesktopActive = v;}
    get catmenumobileActive(): boolean {return this.appService.catmenumobileActive;}
    set catmenumobileActive(v: boolean) {this.appService.catmenumobileActive = v;}
    get collmenudesktopActive(): boolean {return this.appService.collmenudesktopActive;}
    set collmenudesktopActive(v: boolean) {this.appService.collmenudesktopActive = v;}
    get collmenudesktopActive1(): boolean {return this.appService.collmenudesktopActive1;}
    set collmenudesktopActive1(v: boolean) {this.appService.collmenudesktopActive1 = v;}
    get collmenumobileActive(): boolean {return this.appService.collmenumobileActive;}
    set collmenumobileActive(v: boolean) {this.appService.collmenumobileActive = v;}
    get collmenumobileActive1(): boolean {return this.appService.collmenumobileActive1;}
    set collmenumobileActive1(v: boolean) {this.appService.collmenumobileActive1 = v;}
    get cartpanelActive(): boolean {return this.appService.cartpanelActive;}
    set cartpanelActive(v: boolean) {this.appService.cartpanelActive = v;}
    get cart(): Cart {return this.cartService.cart;}
    get cartQ(): number {return this.cartService.q;}
    get wishlist(): Wishlist {return this.wishlistService.wishlist;}
    get wishlistQ(): number {return this.wishlistService.q;}
    get loginpanelActive(): boolean {return this.appService.loginpanelActive;}
    set loginpanelActive(v: boolean) {this.appService.loginpanelActive = v;}
    get registerpanelActive(): boolean {return this.appService.registerpanelActive;}
    set registerpanelActive(v: boolean) {this.appService.registerpanelActive = v;}
    get authenticated(): boolean {return this.authService.authenticated;}
    get recoverpanelActive(): boolean {return this.appService.recoverpanelActive;}
    set recoverpanelActive(v: boolean) {this.appService.recoverpanelActive = v;}
    get searchActive(): boolean {return this.appService.searchActive;}
    set searchActive(v: boolean) {this.appService.searchActive = v;}
    get currentRoute(): string {return window.location.pathname;}

    public ngOnInit(): void {
        this.pages = this.pagesRepository.xl.filter(p => p.inmenu && p.section === 1 && p.slug !== 'home');
        this.cats = this.catsRepository.xl.filter(c => !c.inmenu);
        this.cats1 = this.catsRepository.xl.filter(c => c.inmenuc);
        this.topcats = this.catsRepository.xl.filter(c => c.inmenu);
        this.collections = this.collectionsRepository.xl;

        this.isShowOnCheckoutPage = this.checkRoute(this.router.url);

        this.router.events.subscribe(e => {
            if (e instanceof NavigationStart) {
                this.isShowOnCheckoutPage = this.checkRoute(e.url);
            }
        });
    }

    private checkRoute(url: string): boolean {
      return url === '/ua/shop/checkout-continue' || url === '/en/shop/checkout-continue' || url === '/ru/shop/checkout-continue';
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        this.opaque = window.scrollY > 10;
        this.compact = window.scrollY > 100;
    }

    public goSearch(): void {
        if (this.search.length > 2) {
            this.searchActive = false;
            this.appService.searchKeyword.next(this.search);
            this.search = "";
            this.router.navigateByUrl(`/${this.currentLang.slug}/shop/search`);
        }
    }

    public onLinkClick(): void {
        this.catmenudesktopActive = false;
        this.collmenudesktopActive = false;
        this.cartpanelActive = false;
        this.loginpanelActive = false;
        this.registerpanelActive = false;
        this.recoverpanelActive = false;
    }

    public onShopLinkClick(): void {
        this.catmenudesktopActive = !this.catmenudesktopActive;
        this.catmenumobileActive = true;
        this.collmenudesktopActive = false;
        this.searchActive = false;
        this.cartpanelActive = false;
        this.loginpanelActive = false;
        this.registerpanelActive = false;
        this.recoverpanelActive = false;
    }

    public onSearchLinkClick(): void {
        this.searchActive = !this.searchActive;
        this.catmenudesktopActive = false;
        this.collmenudesktopActive = false;
        this.cartpanelActive = false;
        this.loginpanelActive = false;
        this.registerpanelActive = false;
        this.recoverpanelActive = false;
    }

    public onCollectionsLinkClick(): void {
        this.collmenudesktopActive = !this.collmenudesktopActive;
        this.collmenumobileActive = true;
        this.catmenudesktopActive = false;
        this.searchActive = false;
        this.cartpanelActive = false;
        this.loginpanelActive = false;
        this.registerpanelActive = false;
        this.recoverpanelActive = false;
    }

    public onCartClick(): void {
        this.catmenudesktopActive = false;
        this.collmenudesktopActive = false;
        this.loginpanelActive = false;
        this.registerpanelActive = false;
        this.recoverpanelActive = false;
        this.searchActive = false;
        this.cartpanelActive = !this.cartpanelActive;
    }

    public onLoginClick(): void {
        this.catmenudesktopActive = false;
        this.collmenudesktopActive = false;
        this.searchActive = false;
        this.cartpanelActive = false;
        this.loginpanelActive = !this.loginpanelActive;
        this.registerpanelActive = false;
        this.recoverpanelActive = false;
    }

    public collmenudesktopMouseIn(): void {
        this.collmenudesktopActive=true;
        this.collmenudesktopOut=0;
    }

    public collmenudesktopMouseIn1(): void {
        this.collmenudesktopActive1=true;
        this.collmenudesktopOut1=0;
    }

    public catmenudesktopMouseIn(): void {
        this.catmenudesktopActive=true;
        this.catmenudesktopOut=0;
    }
    public collmenudesktopMouseOut(): void {
        this.collmenudesktopOut=1;
        setTimeout(() => {
            if (this.collmenudesktopOut) {
                this.collmenudesktopActive=false;
            }
        }, 300);
    }
    public collmenudesktopMouseOut1(): void {
        this.collmenudesktopOut1=1;
        setTimeout(() => {
            if (this.collmenudesktopOut1) {
                this.collmenudesktopActive1=false;
            }
        }, 300);
    }
    public catmenudesktopMouseOut(): void {
        this.catmenudesktopOut=1;
        setTimeout(() => {
            if (this.catmenudesktopOut) {
                this.catmenudesktopActive=false;
            }
        }, 300);
    }
}
