import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NotfoundPage } from './notfound.page';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
    ],
    declarations: [
        NotfoundPage,        
    ],
    exports: [
        NotfoundPage,        
    ],    
})
export class ErrorsModule {}
