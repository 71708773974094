<div class="reviews">
    <!-- form -->
    <div class="rv-btn">
        <button class="add" (click)="formActive = !formActive;"><span>{{currentLang.s['reviews']['add']}}</span></button>
    </div>
    <div class="rv-form" [class.active]="formActive">
        <form (submit)="send();">
            <div class="rvf-row">
                <label>{{currentLang.s['reviews']['rating']}}:</label>
                <div><stars [(rate)]="review.rate" [active]="true" [big]="true"></stars></div>
            </div>
            <div class="rvf-row">
                <label>{{currentLang.s['reviews']['title']}}:</label>
                <input type="text" name="title" [(ngModel)]="review.title">
            </div>
            <div class="rvf-row">
                <label>{{currentLang.s['reviews']['review']}}: <span class="red">*</span></label>
                <textarea name="content" [class.error]="errorContent" [(ngModel)]="review.content"></textarea>
            </div>
            <div class="rvf-row">
                <label>{{currentLang.s['reviews']['name']}}:</label>
                <input type="text" name="name" [(ngModel)]="review.name">
            </div>
            <div class="rvf-row">
                <label>{{currentLang.s['reviews']['email']}}: <span class="red">*</span></label>
                <input type="text" name="email" [class.error]="errorEmail" [(ngModel)]="review.email">
            </div>
            <div class="rvf-row">
                <label>{{currentLang.s['reviews']['city']}}:</label>
                <input type="text" name="city" [(ngModel)]="review.city">
            </div>
            <div class="rvf-row" *ngIf="item.sizes.length">
                <label>{{currentLang.s['reviews']['size']}}:</label>
                <div class="select">
                    <select name="size_id" [(ngModel)]="review.size_id">
                        <option [ngValue]="null"></option>
                        <option *ngFor="let s of item.sizes" [ngValue]="s.id">{{s.name}}</option>
                    </select>                    
                </div>                
            </div>
            <div class="rvf-row">
                <label>{{currentLang.s['reviews']['sizematch']}}:</label>
                <div><size-match [active]="true" [(sizematch)]="review.sizematch"></size-match></div>
            </div>
            <div class="rvf-row">
                <button type="submit" [disabled]="sending">{{sending ? currentLang.s['reviews']['sending'] : currentLang.s['reviews']['send']}}</button>
            </div>
            <div class="rvf-row">
                <label class="centered"><span class="red">*</span> - {{currentLang.s['reviews']['required']}}</label>
            </div>
            <div class="rvf-row">
                <div class="info centered" [class.active]="sent"><span>{{currentLang.s['reviews']['sent']}}</span></div>
            </div>
        </form>
    </div>    
    <!-- reviews -->
    <ng-container *ngIf="ready && reviews.length">
        <div class="rv-head">
            <div class="rv-ratedigits">{{item.reviewsRate | number:'1.0-2'}}</div>
            <div class="rv-ratestars"><stars [rate]="item.reviewsRate"></stars></div>
            <div class="rv-q">{{currentLang.s['reviews']['based1']}} {{item.reviewsQ}} {{currentLang.s['reviews']['based2']}}</div>
        </div>
        <div class="rv-items">
            <div class="rv-item" *ngFor="let r of reviews">
                <div class="rvi-left">
                    <div class="rvi-name">{{r.name}}</div>
                    <div class="rvi-city" *ngIf="r.city">{{r.city}}</div>
                    <div class="rvi-verified">{{currentLang.s['reviews']['verified']}}</div>                
                    <div class="rvi-recommend" *ngIf="r.rate >= 4">{{currentLang.s['reviews']['recommend']}}</div>
                    <div class="rvi-notrecommend" *ngIf="r.rate < 4">{{currentLang.s['reviews']['notrecommend']}}</div>
                    <div class="rvi-size" *ngIf="r.size"><strong>{{currentLang.s['reviews']['size']}}:</strong> {{r.size.name}}</div>
                </div>
                <div class="rvi-right">
                    <div class="rvi-stars"><stars [rate]="r.rate"></stars></div>
                    <div class="rvi-head">{{r.title}}</div>
                    <div class="rvi-content" [innerHTML]="r.content"></div>
                    <div class="rvi-sizematch" *ngIf="r.sizematch !== null">
                        <div class="rvis-title">{{currentLang.s['reviews']['sizematch']}}:</div>
                        <div class="rvis-content"><size-match [active]="false" [sizematch]="r.sizematch"></size-match></div>
                    </div>
                </div>
            </div>        
        </div>
        <div class="rv-btn" *ngIf="item.reviewsQ > reviews.length">
            <button class="more" (click)="more();"><span>{{currentLang.s['reviews']['more']}}</span></button>
        </div>
    </ng-container>
    <ng-container *ngIf="!ready || loadingMore">
        <div class="loading">{{currentLang.s['common']['loading']}}</div>
    </ng-container>    
</div>