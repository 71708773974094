import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PagesRepository } from '../../services/repositories/pages.repository';
import { Page } from '../../model/orm/page.model';
import { AppService } from '../../services/app.service';
import { Lang } from '../../model/orm/lang.model';

@Component({
	selector: 'static-page',
	templateUrl: './static.page.html',	
	styleUrls: ["./static.page.scss"]
})
export class StaticPage implements OnInit {	
	public page: Page | null = null;
	public pageReady: boolean = false;
	public slug: string;

	constructor(
		private pagesRepository: PagesRepository,		
		private route: ActivatedRoute,
		private router: Router,
		private appService: AppService,
	) {}

	get currentLang(): Lang {return this.appService.currentLang.value;}
	get pagesHelp(): Page[] {return this.pagesRepository.pagesHelp;}

	public ngOnInit(): void {
		this.appService.headerAlwaysOpaque = true;
		this.appService.catmenumobileActive = false;	
		this.appService.collmenumobileActive = false;		
		this.route.params.subscribe(p => {
			if (p['page'] !== this.slug) {
				this.pageReady = false;
				this.slug = p['page'];
				this.page = this.pagesRepository.xl.find(x => x.slug === this.slug) || null;
	
				if (!this.page) {
					this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
				} else {
					this.setMeta();
					this.pageReady = true;
				}
			} else {
				this.setMeta();
			}						
		});
	}

	private setMeta(): void {
		this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);
		this.appService.setMeta("keywords", this.page.keywords[this.currentLang.slug]);
		this.appService.setMeta("description", this.page.description[this.currentLang.slug]);
	}
}
