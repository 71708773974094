import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "stars",
    templateUrl: "./stars.component.html",
    styleUrls: ["./stars.component.scss"],
})
export class StarsComponent {
    @Input() rate: number;
    @Input() active: boolean = false;
    @Input() big: boolean = false;
    @Output() rateChange: EventEmitter<number> = new EventEmitter();

    public getClass(n: number): string {
        if (this.rate >= n) {
            return "marked";
        } else if (this.rate >= n - 0.5) {
            return "semimarked";
        }
        
        return "";
    }

    public setRate(n: number): void {
        if (this.active) {
            this.rateChange.emit(n);
        }
    }
}
