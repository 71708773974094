import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from "@angular/core";
import { GuidesRepository } from 'src/app/services/repositories/guides.repository';
import { AppService } from 'src/app/services/app.service';
import { Guide } from 'src/app/model/orm/guide.model';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
    selector: "size-guides",
    templateUrl: "./sizeguides.component.html",
    styleUrls: ["./sizeguides.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SizeguidesComponent implements OnInit {
    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter();
    public current: number = 0;
    public ready: boolean = false;
    public mobileButtonsActive: boolean = false;

    constructor(
        private guidesRepository: GuidesRepository,
        private appService: AppService,
    ) {}

    get guides(): Guide[] {return this.guidesRepository.xl;}
    get currentLang(): Lang {return this.appService.currentLang.value;}

    public async ngOnInit(): Promise<void> {
        try {
            await this.guidesRepository.load();
            this.ready = true;
        } catch (err) {
            this.appService.showNotification(err, "error");
        }
    }

    public close() {
        this.activeChange.emit(false);
    }

    public onClick(event: MouseEvent | TouchEvent): void {
        let target: HTMLElement = event.target as HTMLElement;
        target.classList.contains("sizeguides") ? this.close() : null;        
    }
}