<div class="rangeslider" #wrap>
    <ng-container *ngIf="ready">
        <div class="rs-scalewrap">
            <div class="rs-scale"></div>
            <div class="rs-handle" [style.left.px]="left[0]" (mousedown)="onDragStart(0, $event);" (touchstart)="onDragStart(0, $event);"></div>
            <div class="rs-handle" [style.left]="'calc('+left[1]+'px - 10px)'" (mousedown)="onDragStart(1, $event);" (touchstart)="onDragStart(1, $event);"></div>
        </div>
        <div class="rs-values">
            <div class="rs-value">{{formatPrice(current[0])}}</div>
            <div class="rs-value">{{formatPrice(current[1])}}</div>
        </div>
    </ng-container>    
</div>
