import { Model } from '../model';
import { IPicture } from './picture.interface';

export class Collection extends Model {
    public id: number;
    public img: string;
    public img_s: string;
    public slug: string;     
    public pictures: IPicture[];   
    
    public name: Object;
    public h1: Object;
    public contentshort: Object;
    public content: Object;
    public title: Object;
    public keywords: Object;
    public description: Object;

    public selected?: boolean;
}
