import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { ReviewsByItemRepository } from 'src/app/services/repositories/reviewsbyitem.repository';
import { Review } from 'src/app/model/orm/review.model';
import { ISize } from 'src/app/model/orm/size.interface';
import { Item } from 'src/app/model/orm/item.model';
import { ReviewsService } from './reviews.service';

@Component({
    selector: "item-reviews",
    templateUrl: "./reviews.component.html",
    styleUrls: ["./reviews.component.scss"]
})
export class ReviewsComponent implements OnChanges {
    @Input() item: Item;
    @Input() sizes: ISize[];
    @Input() formActive: boolean;
    public ready: boolean = false;
    public loadingMore: boolean = false;
    public review: Review;
    public sending: boolean = false;
    public sent: boolean = false;
    public errorEmail: boolean = false;
    public errorContent: boolean = false;

    constructor(
        private appService: AppService,
        private reviewsByItemRepository: ReviewsByItemRepository,
        private reviewsService: ReviewsService,
    ) {}

    get reviews(): Review[] {return this.reviewsByItemRepository.xl;}    
    get currentLang(): Lang {return this.appService.currentLang.value;}

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        try {
            this.ready = false;
            this.review = new Review().init(this.item.id);
            this.reviewsByItemRepository.filterItem = this.item.id;
            this.reviewsByItemRepository.xl = [];            
            this.reviewsByItemRepository.currentPart = 0;
            await this.reviewsByItemRepository.loadReviews();            
            this.ready = true;
        } catch (err) {
            this.appService.showNotification(err, "error");
        }
    }

    public async more(): Promise<void> {
        if (!this.loadingMore) {
            try {
                this.loadingMore = true;
                this.reviewsByItemRepository.currentPart++;
                await this.reviewsByItemRepository.loadReviews();
                this.loadingMore = false;
            } catch (err) {
                this.appService.showNotification(err, "error");
            }
        }
    }

    public async send(): Promise<void> {
        try {
            if (!this.validate()) {
                return;
            }

            this.sending = true;
            await this.reviewsService.send(this.review);
            this.sending = false;
            this.sent = true;
            setTimeout(() => {
                this.sent = false;
                this.formActive = false;
                this.review = new Review().init(this.item.id);
            }, 1000);
        } catch (err) {
            this.appService.showNotification(err, "error");
            this.sending = false;
        }        
    }

    public validate(): boolean {
        this.review.email = this.review.email.trim();
        this.review.content = this.review.content.trim();
        let error = false;

        if (!this.review.email.length) {
            error = true;
            this.errorEmail = true;
            setTimeout(() => this.errorEmail = false, 2000);
        }

        if (!this.review.content.length) {
            error = true;
            this.errorContent = true;            
            setTimeout(() => this.errorContent = false, 2000);
        }

        return !error;
    }
}
