import { Component, OnInit, OnChanges, Input, SimpleChanges } from "@angular/core";
import { Discount } from 'src/app/model/orm/discount.model';
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { DiscountRepository } from 'src/app/services/repositories/discount.repository';
import { Cat } from 'src/app/model/orm/cat.model';

@Component({
    selector: "cat-timer",
    templateUrl: "./cat.timer.html",
    styleUrls: ["./cat.timer.scss"]
})

export class CatTimerComponent implements OnInit, OnChanges {
    @Input() cat: Cat;
    public discount: Discount = null;
    public timeLeft: null | {days: string, hours: string, minutes: string, seconds: string};
    public interval: null | ReturnType<typeof setInterval> = null;

    public ngOnInit(): void {
        this.loadTimer();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if(changes.cat){
            this.loadTimer();
        }
    }

    constructor (
        private appService: AppService,
        private discountRepository: DiscountRepository
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}

    private loadTimer(){
        this.discount = null;
        this.timeLeft = null;
        if(this.appService.isBrowser) clearInterval(this.interval);
        this.discountRepository
				.load(this.cat.id)
				.then(() => {
					this.discount = this.discountRepository.discount; 
                    console.log(this.discount, this.cat)
                    if (this.discount && this.discount.ends_at && this.discount.id) {
                        this.timeLeft = this.discount.getTimeLeft();
                        if(this.appService.isBrowser){
                            this.interval = setInterval(() => {
                                this.timeLeft = this.discount.getTimeLeft()
                            }, 1000);
                        }
                    }
				}).catch(err => this.appService.showNotification(err, "error"));
    }

}