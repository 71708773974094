<div class="gal2"> 
    <!-- main version -->   
    <ng-container *ngIf="isBrowser">
        <div class="gal2-wrap" #galwrap [style.height.px]="h">    
            <div 
                #galcontainer
                class="gal2-container"
                [style.left.px]="-left"                        
                (touchstart)="onDragStart($event);"
                (mousedown)="onDragStart($event);"                
                (transitionend)="onTransitionEnd();"   
            >            
                <div class="gal2-item" [style.width.px]="w" *ngFor="let item of items">
                    <cat-item [item]="item" [hoverable]="false" [blockImageLink]="moving"></cat-item>
                </div>            
            </div>        
        </div>
        <div class="gal2-switch">        
            <div class="gal2-switch-item" *ngFor="let s of steps" [class.active]="s === step" (click)="moveTo(s);"></div>                
        </div>    
    </ng-container>    
    <!-- dummy for server version -->   
    <ng-container *ngIf="isServer">
        <div class="gal2-wrap">
            <div class="gal2-container-dummy">
                <div class="gal2-item-dummy" *ngFor="let item of itemsShort">
                    <cat-item [item]="item" [hoverable]="false"></cat-item>
                </div>            
            </div>
        </div> 
    </ng-container> 
</div>