import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CartInfoMessage } from "src/app/model/cart.info.message.interface";
import { Lang } from 'src/app/model/orm/lang.model';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: "cart-info-message",
    templateUrl: "./cart.info.message.component.html",
    styleUrls: ["./cart.info.message.component.scss"]
})


export class CartInfoMessageComponent implements OnInit {
    @Input() messageObj: CartInfoMessage;
    @Output('removeMessage') removeMessage: EventEmitter<any> = new EventEmitter();
    
    constructor(
        private appService: AppService
    ) {}

    public showed: boolean = false;
    
    get currentLang(): Lang {
        return this.appService.currentLang.value;
    }
    get className(): string {
        return this.showed ? 'cart-info-message showed' : 'cart-info-message';
    }
    
    get cartLink(): string {
        return '/' + this.currentLang.slug + '/shop/cart';
    }

    public ngOnInit(): void {
        setTimeout(() => {
            this.deleteMessage();
        }, 7000);
    }

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.showed = true;
        }, 500)
    }

    public deleteMessage(){
        this.showed = false;
        setTimeout(() => {
            this.removeMessage.emit();
        }, 1000);
    }
}