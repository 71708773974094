import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { AuthService } from 'src/app/services/auth.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { User } from 'src/app/model/orm/user.model';
import { CountriesRepository } from 'src/app/services/repositories/countries.repository';
import { Country } from 'src/app/model/orm/country.model';

@Component({
	selector: 'address-page',
	templateUrl: './address.page.html',
	styleUrls: ['../../../common.styles/userdata.scss']
})
export class AddressPage implements OnInit {
    public page: Page = null;
	public pageReady: boolean = false;

	public formLoading: boolean = false;
    public formMsg: string = "&nbsp;"
    public formError: boolean = false;  
	
	constructor(
		private appService: AppService,		
		private route: ActivatedRoute,
		private router: Router,
		private pagesRepository: PagesRepository,	
		private countriesRepository: CountriesRepository,
		private authService: AuthService,
	) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}	
    get authenticated(): boolean {return this.authService.authenticated;}
	get pagesAcc(): Page[] {return this.pagesRepository.pagesAcc;}
	get user(): User {return this.authService.user;}
	get countries(): Country[] {return this.countriesRepository.xl;}
	
	public ngOnInit(): void {
		this.appService.headerAlwaysOpaque = true;		
		this.appService.catmenumobileActive = false;	
		this.appService.collmenumobileActive = false;
			
		// page
		this.page = this.pagesRepository.xl.find(x => x.slug === "address") || null;

		if (!this.page) {
			this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
		} else {
            this.pageReady = true;
			this.route.params.subscribe(p => {
				this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);				
			});					
		}		
	}
	
	public async update(): Promise<void> {
        try {            
            this.formLoading = true;
            await this.authService.update(this.user);
            this.authService.save();
            this.formLoading = false;
            this.formMsg = this.currentLang.s['user']['update-ok'];              
            setTimeout(() => {this.formMsg = "&nbsp;";}, 3000);            
        } catch (err) {
            this.appService.showNotification(err, "error");
            this.formLoading = false;
        }
    }
}
