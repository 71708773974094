import { Component, Input, Output, EventEmitter } from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
    selector: "size-match",
    templateUrl: "./sizematch.component.html",
    styleUrls: ["./sizematch.component.scss"]
})
export class SizematchComponent {
    @Input() sizematch: number = null;
    @Input() active: boolean = false;
    @Output() sizematchChange: EventEmitter<number> = new EventEmitter();

    constructor(private appService: AppService) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}

    public setTo(n: number): void {
        if (this.active) {
            this.sizematchChange.emit(this.sizematch === n ? null : n);
        }
    }
}
