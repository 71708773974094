<div class="costperwearslider" #wrap>
    <ng-container *ngIf="ready">
        <div class="costperwearslider-title">{{currentLang.s['item']['cpw_before']}}<b>{{ currentLang.s['item']['cpw']}}</b></div>
        <div class="costperwearslider-scalewrap">
            <div class="costperwearslider-scale"></div>
            <div class="costperwearslider-min"><span>{{min}}</span></div>
            <div class="costperwearslider-max"><span>{{max}}</span></div>
            <div class="costperwearslider-handle" [style.left.px]="left - 5" (mousedown)="onDragStart($event);" (touchstart)="onDragStart($event);"><span>{{current}}</span></div>
        </div>
        <div class="costperwearslider-values">
            <div class="costperwearslider-value">CPW: {{formatPrice((price/current))}}</div>
        </div>
    </ng-container>
</div>
