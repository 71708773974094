<ng-container *ngIf="pageReady">
    <div class="compact padded" *ngIf="cart_enabled">
        <div id="breadcrumbs">
            <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>
        </div>
        <h1>
            <span [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></span>: {{cartQ}} {{currentLang.s['cart']['pcs']}}
        </h1>
        <ng-container *ngIf="cart.records.length">
            <div id="cart">
                <div id="cart-left">
                    <div id="cart-table">
                        <div id="ct-head">
                            <div class="ct-th">{{currentLang.s['cart']['item']}}</div>
                            <div class="ct-th">{{currentLang.s['cart']['size']}}</div>
                            <div class="ct-th">{{currentLang.s['cart']['color']}}</div>
                            <div class="ct-th">{{currentLang.s['cart']['q']}}</div>
                        </div>
                        <div class="ct-row" *ngFor="let r of cart.records">
                            <div class="ct-img"><a routerLink="/{{currentLang.slug}}/shop/{{r.item.catslug}}/{{r.item.slug}}"><div class="ct-imgwrap" [style.background-image]="getItemImg(r.item)"></div></a></div>
                            <div class="ct-details">
                                <div class="ct-nameprice">
                                    <div class="ct-name">{{r.item.name[currentLang.slug]}}</div>
                                    <div class="ct-price">{{getItemPrice(r.item)}} {{currentCurrency.name}}</div>
                                    <div class="ct-error" *ngIf="r.errorBeforeCheckout">
                                      {{ r.errorBeforeCheckoutQuantity === 0 ? 'На жаль, це був last size у цьому розмірі. Наразі виріб на етапі оформлення замовлення у іншого клієнта' : 'На жаль, наразі доступних виробів до замовлення: ' + r.errorBeforeCheckoutQuantity }}
                                    </div>
                                </div>
                                <div class="ct-size"><span>{{currentLang.s['cart']['size']}}: </span>{{r.size.name}}</div>
                                <div class="ct-color">
                                    <ng-container *ngIf="r.item.color">
                                        <span>{{currentLang.s['cart']['color']}}: </span>{{r.item.color.name[currentLang.slug]}}
                                    </ng-container>
                                </div>
                                <div class="ct-q">
                                    <div class="ctq-incdec">
                                        <div class="ctq-adjust" (click)="decrease(r);">&ndash;</div>
                                        <div>{{r.q}}</div>
                                        <div class="ctq-adjust" (click)="increase(r);">+</div>
                                    </div>
                                    <div class="ctq-remove" (click)="remove(r);">{{currentLang.s['cart']['remove']}}</div>
                                </div>
                                <div class="ct-remove" (click)="remove(r);"></div>
                            </div>
                        </div>
                        <!--
                        <div id="ct-bag">
                            <checkbox [(value)]="cart.bag" (valueChange)="saveCart();" [label]="bagLabel"></checkbox>
                        </div>
                        -->
                    </div>
                </div>
                <div id="cart-right">
                    <div id="cart-totalcheckout">
                        <div id="cart-total">
                            <div id="cart-total-title">{{currentLang.s['cart']['total']}}</div>
                            <div id="cart-total-sum">{{cartS}} {{currentCurrency.name}}</div>
                        </div>
                        <div id="cart-checkout"><button type="button" (click)="onCheckout()">{{currentLang.s['cart']['checkout']}}</button></div>
                    </div>
                    <div id="cart-page" [innerHTML]="page.content[currentLang.slug]"></div>
                </div>
            </div>
            <ng-container *ngIf="cartRelated.length">
                <div id="cart-recommended-head">{{currentLang.s['cart']['recommended']}}</div>
                <cart-recommended [items]="cartRelated"></cart-recommended>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!cart.records.length">{{currentLang.s['cart']['empty']}}</ng-container>
    </div>
</ng-container>
