import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'the-delete-reserve-popup',
  templateUrl: "./delete-reserve-popup.component.html",
  styleUrls: ["./delete-reserve-popup.component.scss"]
})

export class DeleteReservePopupComponent implements OnInit {
    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter();
    @Output() deleteReserve = new EventEmitter();

    constructor() {}

    public ngOnInit() {}

    public close() {
      this.activeChange.emit(false);
    }

    public onClick(event: MouseEvent | TouchEvent): void {
        const target: HTMLElement = event.target as HTMLElement;

        target.classList.contains('delete-reserve-popup') ? this.close() : null;
    }

    public onSubmitDeleteReserve() {
      this.deleteReserve.emit();
    }
}
