import { Model } from '../model';
import { Qa } from './qa.interface';

export class Page extends Model {
    public id: number;
    public parent_id: number;
    public section: number;
    public img: string;
    public img_s: string;
    public slug: string;    
    public defended: boolean;
    public inmenu: boolean;
    public inburger: boolean;
    public qas: Qa[];

    public name: Object;
    public h1: Object;
    public contentshort: Object;
    public content: Object;
    public title: Object;
    public keywords: Object;
    public description: Object;
}
