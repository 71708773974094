import { ICartRecord } from './cartrecord.interface';
import { IPromocode } from './orm/promocode.interface';

export class Cart {
    constructor(
        public cartUserId: number = 0,
        public old_records: ICartRecord[] = [],
        public records: ICartRecord[] = [],
        public reserve: ICartRecord[] = [],
        public timer = {
            minutes: 0,
            seconds: 0,
            secondsLeft: 0
        },
        public newTimer: number | null = null,
        public bag: boolean = false,
        public promocode: IPromocode = null,
        public paymentmethod_id: number = 1, // 1 - online, 2 - cash in post office

        public email: string = "",
        public phone: string = "",
        public instagram: string = "",
        public dont_call_me: boolean = false,
        public name: string = "",
        public surname: string = "",
        public address: string = "",
        public city: string = "",
        public province: string = "",
        public country_id: number = null,
    ) {}
}
