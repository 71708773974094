import { Injectable } from '@angular/core';

import { DataService } from '../data.service';
import { SimpleRepository } from './_simple.repository';
import { Discount } from 'src/app/model/orm/discount.model';

@Injectable()
export class DiscountRepository extends SimpleRepository<Discount> {
    public discount: Discount;

    constructor(protected dataService: DataService) {
        super();
    }

    get discountTimer(): number {
        return this.discount ? this.discount.getTimeLeft() : 0;
    }
    
    public load(catId = null): Promise<void> {
        return new Promise((resolve, reject) => {
            if (new Date().getTime() - this.loadedAt < this.ttl) {
                resolve();
            } else {
                this.dataService.discount(catId).subscribe(res => {
                    if (res.statusCode === 200) {
                        this.discount = res ? new Discount().build(res.data) : null;
                        resolve();
                    } else {
                        reject(res.statusCode+": "+res.error);
                    }
                }, err => {
                    reject(err.message);
                });
            }            
        });
    }
}
