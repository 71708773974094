import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Collection } from 'src/app/model/orm/collection.model';
import { Item } from 'src/app/model/orm/item.model';
import { AppService } from 'src/app/services/app.service';
import { CollectionsRepository } from 'src/app/services/repositories/collections.repository';
import { ItemsService } from 'src/app/services/items.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { ItemsByCollectionRepository } from 'src/app/services/repositories/itemsbycollection.repository';
import { FilterByCollectionRepository } from 'src/app/services/repositories/filterbycollection.repository';
import { IFilter } from 'src/app/model/filter.interface';

@Component({
    selector: "collection-page",
    templateUrl: "./collection.page.html",
    styleUrls: ["../../../common.styles/items.page.scss", "./collection.page.scss"],
})
export class CollectionPage implements OnInit {
    public collectionReady: boolean = false;
    public itemsReady: boolean = false;
    public filterReady: boolean = false;    
    public collection: Collection = null;
    public filteredItems: Item[] = [];    
    private slug: string = null;  
    public showInARow = 2;  
    
    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private collectionsRepository: CollectionsRepository,
        private itemsByCollectionRepository: ItemsByCollectionRepository,
        private filterByCollectionRepository: FilterByCollectionRepository,
        private itemsService: ItemsService,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}    
    get items(): Item[] {return this.itemsByCollectionRepository.xl;}
    get filter(): IFilter {return this.filterByCollectionRepository.filter;}    

    public ngOnInit(): void {
        this.appService.headerAlwaysOpaque = false;
        this.appService.catmenumobileActive = false;
        this.appService.collmenumobileActive = true;	
        this.route.params.subscribe(async p => {            
            if (p["collection"] !== this.slug) {
                try {
                    this.collectionReady = false;    
                    this.itemsReady = false;   
                    this.filterReady = false;                                             
                    this.slug = p["collection"];
                    this.collection = this.collectionsRepository.xl.find(x => x.slug === this.slug);
        
                    if (!this.collection) {
                        this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
                    } else {
                        this.setMeta();
                        this.collectionReady = true;
                        this.itemsByCollectionRepository.xl = [];                        
                        this.itemsByCollectionRepository.filterCollection = this.collection.id;				
                        await this.itemsByCollectionRepository.load();	
                        this.filteredItems = this.items;				
                        this.itemsReady = true;  
                        this.filterByCollectionRepository.collectionId = this.collection.id;                         
                        await this.filterByCollectionRepository.load();
                        this.filterReady = true;                 
                    } 
                } catch (err) {
                    this.appService.showNotification(err, "error");
                }              
            } else {
                this.setMeta();
            }           
        });
    }
    
    private setMeta(): void {
        this.appService.setTitle(this.collection.title[this.currentLang.slug] || this.collection.name[this.currentLang.slug]);
        this.appService.setMeta("keywords", this.collection.keywords[this.currentLang.slug]);
        this.appService.setMeta("description", this.collection.description[this.currentLang.slug]);
    }

    public applyFilters(): void {
        this.filteredItems = this.itemsService.applyFilters(this.items, this.filter);

    }  

    public onShowInARowChanged(count): void {
        this.showInARow = count;
    }  
}