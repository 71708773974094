import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { AboutPage } from './about.page';

let routes = RouterModule.forChild ([        
    {path:"", component: AboutPage},        
]);

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        routes,        
    ],
    declarations: [
        AboutPage,
    ],
    exports: [
        AboutPage,
    ],
    providers: []
})
export class AboutModule {}
