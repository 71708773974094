<div id="cat-filtersdash" *ngIf="filter && (filter.sizes.length)">
    <div id="cat-filters" class="beatifulscroll grey">
        <ng-container *ngIf="ready">
            <div class="cat-filter" [class.active]="filterActive.size" (click)="toggleFilter('size');" *ngIf="filter.sizes.length"><span>{{currentLang.s['cat']['size']}}</span></div>
            <div class="cat-filter">
              <div class="cat-image-view">
                <button class="cat-image-view-model" [class.active]="getImageView() == 'model'" (click)="setImageView('model')">{{currentLang.s['cat']['view_model']}}</button>
                <button class="cat-image-view-product" [class.active]="getImageView() == 'product'" (click)="setImageView('product')">{{currentLang.s['cat']['view_product']}}</button>
              </div>
            </div>
            <div class="cat-filter" [class.active]="filterActive.sortBy" (click)="toggleFilter('sortBy');"><span>{{currentLang.s['cat']['sortBy']}}</span></div>
        </ng-container>
    </div>
    <ng-container *ngIf="ready">
        <div class="cat-checkboxes beatifulscroll" *ngIf="filterActive.size">
            <div class="cat-checkboxes-wrap">
                <div class="cat-checkboxes-wrap2">
                    <div class="cat-checkbox" *ngFor="let s of filter.sizes" (click)="s.selected = !s.selected; applyFilters();">
                        <checkbox [value]="s.selected" [label]="s.name"></checkbox>
                    </div>
                </div>
            </div>
        </div>
      <div class="cat-checkboxes beatifulscroll" *ngIf="filterActive.sortBy">
        <div class="cat-checkboxes-wrap">
          <div class="cat-checkboxes-wrap2">
            <div class="cat-checkbox" *ngFor="let sort of ['price_asc', 'price_desc']" (click)="filter.sortBy = sort; applyFilters();">
              <radio [value]="sort == filter.sortBy" [label]="currentLang.s['cat']['sortBy_' + sort]"></radio>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
</div>