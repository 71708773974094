export class StatisticsCheckoutStep {
  static get VIEW_CART(): number {
    return 1;
  }
  static get CHECKOUT_START(): number {
    return 2;
  }
  static get CHECKOUT_CONTINUE(): number {
    return 3;
  }
  static get CHECKOUT_FINISH(): number {
    return 4;
  }
}