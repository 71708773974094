<div class="galcollection">     
        <div class="gc-wrap" #galwrap [style.height.px]="h">    
            <div 
                #galcontainer
                class="gc-container"
                [style.left.px]="-left"                        
                (touchstart)="onDragStart($event);"
                (mousedown)="onDragStart($event);"  
                (transitionend)="onTransitionEnd();"                
            >            
                <div class="gc-item" [style.width.px]="w" *ngFor="let p of pictures">
                    <div class="gc-itemwrap">
                        <div class="gc-itemwrap2">
                            <div class="gc-picture" [style.background-image]="'url(/images/'+p.img+')'"></div>
                        </div>
                    </div>
                </div>            
            </div>        
        </div>
        <div class="gc-switch">        
            <div class="gc-switch-item" *ngFor="let s of steps" [class.active]="s === step" (click)="moveTo(s);"></div>                
        </div>        
</div>
