import { Component, OnInit } from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { CartInfoMessage } from 'src/app/model/cart.info.message.interface';

@Component({
    selector: "cart-info-window",
    templateUrl: "./cart.info.window.component.html",
    styleUrls: ["./cart.info.window.component.scss"]
})

export class CartInfoWindowComponent implements OnInit {
    public messages: Array<CartInfoMessage> = [];
    constructor(
        private appService: AppService,
        private cartService: CartService
    ) {}
    
    get currentLang(): Lang {
        return this.appService.currentLang.value;
    }

    public ngOnInit(): void {
        this.cartService.addCartInfoMessage.subscribe((messageObj) => {
            const result = this.messages.find(message => messageObj.id === message.id);
            if(!result){
                this.messages.push(messageObj);
            }
        });
    }

    public removeMessage(messageObj){
        let index = this.messages.indexOf(messageObj);
        this.messages[index].show = false; 
    }
}