import { Component } from "@angular/core";

import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Lang } from 'src/app/model/orm/lang.model';
import { LangsRepository } from 'src/app/services/repositories/langs.repository';
import { CurrenciesRepository } from 'src/app/services/repositories/currencies.repository';
import { Currency } from 'src/app/model/orm/currency.model';

@Component({
    selector: "the-footer",
    templateUrl: "footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
    constructor(
        private appService: AppService,
        private pagesRepository: PagesRepository,
        private langsRepository: LangsRepository,
        private currenciesRepository: CurrenciesRepository,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}
    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
    get langs(): Lang[] {return this.langsRepository.xl;}
    get currencies(): Currency[] {return this.currenciesRepository.xl;}
    get pagesHelp(): Page[] {return this.pagesRepository.pagesHelp;}
    get pagesMore(): Page[] {return this.pagesRepository.pagesMore;}
    
    public getLangLink(langSlug: string): string {
        return this.appService.getLangLink(langSlug);
    }

    public setCurrency(currency: Currency): void {
        this.appService.setCurrency(currency);
    }
}