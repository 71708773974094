export class StatisticsGoogleCheckoutStep {
    constructor(
        public actionField: {
          step: number,
          option?: string
        },
        public products: {
          name: string,
          id: string,
          price?: string,
          brand?: string,
          category?: string,
          variant?: string,
          quantity?: number
        }[]
    ) {}
}
