<div id="itemspage">
    <!-- head -->
    <div id="ip-head" [style.background-image]="'url(\'/images/wishlist/wishlist.jpg\')'" *ngIf="pageReady">
        <div id="ip-head-content">
            <h1>{{currentLang.s['wishlist']['title']}}</h1>
        </div>
    </div>
    <br>
    <br>
    <!-- items -->
    <ng-container *ngIf="pageReady">
        <div id="ip-items" [class.one-in-a-row]="showInARow==1">
            <div *ngFor="let item of wishlist.records">
                <cat-item [item]="item.item"></cat-item>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!wishlist.records.length">
        <div class="compact">
            <h2 class="padded">{{currentLang.s['wishlist']['empty']}}</h2>
        </div>
    </ng-container>
</div>
