import { Component, Input, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
    selector: "slide-menu",
    templateUrl: "./slidemenu.component.html",
    styleUrls: ["./slidemenu.component.scss"]
})
export class SlidemenuComponent implements AfterViewInit {
    @Input() head: string;
    @Input() pages: Page[];
    @Input() urlprefix: string = null;
    public active: boolean = false;
    @ViewChild("wrap", {static: false}) wrapElementRef: ElementRef;
    private wrapElement: HTMLElement;

    constructor(private appService: AppService) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.wrapElement = this.wrapElementRef.nativeElement;
        }, 1);
    }

    public activate(): void {
        this.wrapElement.style.height = this.wrapElement.scrollHeight + "px";
        this.active = true;
    }

    public deactivate(): void {
        this.wrapElement.style.height = "30px";
        this.active = false;
    }

    public toggle(): void {
        this.active ? this.deactivate() : this.activate();
    }
}
