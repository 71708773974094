<div class="sizematch" [class.active]="active">
    <div class="sm-instrum">
        <div class="sm-scale"></div>
        <div class="sm-handles">
            <div class="sm-handle" [class.active]="sizematch === -2" (click)="setTo(-2);"></div>
            <div class="sm-handle" [class.active]="sizematch === -1" (click)="setTo(-1);"></div>
            <div class="sm-handle" [class.active]="sizematch === 0" (click)="setTo(0);"></div>
            <div class="sm-handle" [class.active]="sizematch === 1" (click)="setTo(1);"></div>
            <div class="sm-handle" [class.active]="sizematch === 2" (click)="setTo(2);"></div>
        </div>
    </div>
    <div class="sm-titles">
        <div>{{currentLang.s['reviews']['toosmall']}}</div>
        <div>{{currentLang.s['reviews']['truetosize']}}</div>
        <div>{{currentLang.s['reviews']['toolarge']}}</div>
    </div>
</div>
