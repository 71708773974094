export class StatisticsGoogleAddToCart {
    constructor(
      public currencyCode: string,

      public products: {                            // List of productFieldObjects.
        name: string,     // Name or ID is required.
        id: string,
        price: string,
        category: string,
        variant: string,
        quantity: number
       }[]

    ) {}
}
