<div class="catsslider">
    <div class="cs-container">
        <div class="cs-content">
            <div class="cs-left">
                <ul>
                    <li *ngFor="let c of cats"><a routerLink="/{{currentLang.slug}}/shop/{{c.slug}}">{{c.name[currentLang.slug]}}</a></li>
                </ul>
            </div>
            <div class="cs-right">
                <ul>
                    <li><a target="_blank" href="{{currentLang.s['home']['friendly-link']}}">{{currentLang.s['home']['friendly-name']}}</a></li>
                </ul>
            </div>
        </div>    
    </div>    
</div>
