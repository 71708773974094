import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from '../../services/app.service';
import { Page } from 'src/app/model/orm/page.model';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Lang } from 'src/app/model/orm/lang.model';
import { ItemsHitsRepository } from 'src/app/services/repositories/itemshits.repository';
import { Item } from 'src/app/model/orm/item.model';
import { GalsRepository } from 'src/app/services/repositories/gals.repository';
import { DiscountRepository } from 'src/app/services/repositories/discount.repository';
import { Gal } from 'src/app/model/orm/gal.model';
import { Discount } from 'src/app/model/orm/discount.model';
import { IGalitem } from 'src/app/model/orm/galitem.interface';

@Component({
	selector: 'home-page',
	templateUrl: './home.page.html',
	styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {
	public page: Page = null;	
	public itemsReady: boolean = false;
	public discountReady: boolean = false;
	public splash: IGalitem = null;
	public discount: Discount = null;
	
	constructor(
		private appService: AppService,		
		private route: ActivatedRoute,
		private router: Router,
		private pagesRepository: PagesRepository,
		private itemshitsRepository: ItemsHitsRepository,
		private galsRepository: GalsRepository,
		private discountRepository: DiscountRepository
	) {}

	get currentLang(): Lang {return this.appService.currentLang.value;}	
	get items(): Item[] {return this.itemshitsRepository.xl;}

	public ngOnInit(): void {
		this.appService.headerAlwaysOpaque = false;	
		this.appService.catmenumobileActive = false;
		this.appService.collmenumobileActive = false;					
		// page
		this.page = this.pagesRepository.xl.find(x => x.slug === "home") || null;

		if (!this.page) {
			this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
		} else {
			this.route.params.subscribe(p => {
				this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);
				this.appService.setMeta("keywords", this.page.keywords[this.currentLang.slug]);
				this.appService.setMeta("description", this.page.description[this.currentLang.slug]);				
			});		

			// items
			this.itemshitsRepository.load()
				.then(() => this.itemsReady = true)
				.catch(err => this.appService.showNotification(err, "error"));

			// splash
			let splashGal: Gal = this.galsRepository.xl.find(x => x.name === "home-splash");
        	splashGal ? this.splash = splashGal.items[0] : null;
			this.discountRepository
				.load()
				.then(() => {
					this.discountReady = true;
					this.discount = this.discountRepository.discount; 
				}).catch(err => this.appService.showNotification(err, "error"));
		}
	}
}
