import { Injectable } from "@angular/core";
import { DataService } from '../data.service';
import { IFilter } from 'src/app/model/filter.interface';
import { IFiltersGetDTO } from 'src/app/model/dto/filters.get.dto';

@Injectable()
export class FilterBySearchRepository {    
    public name: string;
    public langId: number;
    public filter: IFilter = null;

    constructor(private dataService: DataService) {}

    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            let dto: IFiltersGetDTO = {name: this.name, langId: this.langId};
            this.dataService.filtersBySearch(dto).subscribe(res => {                                                            
                if (res.statusCode === 200) {                    
                    this.filter = res.data;
                    this.filter.selectedMinPrice = this.filter.minPrice;
                    this.filter.selectedMaxPrice = this.filter.maxPrice;
                    this.filter.sortBy = 'default';
                    resolve();
                } else {                        
                    reject(res.statusCode+": "+res.error);
                }
            }, err => {
                reject(err.message);
            });
        });
    }
}