import { Injectable } from "@angular/core";
import { DataService } from 'src/app/services/data.service';
import { IWaitlistDTO } from './waitlist.dto';

@Injectable()
export class WaitlistService {
    public dto: IWaitlistDTO;

    constructor(private dataService: DataService) {}

    public sendJoin(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.dataService.waitsCreate(this.dto).subscribe(res => {
                if (res.statusCode === 200) {
                    resolve();
                } else {
                    reject(res.statusCode+": "+res.error);
                }
            }, err => {
                reject(err.message);
            });
        });
    }
}