import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CCModule } from 'src/app/common.components/cc.module';
import { PrivatePage } from './private/private.page';
import { AddressPage } from './address/address.page';
import { LogoutPage } from './logout/logout.page';
import { HistoryPage } from './history/history.page';
import { OrderPage } from './order/order.page';
import { RetturnPage } from './retturn/retturn.page';

let routes = RouterModule.forChild ([
    //{path: "private", component: PrivatePage},
    //{path: "address", component: AddressPage},
    //{path: "history", component: HistoryPage},
    //{path: "history/:orderid", component: OrderPage},
    //{path: "history/:orderid/retturn/:orderitemid", component: RetturnPage},
    //{path: "logout", component: LogoutPage},
    {path: '**', redirectTo: '/ua/404'}
]);

@NgModule({
    imports: [                
        CommonModule,
        RouterModule,
        FormsModule,
        routes,
        CCModule,
    ],
    declarations: [
        PrivatePage,
        AddressPage,
        HistoryPage,
        OrderPage,
        LogoutPage,
        RetturnPage,
    ],
    exports: [],
    providers: []
})
export class AccountModule {}
