import { Component, Input, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Item } from 'src/app/model/orm/item.model';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: "cart-recommended",
    templateUrl: "./cart-recommended.component.html",
    styleUrls: ["./cart-recommended.component.scss"]
})
export class CartRecommendedComponent {
    @Input() items: Item[];
    public w: number = 0;
    @ViewChild("container", {static: false}) containerRef: ElementRef;   
    private container: HTMLElement;     
    private winWidth: number = 0;
    
    constructor(private appService: AppService) {}    

    public ngAfterViewInit(): void {
        if (this.appService.isBrowser) {
            setTimeout(() => {
                this.container = this.containerRef.nativeElement;
                this.initLayout();
            }, 1);
        }
    }

    private initLayout(): void {
        this.winWidth = window.innerWidth;

        if (this.winWidth > 1000) {
            this.w = this.container.offsetWidth / 5;
        } else if (this.winWidth > 640) {
            this.w = this.container.offsetWidth / 3;
        } else {
            this.w = this.container.offsetWidth / 2;
        }        
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth !== this.winWidth) {
            this.initLayout();
        }        
    }
}