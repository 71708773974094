import { Injectable } from "@angular/core";
import { StatisticsGoogleProductView } from '../model/statistics_GoogleProductView';
import { StatisticsGoogleProductDetailView } from '../model/statistics_GoogleProductDetailView';
import { StatisticsGoogleAddToCart } from '../model/statistics_GoogleAddToCart';
import { StatisticsGoogleCheckoutStep } from '../model/statistics_GoogleCheckoutStep';
import { StatisticsGooglePurchase } from '../model/statistics_GooglePurchase';

import { environment } from '../../environments/environment';

declare const dataLayer: any;

@Injectable()
export class StatisticsGoogleService {
    public viewContent(statisticsGoogle: StatisticsGoogleProductView): void {
        this.clearThePreviousEcommerceObject();
        let googleData = {
            event: 'impressions',
            ecommerce: {
                currencyCode: statisticsGoogle.currencyCode,
                impressions: statisticsGoogle.impressions
            }
        };
        this.sendDataToGoogle(googleData);
    }
    public viewProductDetails(statisticsGoogle: StatisticsGoogleProductDetailView): void {
        this.clearThePreviousEcommerceObject();
        let googleData = {
            event: 'detail',
            ecommerce: {
                detail: {
                    actionField: statisticsGoogle.actionField,
                    products: statisticsGoogle.products
                }
            }
        };
        this.sendDataToGoogle(googleData);
    }
    public addToCart(statisticsGoogle: StatisticsGoogleAddToCart): void {
        this.clearThePreviousEcommerceObject();
        let googleData = {
            event: 'addToCart',
            ecommerce: {
                currencyCode: statisticsGoogle.currencyCode,
                add: {
                    products: statisticsGoogle.products
                }
            }
        };
        this.sendDataToGoogle(googleData);
    }
    public checkoutStep(statisticsGoogle: StatisticsGoogleCheckoutStep): void {
        this.clearThePreviousEcommerceObject();
        let googleData = {
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: statisticsGoogle.actionField,
                    products: statisticsGoogle.products
                }
            }
        };
        this.sendDataToGoogle(googleData);
    }
    public purchase(statisticsGoogle: StatisticsGooglePurchase): void {
        this.clearThePreviousEcommerceObject();
        let googleData = {
            event: 'purchase',
            ecommerce: {
                purchase: {
                    actionField: statisticsGoogle.actionField,
                    products: statisticsGoogle.products
                }
            }
        };
        this.sendDataToGoogle(googleData);
    }
    clearThePreviousEcommerceObject(): void {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });
    }
    sendDataToGoogle(data: any): void {
        window['dataLayer'] = window['dataLayer'] || [];
        if (environment.production) {
            window['dataLayer'].push(data);
        } else {
            console.log('window[\'dataLayer\'].push', data);
        }
    }
}
