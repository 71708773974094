import { Injectable } from "@angular/core";

import { DataService } from 'src/app/services/data.service';
import { Review } from 'src/app/model/orm/review.model';

@Injectable()
export class ReviewsService {
    constructor(private dataService: DataService) {}

    public send(x: Review): Promise<void> {
        return new Promise((resolve, reject) => {
            this.dataService.reviewsSend(x).subscribe(res => {
                if (res.statusCode === 200) {
                    resolve();
                } else {
                    reject(res.statusCode+": "+res.error);
                }
            }, err => {
                reject(err.message);
            });
        });
    }
}
