import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CCModule } from 'src/app/common.components/cc.module';
import { CollectionPage } from './collection/collection.page';

let routes = RouterModule.forChild ([        
    {path: ":collection", component: CollectionPage},        
]);

@NgModule({
    imports: [                
        CommonModule,
        RouterModule,
        FormsModule,
        routes,
        CCModule,
    ],
    declarations: [
        CollectionPage,
    ],
    exports: [
        CollectionPage,
    ],
    providers: []
})
export class CollectionsModule {}
