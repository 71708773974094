import { Model } from '../model';
import { IOrderitem } from './orderitem.interface';

export class Order extends Model {
    public id: number;
    public created_at: string;
    public total: number;
    public currency: string;
    public orderitems: IOrderitem[];
}
