import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnInit, HostListener, OnChanges, SimpleChanges } from "@angular/core";

import { IPicture } from 'src/app/model/orm/picture.interface';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: "gal-collection",
    templateUrl: "./galcollection.component.html",
    styleUrls: ["./galcollection.component.scss"],
})
export class GalCollectionComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() pictures: IPicture[];
    private n: number = 0;
    private framesPerSection: number = 0;
    public w: number = 0;    
    public h: number = 0;
    private winWidth: number = 0;
    @ViewChild("galwrap", {static: false}) galwrapRef: ElementRef;
    @ViewChild("galcontainer", {static: false}) galcontainerRef: ElementRef;
    private galwrap: HTMLElement = null;
    private galcontainer: HTMLElement = null;    
    public dataReady: boolean = false;
    public layoutReady: boolean = false;       
    // movement
    public step: number = 0;
    public steps: number[] = [];
    public left: number = 0;
    public active: boolean = false;  
    public moving: boolean = false;
    private startX: number = 0;    
    private startXOffsetted: number = 0;
    private startY: number = 0;
    private prevX: number = 0;

    constructor(private appService: AppService) {}

    get isBrowser(): boolean {return this.appService.isBrowser;}
    get isServer(): boolean {return this.appService.isServer;}

    public ngOnChanges(changes: SimpleChanges): void {
        // reinit only, not first time
        this.dataReady ? this.initData() : null; 
        this.layoutReady ? this.initLayout() : null;
    }

    public ngOnInit(): void {
        this.onDragMove = this.onDragMove.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);       
        this.initData();
    }

    public ngAfterViewInit(): void {
        if (this.isBrowser) {
            setTimeout(() => {
                this.galwrap = this.galwrapRef.nativeElement;
                this.galcontainer = this.galcontainerRef.nativeElement;                                  
                this.initLayout();                                
            }, 1);
        }        
    }
    
    private initData(): void {
        this.n = this.pictures.length;
        this.dataReady = true;
    }

    private initLayout() {
        this.winWidth = window.innerWidth; 
        this.framesPerSection = window.innerWidth < 1000 ? 2 : 3;
        this.w = this.galwrap.offsetWidth / this.framesPerSection;
        this.left = 0;
        this.step = 0;
        this.steps = [];
        this.moving = false;

        for (let i = 0; i < Math.ceil(this.n / this.framesPerSection); i++) {
            this.steps.push(i);
        }

        setTimeout(() => this.h = this.galcontainer.clientHeight, 1);
        this.layoutReady = true;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth != this.winWidth) {
            this.initLayout();
        } 
    }

    public moveTo(i: number): void {
        if (!this.moving && this.step !== i) {
            this.moving = true;
            this.step = i;
            this.left = this.w * i * this.framesPerSection;
        }        
    }

    public onDragStart (event: TouchEvent | MouseEvent): void {           
        this.startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        this.startXOffsetted = this.startX - this.galcontainer.offsetLeft;	
        this.startY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
        
        if (event instanceof MouseEvent) {    // just start moving             
            this.galcontainer.style.transition = "none";
            this.active = true; 
            window.addEventListener("mousemove", this.onDragMove, {passive: false});
            window.addEventListener("mouseup", this.onDragEnd);              
        } else { // detect if moving is more by X than by Y
            window.addEventListener("touchmove", this.onTouchMove);                            
        }
    }

    public onTouchMove(event: TouchEvent): void {
        let deltaX = Math.abs(this.startX - event.touches[0].clientX);
        let deltaY = Math.abs(this.startY - event.touches[0].clientY);
        window.removeEventListener("touchmove", this.onTouchMove);

        if (deltaX > deltaY && !this.moving) {    
            this.galcontainer.style.transition = "none";
            this.active = true;    
            window.addEventListener("touchmove", this.onDragMove, {passive: false});
            window.addEventListener("touchend", this.onDragEnd);  
        }
    }

    public onDragEnd (): void {
        window.removeEventListener("mousemove", this.onDragMove);
        window.removeEventListener("touchmove", this.onDragMove);
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("touchend", this.onDragEnd);
        this.galcontainer.style.transition = "left 0.3s ease 0s";
        this.active = false;
        
        // доводка 
        if (this.moving) {
            if (this.prevX < this.startX - 30 && this.step < this.steps.length - 1) {
                this.step++;
            } else if (this.prevX > this.startX + 30 && this.step > 0) {
                this.step--;
            }        
            
            this.left = this.step * this.w * this.framesPerSection;        
        }                        
    }    

    public onDragMove (event: TouchEvent | MouseEvent): void {        
        event.cancelable ? event.preventDefault() : null;
        this.moving = true;
        const x: number = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        let nextX: number = this.startXOffsetted - x;            
        this.left = nextX;            
        this.prevX = x;        
    } 

    public onTransitionEnd(): void {
        setTimeout(() => this.moving = false, 100);
    }
}