import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { LangsRepository } from 'src/app/services/repositories/langs.repository';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Page } from 'src/app/model/orm/page.model';
import { GalsRepository } from 'src/app/services/repositories/gals.repository';
import { Gal } from 'src/app/model/orm/gal.model';
import { Cat } from 'src/app/model/orm/cat.model';
import { CatsRepository } from 'src/app/services/repositories/cats.repository';
import { CurrenciesRepository } from 'src/app/services/repositories/currencies.repository';
import { Currency } from 'src/app/model/orm/currency.model';
import { CollectionsRepository } from 'src/app/services/repositories/collections.repository';
import { Collection } from 'src/app/model/orm/collection.model';
import { CartService } from 'src/app/services/cart.service';
import { Wishlist } from 'src/app/model/wishlist';
import { WishlistService } from 'src/app/services/wishlist.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: "the-mobmenu",
    templateUrl: "./mobmenu.component.html",
    styleUrls: ["./mobmenu.component.scss"]
})
export class MobmenuComponent implements OnInit {
    public catmenuActive: boolean = false;
    public collmenuActive: boolean = false;
    public collmenuActive1: boolean = false;
    public search: string = "";
    public pagesMain: Page[] = [];
    public pagesAdditional: Page[] = [];
    public cats: Cat[] = [];
    public cats1: Cat[] = [];
    public collections: Collection[] = [];
    public topcats: Cat[] = [];
    public gal: Gal = null;

    constructor(
        private appService: AppService,        
        private langsRepository: LangsRepository,
        private pagesRepository: PagesRepository,
        private galsRepository: GalsRepository,
        private catsRepository: CatsRepository,
        private collectionsRepository: CollectionsRepository,
        private currenciesRepository: CurrenciesRepository,
        private cartService: CartService,
        private wishlistService: WishlistService,
        private authService: AuthService,
        private router: Router,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}
    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
    get langs(): Lang[] {return this.langsRepository.xl;}
    get currencies(): Currency[] {return this.currenciesRepository.xl;}
    get mobmenuActive(): boolean {return this.appService.mobmenuActive;}
    set mobmenuActive(v: boolean) {this.appService.mobmenuActive = v;}
    get cartpanelActive(): boolean {return this.appService.cartpanelActive;} 
    set cartpanelActive(v: boolean) {this.appService.cartpanelActive = v;}
    get cartQ(): number {return this.cartService.q;}
    get wishlist(): Wishlist {return this.wishlistService.wishlist;}
    get wishlistQ(): number {return this.wishlistService.q;}
    get loginpanelActive(): boolean {return this.appService.loginpanelActive;}
    set loginpanelActive(v: boolean) {this.appService.loginpanelActive = v;}
    set searchActive(v: boolean) {this.appService.searchActive = v;}
    get authenticated(): boolean {return this.authService.authenticated;}
    get currentRoute(): string {return window.location.pathname;}

    public ngOnInit(): void {
        this.pagesMain = this.pagesRepository.xl.filter(p => p.inmenu && p.inburger && p.section === 1 && p.slug !== 'home');
        this.pagesAdditional = this.pagesRepository.xl.filter(p => p.inmenu && p.inburger && (p.section === 2 || p.section === 3));
        this.cats = this.catsRepository.xl.filter(c => !c.inmenu && c.inburger);
        this.cats1 = this.catsRepository.xl.filter(c => c.inmenuc);
        this.topcats = this.catsRepository.xl.filter(c => c.inmenu && c.inburger);
        this.collections = this.collectionsRepository.xl;
        this.gal = this.galsRepository.xl.find(x => x.name === "mobmenu");        
    }

    public getLangLink(langSlug: string): string {
        return this.appService.getLangLink(langSlug);
    }

    public setCurrency(currency: Currency): void {
        this.appService.setCurrency(currency);
    }

    public goSearch(): void {
        if (this.search.length > 2) {
            this.mobmenuActive = false;
            this.appService.searchKeyword.next(this.search);
            this.search = "";
            this.router.navigateByUrl(`/${this.currentLang.slug}/shop/search`);
        }
    }
}
