<div class="gal" #galwrap>    
    <!-- main version -->
    <ng-container *ngIf="isBrowser">
        <div 
            #galcontainer
            class="gal-container" 
            [class.visible] = "visible"
            [style.left]="-left+'px'"            
            (touchstart)="onDragStart($event);"            
            (mousedown)="onDragStart($event);"            
            (transitionend)="adjustInfiniteMotion()"
        >
            <ng-container *ngIf="ready">
                <ng-container *ngFor="let x of xl">
                        <div class="gal-item" [style.background-image]="'url(\'/images/gals/'+x.img+'\')'" [style.width]="w+'px'">
                            <div class="gi-headlink">
                                <div class="gi-head" *ngIf="x.header[currentLang.slug]" [innerHTML]="x.header[currentLang.slug]"></div>
                                <div class="gi-link" *ngIf="x.title[currentLang.slug] && x.link[currentLang.slug]"><a [routerLink]="x.link[currentLang.slug]">{{x.title[currentLang.slug]}}</a></div>                    
                            </div>

                            <ng-container *ngIf="false && discount && x.i == 0 && timeLeft">
                                <div class="timer" [style.color]="discount.color">
                                    <div class="days">
                                        <div class="timer-number" [innerHTML]="timeLeft.days"></div>
                                        <div class="timer-header">{{currentLang.s['home']['timer-days']}}</div>
                                    </div>
                                    <div class="separator">:</div>
                                    <div class="hours">
                                        <div class="timer-number" [innerHTML]="timeLeft.hours"></div>
                                        <div class="timer-header">{{currentLang.s['home']['timer-hours']}}</div>
                                    </div>
                                    <div class="separator">:</div>
                                    <div class="minutes">
                                        <div class="timer-number" [innerHTML]="timeLeft.minutes"></div>
                                        <div class="timer-header">{{currentLang.s['home']['timer-minutes']}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="gal-left" (click)="moveLeft();"></div>
        <div class="gal-right" (click)="moveRight();"></div>
    </ng-container>
    <!-- dummy for server version -->    
    <ng-container *ngIf="ready">
        <div class="gal-dummy" [style.background-image]="'url(\'/images/gals/'+items[0].img+'\')'"></div>
    </ng-container>        
</div>