import { Model } from '../model';

export class Lang extends Model {
    public id: number;
    public slug: string;
    public title: string;    
    public defended: boolean;

    public s: Object;    
}
