import { Injectable } from "@angular/core";
import { StatisticsFacebook } from '../model/statistics_Facebook';
import { StatisticsGoogleProductView } from '../model/statistics_GoogleProductView';
import { StatisticsGoogleProductDetailView } from '../model/statistics_GoogleProductDetailView';
import { StatisticsGoogleAddToCart } from '../model/statistics_GoogleAddToCart';
import { StatisticsGoogleCheckoutStep } from '../model/statistics_GoogleCheckoutStep';
import { StatisticsGooglePurchase } from '../model/statistics_GooglePurchase';
import { Item } from '../model/orm/item.model';
import { Cat } from '../model/orm/cat.model';
import { ICartRecord } from '../model/cartrecord.interface';

@Injectable()
export class StatisticsConverterService {
    public toStatisticsFacebookProductDetailsView(
            item: Item, 
            cat: Cat, 
            currentLangSlug: string, 
            currencyIn: string, 
            price: string
        ): StatisticsFacebook {
        let value = price;
        let currency = currencyIn;
        let content_name = item.name[currentLangSlug];
        let content_type = 'product';
        let content_category = cat.name[currentLangSlug];
        let content_ids = [item.id.toString()];
        return new StatisticsFacebook(
                value,
                currency,
                content_name,
                content_type,
                content_category,
                content_ids,
                ''
            );
    }
    public toStatisticsFacebookAddToCart(
            item: Item, 
            cat: Cat, 
            currentLangSlug: string, 
            currencyIn: string, 
            price: string
        ): StatisticsFacebook {
        let value = price;
        let currency = currencyIn;
        let content_name = item.name[currentLangSlug];
        let content_type = 'product';
        let content_category = cat.name[currentLangSlug];
        let content_ids = [item.id.toString()];     
        return new StatisticsFacebook(
                value,
                currency,
                content_name,
                content_type,
                content_category,
                content_ids,
                ''
            );
    }
    public toStatisticsFacebookCheckoutInitiated(
            records: ICartRecord[],
            currentLangSlug: string, 
            currencyIn: string, 
            price: string,
            num_items: string
        ): StatisticsFacebook {
        let value = price;
        let currency = currencyIn;
        let content_name = '';
        let content_category = '';
        let content_type = 'product';

        let content_ids = [];
        records.forEach(record => {
            content_ids.push(record.item.id.toString());
        }); 
        return new StatisticsFacebook(
                value,
                currency,
                content_name,
                content_type,
                content_category,
                content_ids,
                num_items
            );
    }
    public toStatisticsFacebookPurchase(
            records: ICartRecord[],
            currentLangSlug: string, 
            currencyIn: string, 
            price: string,
            num_items: string
        ): StatisticsFacebook {
        let value = price;
        let currency = currencyIn;
        let content_name = '';
        let content_type = 'product';
        let content_category = '';

        let content_ids = [];
        records.forEach(record => {
            content_ids.push(record.item.id.toString());
        });
        return new StatisticsFacebook(
                value,
                currency,
                content_name,
                content_type,
                content_category,
                content_ids,
                num_items
            );
    }
    
    public toStatisticsGoogleProductView(
            currencyCode: string,
            item: Item, 
            cat: Cat, 
            currentLangSlug: string,
            price: string
        ): StatisticsGoogleProductView { 

        let name: string = item.name[currentLangSlug];
        let category: string = cat.name[currentLangSlug];
        let id: string = item.id.toString();
        let variant: string = item.color ? item.color.name[currentLangSlug] : "";

        return new StatisticsGoogleProductView(
                currencyCode,
                [
                    {
                        id,
                        name,
                        price,
                        category,
                        variant
                    }
                ]
            );
    }
    public toStatisticsGoogleProductDetailView(
            currencyCode: string,
            item: Item, 
            cat: Cat, 
            currentLangSlug: string,
            price: string
        ): StatisticsGoogleProductDetailView { 

        let name: string = item.name[currentLangSlug];
        let category: string = cat.name[currentLangSlug];
        let id: string = item.id.toString();
        let variant: string = item.color ? item.color.name[currentLangSlug] : "";

        return new StatisticsGoogleProductDetailView(
                {list: "Product Page"},
                [
                    {
                        id,
                        name,
                        price,
                        category,
                        variant
                    }
                ]
            );
    }
    public toStatisticsGoogleAddToCart(
            currencyCode: string,
            item: Item,
            cat: Cat,
            currentLangSlug: string,
            price: string,
            quantity: number
        ): StatisticsGoogleAddToCart {

        let name: string = item.name[currentLangSlug];
        let category: string = cat.name[currentLangSlug];
        let id: string = item.id.toString();
        let variant: string = item.color ? item.color.name[currentLangSlug] : "";

        let products = [
            {
                id,
                name,
                price,
                category,
                variant,
                quantity
            }
        ];  
        return new StatisticsGoogleAddToCart(
                currencyCode,
                products 
            );
    }
    public toStatisticsGoogleCheckoutStep(
            step: number,
            currentCurrency: string,
            records: ICartRecord[],
            currentLangSlug: string
        ): StatisticsGoogleCheckoutStep {
        let products = [];
        records.forEach(record => {
            products.push({
                name: record.item.name[currentLangSlug],
                id: record.item.id,
                price: record.item.price.toString(),
                category: record.item.catslug,
                variant: record.item.color?record.item.color.name[currentLangSlug]:'',
                quantity: record.q
           });
        });
        return new StatisticsGoogleCheckoutStep(
                { step },
                products
            );
    }
    public toStatisticsGooglePurchase(
            currentCurrency: string,
            records: ICartRecord[],
            currentLangSlug: string,
            transactionId: string,
            revenue: string,
            coupon: string
        ): StatisticsGooglePurchase {
        let products = [];
        records.forEach(record => {
            products.push({
                name: record.item.name[currentLangSlug],
                id: record.item.id,
                price: record.item.price.toString(),
                category: record.item.catslug,
                variant: record.item.color?record.item.color.name[currentLangSlug]:'',
                quantity: record.q
           });
        });
        let actionField = {
            id: transactionId,
            revenue: revenue,
            coupon: coupon
        };
        return new StatisticsGooglePurchase(
                actionField,
                products
            );
    }
}
