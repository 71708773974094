import { Model } from '../model';

export class Cat extends Model {
    public id: number;
    public img: string;
    public img_s: string;
    public slug: string;        
    public inmenu: boolean;
    public inmenuc: boolean;
    public inburger: boolean;
    public inhome: boolean;

    public name: Object;
    public h1: Object;
    public contentshort: Object;
    public content: Object;
    public title: Object;
    public keywords: Object;
    public description: Object;
    public diff_name: Object
}
