<div class="nosizemodal" [class.active]="active" (click)="onClick($event);">
  <div class="wl-win">
    <div class="wl-close" (click)="close();"></div>
    <div class="wl-content">
      <div class="wl-head"></div>
      <div class="wl-info" style="margin-top: 31px">{{currentLang.s['item']['nosize']}}</div>
      <div class="wl-form">
        <div class="wl-form-row">
          <div class="wl-form-btnwrap">
            <button type="button" (click)="close();">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
