<the-notification></the-notification>
<ng-container *ngIf="langsReady && pagesReady && galsReady && catsReady && currenciesReady && collectionsReady && countriesReady && settingsReady">
    <ng-container *ngIf="active">
        <ng-container *ngIf="preHeaderData">
            <the-pre-header [data]="preHeaderData"></the-pre-header>
        </ng-container>
        <the-header2 [isShowPreHeader]="!!preHeaderData"></the-header2>
        <the-mobmenu></the-mobmenu>
        <main [class.with-pre-header]="!!preHeaderData">
            <router-outlet></router-outlet>
        </main>
        <the-footer></the-footer>
        <cart-panel></cart-panel>
        <login-panel></login-panel>
        <register-panel></register-panel>
        <recover-panel></recover-panel>
        <ng-container *ngIf="isShowWelcomePopup">
          <the-welcome-popup [content]="welcomePopupContent" [(active)]="isShowWelcomePopup" (activeChange)="onPopupActiveChange($event)"></the-welcome-popup>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!active">
        <dummy-component></dummy-component>
    </ng-container>
    <cart-info-window></cart-info-window>
</ng-container>
