import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { PipesModule } from '../../pipes/pipes.module';
import { CCModule } from 'src/app/common.components/cc.module';
import { CatPage } from './cat/cat.page';
import { SearchPage } from './search/search.page';
import { ItemPage } from './item/item.page';
import { ReviewsComponent } from './item/reviews/reviews.component';
import { ReviewsService } from './item/reviews/reviews.service';
import { SizeguidesComponent } from './item/size-guides/sizeguides.component';
import { CartPage } from './cart/cart.page';
import { WishlistPage } from './wishlist/wishlist.page';
import { CartRecommendedComponent } from './cart/cart-recommended/cart-recommended.component';
import { CheckoutStartPage } from './checkout-start/checkoutstart.page';
import { CheckoutContinuePage } from './checkout-continue/checkoutcontinue.page';
import { CheckoutFinishPage } from './checkout-finish/checkoutfinish.page';
import { CatTimerComponent } from 'src/app/common.components/cat-timer/cat.timer';

let routes = RouterModule.forChild ([
    {path: "search", component: SearchPage},
    {path: "cart", component: CartPage},
    {path: "wishlist", component: WishlistPage},
    {path: "checkout-start", component: CheckoutStartPage},
    {path: "checkout-continue", component: CheckoutContinuePage},
    {path: "checkout-finish/:order", component: CheckoutFinishPage},
    {path: ":cat", component: CatPage},
    {path: ":cat/:item", component: ItemPage},
]);

@NgModule({
    imports: [                
        CommonModule,
        RouterModule,
        FormsModule,
        routes,
        CCModule,
        PipesModule,
    ],
    declarations: [
        CatPage,
        SearchPage, 
        ItemPage,   
        CartPage,
        WishlistPage,
        ReviewsComponent,
        SizeguidesComponent,
        CartRecommendedComponent,
        CheckoutStartPage,
        CheckoutContinuePage,
        CheckoutFinishPage,
        CatTimerComponent
    ],
    exports: [PipesModule],
    providers: [
        ReviewsService,
    ]
})
export class ShopModule {}
