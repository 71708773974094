<div class="galpair">
    <div class="gp-wrap">
        <div class="gp-picture" [style.background-image]="img" *ngIf="relatedpos === 'bottom'"></div>
        <div class="gp-gal">            
            <div class="gp-galwrap" #galwrap [style.height.px]="h">
                <div 
                    #galcontainer
                    class="gp-container"
                    [style.left.px]="-left"                        
                    (touchstart)="onDragStart($event);"
                    (mousedown)="onDragStart($event);"
                    (transitionend)="adjustInfiniteMotion();"
                >            
                    <div class="gp-item" [style.width.px]="w" *ngFor="let item of xl">
                        <cat-item [item]="item" [hoverable]="false" [blockImageLink]="moving"></cat-item>
                    </div>            
                </div>
            </div>     
            <!-- тут можно сделать dummy для серверной версии, см. файл стилей, но можно и не делать -->       
            <div class="gp-toleft" (click)="moveLeft();"></div>
            <div class="gp-toright" (click)="moveRight();"></div>
        </div>
        <div class="gp-picture" [style.background-image]="img" *ngIf="relatedpos === 'top'"></div>
    </div>
</div>