import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
    selector: "logout-page",
    template: "",
})
export class LogoutPage implements OnInit {
    constructor(
        private authService: AuthService,
        private router: Router,
        private appService: AppService,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}

    public ngOnInit(): void {
        this.authService.logout();
        let addr: string = this.currentLang.defended ? "/" : `/${this.currentLang.slug}`;
        this.router.navigateByUrl(addr);
    }
}
