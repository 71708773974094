export class StatisticsFacebook {
    constructor(
        public value: string = '',
        public currency: string = '',
        public content_name: string = '',
        public content_type: string = '',
        public content_category: string = '',
        public content_ids: string[] = [],
        public num_items: string = ''
    ) {}
}
