import { Injectable } from "@angular/core";

import { SimpleRepository } from './_simple.repository';
import { Item } from 'src/app/model/orm/item.model';
import { DataService } from '../data.service';
import { IItemsGetmoreDTO } from 'src/app/model/dto/items.getmore.dto';

@Injectable()
export class ItemsMoreRepository extends SimpleRepository<Item> {
    public dto: IItemsGetmoreDTO;
    public xlByColor: Item[] = [];
    public xlByCat: Item[] = [];

    constructor(protected dataService: DataService) {
        super();                      
    }

    public load(): Promise<void> {
        return new Promise((resolve, reject) => {            
            this.dataService.itemsMore(this.dto).subscribe(res => {                                    
                if (res.statusCode === 200) {                                      
                    this.xlByColor =  res.data.bycolor.length ? res.data.bycolor.map(d => new Item().build(d)) : [];                                          
                    this.xlByCat =  res.data.bycat.length ? res.data.bycat.map(d => new Item().build(d)) : [];
                    resolve();
                } else {                        
                    reject(res.statusCode+": "+res.error);
                }
            }, err => {
                reject(err.message);
            });            
        });
    }
}
