import { Injectable } from '@angular/core';

import { DataService } from '../data.service';
import { SimpleRepository } from './_simple.repository';
import { Order } from 'src/app/model/orm/order.model';

@Injectable()
export class OrdersRepository extends SimpleRepository<Order> {
    private userId: number = 0;
    
    constructor(protected dataService: DataService) {
        super();
    }
    
    public load(userId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.userId === userId && new Date().getTime() - this.loadedAt < this.ttl) {
                resolve();
            } else {
                this.userId = userId;
                this.dataService.orderHistory(this.userId).subscribe(res => {                                                            
                    if (res.statusCode === 200) {
                        this.xl = res.data.length ? res.data.map(d => new Order().build(d)) : [];                           
                        this.loadedAt = new Date().getTime();
                        resolve();
                    } else {                        
                        reject(res.statusCode+": "+res.error);
                    }
                }, err => {
                    reject(err.message);
                });
            }            
        });
    }    
}
