import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { AuthService } from 'src/app/services/auth.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { User } from 'src/app/model/orm/user.model';

@Component({
	selector: 'private-page',
	templateUrl: './private.page.html',
	styleUrls: ['../../../common.styles/userdata.scss']
})
export class PrivatePage implements OnInit {
    public page: Page = null;
    public pageReady: boolean = false;
    public msgRecover: string = "&nbsp;";
    public errorRecover: boolean = false;
    public errorName: string = "&nbsp;";
    public errorSurname: string = "&nbsp";   
    
    public formLoading: boolean = false;
    public formMsg: string = "&nbsp;"
    public formError: boolean = false;  
	
	constructor(
		private appService: AppService,		
		private route: ActivatedRoute,
		private router: Router,
		private pagesRepository: PagesRepository,	
		private authService: AuthService,
	) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}	
    get authenticated(): boolean {return this.authService.authenticated;}
    get pagesAcc(): Page[] {return this.pagesRepository.pagesAcc;}
    get user(): User {return this.authService.user;}
	
	public ngOnInit(): void {
		this.appService.headerAlwaysOpaque = true;		
		this.appService.catmenumobileActive = false;	
		this.appService.collmenumobileActive = false;
			
		// page
		this.page = this.pagesRepository.xl.find(x => x.slug === "private") || null;

		if (!this.page) {
			this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
		} else {
            this.pageReady = true;
			this.route.params.subscribe(p => {
				this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);				
			});					
		}		
    }

    public async update(): Promise<void> {
        if (!this.validate()) {
            return;
        }

        try {            
            this.formLoading = true;
            await this.authService.update(this.user);
            this.authService.save();
            this.formLoading = false;
            this.formMsg = this.currentLang.s['user']['update-ok'];              
            setTimeout(() => {this.formMsg = "&nbsp;";}, 3000);            
        } catch (err) {
            this.appService.showNotification(err, "error");
            this.formLoading = false;
        }
    }

    private validate(): boolean {
        let error: boolean = false;

        this.user.name = this.user.name.trim();
        this.user.surname = this.user.surname.trim();          
        
        if (!this.user.name.length) {
            error = true;
            this.errorName = this.currentLang.s['common']['error-required'];
        } else {
            this.errorName = "&nbsp;";
        }

        if (!this.user.surname.length) {
            error = true;
            this.errorSurname = this.currentLang.s['common']['error-required'];
        } else {
            this.errorSurname = "&nbsp;";
        }
        
        return !error;
    }

    public async recover(): Promise<void> {        
        try {            
            this.msgRecover = this.currentLang.s['common']['sending'];
            const status: number = await this.authService.recover(this.user.email);              

            if (status === 200) {  
                this.msgRecover = this.currentLang.s['user']['recover-ok'];              
                setTimeout(() => {this.msgRecover = "&nbsp;";}, 3000);                
            } else if (status === 404) {
                this.msgRecover = this.currentLang.s['user']['recover-notfound'];  
                this.errorRecover = true;              
                setTimeout(() => {this.msgRecover = "&nbsp;"; this.errorRecover = false;}, 3000);
            } else {
                this.msgRecover = this.currentLang.s['common']['error'];
                this.errorRecover = true;
                setTimeout(() => {this.msgRecover = "&nbsp;"; this.errorRecover = false;}, 3000);
            }
        } catch (err) {
            this.appService.showNotification(err, "error");           
            this.msgRecover = "&nbsp;"
        }
    }
}
