import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { Page } from 'src/app/model/orm/page.model';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Lang } from 'src/app/model/orm/lang.model';
import { Item } from 'src/app/model/orm/item.model';
import { ItemsSearchRepository } from 'src/app/services/repositories/itemssearch.repository';
import { Subscription } from 'rxjs';
import { FilterBySearchRepository } from 'src/app/services/repositories/filterbysearch.repository';
import { IFilter } from 'src/app/model/filter.interface';
import { ItemsService } from 'src/app/services/items.service';

@Component({
    selector: "search-page",
    templateUrl: "./search.page.html",
    styleUrls: ["../../../common.styles/items.page.scss"]
})
export class SearchPage implements OnInit, OnDestroy {
    public page: Page = null;
    public pageReady: boolean = true;
    public filterReady: boolean = false;
    public itemsReady: boolean = false;
    public filteredItems: Item[] = []; 
    private subscription: Subscription;
    public showInARow = 2;

    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private pagesRepository: PagesRepository,
        private itemsSearchRepository: ItemsSearchRepository,
        private filterBySearchRepository: FilterBySearchRepository,
        private itemsService: ItemsService,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}
    get search(): string {return this.appService.searchKeyword.value;}
    get items(): Item[] {return this.itemsSearchRepository.xl;}
    get filter(): IFilter {return this.filterBySearchRepository.filter;}    

    public ngOnInit(): void {
        this.appService.catmenumobileActive = false;	
        this.appService.collmenumobileActive = false;		
        // page
        this.page = this.pagesRepository.xl.find(x => x.slug === "search") || null;

        if (!this.page) {
            this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
        } else {
            this.pageReady = true;
            this.route.params.subscribe(p => {
                this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);
            });
        }  
        
        // search
        this.subscription = this.appService.searchKeyword.subscribe(async s => {
            try {
                this.itemsReady = false;
                this.filterReady = false;
                this.itemsSearchRepository.xl = [];
                this.itemsSearchRepository.filterName = s;
                this.itemsSearchRepository.filterLang = this.currentLang.id;
                await this.itemsSearchRepository.load();
                this.filteredItems = this.items;
                this.itemsReady = true;
                this.filterBySearchRepository.langId = this.currentLang.id;
                this.filterBySearchRepository.name = s;
                await this.filterBySearchRepository.load();
                this.filterReady = true;
            } catch (err) {
                this.appService.showNotification(err, "error");
            }            
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public applyFilters(): void {
        this.filteredItems = this.itemsService.applyFilters(this.items, this.filter);
    } 
    public onShowInARowChanged(count): void {
        this.showInARow = count;
    }
}