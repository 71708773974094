import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

import { INotification } from '../model/notification.interface';
import { Lang } from '../model/orm/lang.model';
import { Currency } from '../model/orm/currency.model';

@Injectable()
export class AppService {
    public url: string[] = [];
    public notification: INotification = {active: false, content: "", status: "info"};
    public notificationTimer: number = null;
    public mobmenuActive: boolean = false;
    public currentLang: BehaviorSubject<Lang | null> = new BehaviorSubject(null);
    public currentCurrency: BehaviorSubject<Currency | null> = new BehaviorSubject(null);
    public currentImageView: string = 'product';
    public headerAlwaysOpaque: boolean = false;
    public searchKeyword: BehaviorSubject<string> = new BehaviorSubject("");
    // additional menus
    public catmenudesktopActive: boolean = false;
    public catmenumobileActive: boolean = false;
    public collmenudesktopActive: boolean = false;
    public collmenumobileActive: boolean = false;
    public collmenudesktopActive1: boolean = false;
    public collmenumobileActive1: boolean = false;
    public cartpanelActive: boolean = false;
    public loginpanelActive: boolean = false;
    public registerpanelActive: boolean = false;
    public recoverpanelActive: boolean = false;
    public searchActive: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private router: Router,
        private titleService: Title,
        private metaService: Meta,
    ) {
        this.initURLRoutine();
    }

    get isBrowser(): boolean {return isPlatformBrowser(this.platformId);}
    get isServer(): boolean {return isPlatformServer(this.platformId);}

    private initURLRoutine(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {this.url = event.url.split("/");});
    }

    public showNotification (content: string, status: string = "info"): void {
        if (this.isBrowser) {
            if (this.notificationTimer) {
                clearTimeout (this.notificationTimer);
            }

            this.notification.content = content;
            this.notification.status = status;
            this.notification.active = true;
            this.notificationTimer = window.setTimeout (() => {
                this.notification.active = false;
                this.notificationTimer = null;
            }, 2000);
        }
    }

    public setMeta(name: string, content: string): void {
        this.metaService.removeTag (`name="${name}"`);

        if (content) {
            this.metaService.addTag ({name: name, content: content});
        }
    }

    public setTitle (title: string) {
        this.titleService.setTitle(this.decodeHTMLEntities(title));
    }

    public decodeHTMLEntities(text: string): string {
        let entities = [
            ['amp', '&'],
            ['apos', '\''],
            ['#x27', '\''],
            ['#x2F', '/'],
            ['#39', '\''],
            ['#47', '/'],
            ['lt', '<'],
            ['gt', '>'],
            ['nbsp', ' '],
            ['quot', '"']
        ];

        for (let i: number = 0, max: number = entities.length; i < max; ++i) {
            text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);
        }

        return text;
    }

    public encodeHTMLEntities(text: string): string {
        return String(text).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
    }

    public capitalize(s: string): string {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    public validateEmail (email: string): boolean {
        const re: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test (email.toLowerCase());
    }

    public getLangLink(langSlug: string): string {
        let link: string = `/${langSlug}`;
        let urlParts: string[] = this.router.url.split("/");

        for (let i: number = 2; i < urlParts.length; i++) {
            link += `/${urlParts[i]}`;
        }

        return link;
    }

    public setCurrency(currency: Currency): void {
        this.currentCurrency.next(currency);
        if(localStorage) localStorage.setItem("currency", currency.id.toString());
    }

    public setImageView(mode: string): void {
      this.currentImageView = mode;
      if(localStorage) localStorage.setItem("imageView", this.currentImageView);
    }

    public smoothScroll (from: number, to: number, duration: number, element: HTMLElement | Window): void {
		let change: number = to - from;
        let currentTime: number = 0;
		let increment: number = 10;
        let animateScroll = () => {
                currentTime += increment;
                let val: number = this.easeInOutQuad(currentTime, from, change, duration);
                element.scrollTo(0, val);

                if(currentTime < duration) {
                    setTimeout(animateScroll, increment);
                }
        	};

        animateScroll();
	}

	private easeInOutQuad (t:number, b:number, c:number, d:number): number {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    }

    public isMobile(): boolean {
        return this.isBrowser ? /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(window.navigator.userAgent.toLowerCase()) : false;
    }
}
