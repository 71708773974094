import { Model } from '../model';

export class User extends Model {
    public id: number;
    public name: string;
    public surname: string;
    public email: string;
    public phone: string;
    public address: string;
    public city: string;
    public province: string;
    public country_id: number;
}
