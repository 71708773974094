import { Component, AfterViewInit, ViewChild, ElementRef, HostListener, Input } from "@angular/core";

import { AppService } from 'src/app/services/app.service';
import { Item } from 'src/app/model/orm/item.model';

@Component({
    selector: "cp-recommended",
    templateUrl: "./cart-panel-recommended.component.html",
    styleUrls: ["./cart-panel-recommended.component.scss"]
})
export class CartPanelRecommendedComponent implements AfterViewInit {
    @Input() items: Item[];
    public w: number = 0;
    @ViewChild("container", {static: false}) containerRef: ElementRef;        
    private winWidth: number = 0;
    
    constructor(private appService: AppService) {}    

    public ngAfterViewInit(): void {
        if (this.appService.isBrowser) {
            setTimeout(() => {
                this.initLayout();
            }, 1);
        }
    }

    private initLayout(): void {
        this.w = (this.containerRef.nativeElement as HTMLElement).offsetWidth / 2;
        this.winWidth = window.innerWidth;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth !== this.winWidth) {
            this.initLayout();
        }        
    }
}
