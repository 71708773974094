export class FormHint {
    constructor(
        public email: string = "&nbsp;",
        public phone: string = "&nbsp;",
        public name: string = "&nbsp;",
        public surname: string = "&nbsp;",
        public address: string = "&nbsp;",
        public city: string = "&nbsp;",
        public province: string = "&nbsp;",
        public instagram: string = "&nbsp;",
    ) {}
}