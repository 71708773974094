import { Injectable } from "@angular/core";
import { StatisticsFacebook } from '../model/statistics_Facebook';

import { environment } from '../../environments/environment';

declare const fbq: any;

@Injectable()
export class StatisticsFacebookService {
    public viewContent(statisticsFacebook: StatisticsFacebook): void {
        let facebookData = {
            value: statisticsFacebook.value,
            currency: statisticsFacebook.currency,
            content_name: statisticsFacebook.content_name,
            content_type: statisticsFacebook.content_type,
            content_category: statisticsFacebook.content_category,
            content_ids: statisticsFacebook.content_ids,
        };
        this.sendDataToFacebook('ViewContent', facebookData);
    }
    public addToCart(statisticsFacebook: StatisticsFacebook): void {
        let facebookData = {
            value: statisticsFacebook.value,
            currency: statisticsFacebook.currency,
            content_name: statisticsFacebook.content_name,
            content_type: statisticsFacebook.content_type,
            content_category: statisticsFacebook.content_category,
            content_ids: statisticsFacebook.content_ids,
        };
        this.sendDataToFacebook('AddToCart', facebookData);
    }
    public initiateCheckout(statisticsFacebook: StatisticsFacebook): void {
        let facebookData = {
            value: statisticsFacebook.value,
            currency: statisticsFacebook.currency,
            content_name: statisticsFacebook.content_name,
            content_type: statisticsFacebook.content_type,
            content_category: statisticsFacebook.content_category,
            content_ids: statisticsFacebook.content_ids,
            num_items: statisticsFacebook.num_items
        };
        this.sendDataToFacebook('InitiateCheckout', facebookData);
    }
    public purchase(statisticsFacebook: StatisticsFacebook): void {
        let facebookData = {
            value: statisticsFacebook.value,
            currency: statisticsFacebook.currency,
            content_name: statisticsFacebook.content_name,
            content_type: statisticsFacebook.content_type,
            content_category: statisticsFacebook.content_category,
            content_ids: statisticsFacebook.content_ids,
            num_items: statisticsFacebook.num_items
        };
        this.sendDataToFacebook('Purchase', facebookData);
    }
    sendDataToFacebook(event: string, data: any): void {
        if (environment.production) {
            fbq('track', event, data);
        } else {
            console.log('fbq', 'track', event, data);
        }
    }
}
