<div class="loginpanel" [class.active]="active">
    <div class="lp-left" (click)="active = false;"></div>
    <div class="lp-right">
        <div class="lp-head1">            
            <div class="lp-close" (click)="active = false;"></div>
        </div>
        <div class="lp-head2 big">
            <div class="lp-head21">{{currentLang.s['user']['register-welcome1']}}</div>
            <div class="lp-head22">{{currentLang.s['user']['register-welcome2']}}</div>
        </div>
        <div class="lp-form">
            <form (submit)="register();">
                <div class="lpf-inputwrap"><input type="text" name="name" placeholder="{{currentLang.s['user']['name']}}" [(ngModel)]="name"></div>
                <div class="lpf-error" [innerHTML]="errorName"></div>
                <div class="lpf-inputwrap"><input type="text" name="surname" placeholder="{{currentLang.s['user']['surname']}}" [(ngModel)]="surname"></div>
                <div class="lpf-error" [innerHTML]="errorSurname"></div>                
                <div class="lpf-inputwrap"><input type="email" name="email" placeholder="{{currentLang.s['user']['email']}}" [(ngModel)]="email"></div>
                <div class="lpf-error" [innerHTML]="errorEmail"></div>
                <div class="lpf-inputwrap"><input type="password" name="password" placeholder="{{currentLang.s['user']['password']}}" [(ngModel)]="password"></div>
                <div class="lpf-error" [innerHTML]="errorPassword"></div>
                
                <div class="lpf-btnwrap"><button type="submit" [disabled]="formLoading">{{currentLang.s['user']['register']}}</button></div>
                <div class="lpf-msg" [innerHTML]="formMsg" [class.error]="formError"></div>
            </form>
        </div>
        <div class="lp-foot">
            <div class="lp-foot-title">{{currentLang.s['user']['have-acc']}}</div>
            <div class="lp-foot-btnwrap"><button type="button" (click)="active = false; loginpanelActive = true;">{{currentLang.s['user']['login']}}</button></div>
        </div>
    </div>
</div>