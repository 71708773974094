<ng-container *ngIf="orderReady && orderitemReady && authenticated">    
    <div class="compact padded">    
        <div class="page">
            <div class="page-left">
                <div class="simplemenu">
                    <ul>
                        <li *ngFor="let p of pagesAcc"><a [routerLink]="'/'+currentLang.slug+'/account/'+p.slug">{{p.name[currentLang.slug]}}</a></li>
                    </ul>
                </div>
                <div class="slidemenu">
                    <slide-menu [head]="currentLang.s['history']['history']" urlprefix="account" [pages]="pagesAcc"></slide-menu>
                </div>
            </div>
            <div class="page-right">
                <div id="breadcrumbs">
                    <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>            
                    <span><a routerLink="/{{currentLang.slug}}/account/history">{{currentLang.s['history']['history']}}</a></span>            
                    <span><a routerLink="/{{currentLang.slug}}/account/history/{{order.id}}">{{currentLang.s['history']['order']}} #{{order.id}}</a></span>
                </div>        
                <h1 [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></h1>
                <div class="userdata" *ngIf="!formSent">
                    <form (submit)="send();">
                        <div class="ud-content">                            
                            <div class="ud-item">
                                <label>{{currentLang.s['history']['return-name']}}</label>
                                <div class="udi-inputwrap"><input type="text" [value]="orderitem.name[currentLang.slug]" disabled></div>
                            </div>                            
                            <div class="ud-item">
                                <label>{{currentLang.s['history']['return-code']}}</label>
                                <div class="udi-inputwrap"><input type="text" [value]="orderitem.code" disabled></div>
                            </div>
                            <div class="ud-item">
                                <label>{{currentLang.s['history']['return-q']}}</label>
                                <div class="udi-inputwrap"><input type="number" name="q" [(ngModel)]="dto.q" min="1" [max]="orderitem.q"></div>
                                <div class="udi-error" [innerHTML]="errorQ"></div>
                            </div>  
                            <div class="ud-item">
                                <label>{{currentLang.s['history']['return-reason']}}</label>
                                <div class="udi-inputwrap"><input type="text" name="reason" [(ngModel)]="dto.reason"></div>
                                <div class="udi-error" [innerHTML]="errorReason"></div>
                            </div>
                            <div class="ud-item colspan2">
                                <label>{{currentLang.s['history']['return-bank']}}</label>
                                <div class="udi-textareawrap"><textarea name="bankdetails" [(ngModel)]="dto.bankdetails"></textarea></div>
                                <div class="udi-error" [innerHTML]="errorBankdetails"></div>
                            </div>  
                            <div class="ud-item colspan2">
                                <div class="udi-checkboxwrap small">
                                    <checkbox [(value)]="formAgree1" [label]="currentLang.s['history']['return-agree1']"></checkbox>                                    
                                </div>
                            </div>                          
                            <div class="ud-item colspan2">
                                <div class="udi-checkboxwrap small">
                                    <checkbox [(value)]="formAgree2" [label]="currentLang.s['history']['return-agree2']"></checkbox>                                         
                                </div>
                            </div>                          
                        </div>
                        <div class="ud-btnwrap"><button type="submit" [disabled]="formLoading || !formAgree1 || !formAgree2">{{currentLang.s['history']['return-send']}}</button></div>
                        <div class="ud-msg" [innerHTML]="formMsg" [class.error]="formError"></div>                                                
                    </form>                        
                </div> 
                <div *ngIf="formSent" class="content" [innerHTML]="page.content[currentLang.slug]"></div>
            </div>        
        </div>            
    </div>        
</ng-container>