import { Model } from '../model';
import { ISize } from './size.interface';

export class Review extends Model {
    public id: number;
    public name: string;
    public email?: string;
    public city: string;
    public title: string;
    public content: string;
    public item_id?: number;
    public size?: ISize;
    public size_id?: number;
    public rate: number;
    public sizematch: number;

    public init(item_id: number = null): Review {
        this.name = "";
        this.email = "";
        this.city = "";
        this.title = "";
        this.content = "";
        this.item_id = item_id;
        this.size_id = null;
        this.rate = 5;
        this.sizematch = null;
        
        return this;
    }
}
