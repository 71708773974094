<div id="about">
    <div id="about-content">
        <ng-container *ngIf="pageReady">
            <div id="about-right">
                <img [src]="page.img ? '/images/pages/'+page.img : '/images/noimage.jpg'">
            </div>
            <div id="about-left">
                <h1 [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></h1>
                <div class="content" [innerHTML]="page.content[currentLang.slug]"></div>
            </div>            
        </ng-container>    
    </div>    
</div>
