<div class="timer-container">
    <ng-container *ngIf="discount && timeLeft">
        <div class="timer" [style.color]="discount.color">
            <div class="days">
                <div class="timer-number" [innerHTML]="timeLeft.days"></div>
                <div class="timer-header">{{currentLang.s['home']['timer-days']}}</div>
            </div>
            <div class="separator">:</div>
            <div class="hours">
                <div class="timer-number" [innerHTML]="timeLeft.hours"></div>
                <div class="timer-header">{{currentLang.s['home']['timer-hours']}}</div>
            </div>
            <div class="separator">:</div>
            <div class="minutes">
                <div class="timer-number" [innerHTML]="timeLeft.minutes"></div>
                <div class="timer-header">{{currentLang.s['home']['timer-minutes']}}</div>
            </div>
            <div class="separator">:</div>
            <div class="seconds">
                <div class="timer-number" [innerHTML]="timeLeft.seconds"></div>
                <div class="timer-header">{{currentLang.s['home']['timer-seconds']}}</div>
            </div>
        </div>
    </ng-container>
</div>