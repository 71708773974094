<div class="loginpanel" [class.active]="active">
    <div class="lp-left" (click)="active = false;"></div>
    <div class="lp-right">
        <div class="lp-head1">            
            <div class="lp-close" (click)="active = false;"></div>
        </div>
        <div class="lp-head2">
            <div class="lp-head21">{{currentLang.s['user']['login-welcome1']}}</div>
            <div class="lp-head22">{{currentLang.s['user']['login-welcome2']}}</div>
        </div>
        <div class="lp-form">
            <form (submit)="login();">
                <div class="lpf-inputwrap margined"><input type="email" name="email" placeholder="{{currentLang.s['user']['email']}}" [(ngModel)]="email"></div>
                <div class="lpf-inputwrap margined"><input type="password" name="password" placeholder="{{currentLang.s['user']['password']}}" [(ngModel)]="password"></div>
                <div class="lpf-forgot" (click)="recoverpanelActive = true; active = false;">{{currentLang.s['user']['forgot']}}</div>
                <div class="lpf-btnwrap"><button type="submit" [disabled]="loading">{{currentLang.s['user']['signin']}}</button></div>
                <div class="lpf-msg" [innerHTML]="msg" [class.error]="error"></div>
            </form>
        </div>
        <div class="lp-foot">
            <div class="lp-foot-title">{{currentLang.s['user']['no-acc']}}</div>
            <div class="lp-foot-btnwrap"><button type="button" (click)="registerpanelActive = true; active = false;">{{currentLang.s['user']['create-acc']}}</button></div>
        </div>
    </div>
</div>