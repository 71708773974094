import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'the-welcome-popup',
  templateUrl: 'welcome-popup.component.html',
  styleUrls: ['welcome-popup.component.scss']
})

export class WelcomePopupComponent {
  @Input() content: string;
  @Input() active: boolean;
  @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public close() {
    this.activeChange.emit(false);
  }

  public onClick(event: MouseEvent | TouchEvent): void {
    const target: HTMLElement = event.target as HTMLElement;

    target.classList.contains('welcome-popup') ? this.close() : null;
  }
}
