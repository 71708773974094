import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServicesModule } from './services/services.module';
import { HomeModule } from './section.components/home/home.module';
import { CCModule } from './common.components/cc.module';
import { CacheModule } from './cache/cache.module';
import { ErrorsModule } from './section.components/errors/errors.module';
import { CartInfoWindowComponent } from 'src/app/common.components/cart-info-window/cart.info.window.component';
import { CartInfoMessageComponent } from 'src/app/common.components/cart-info-message/cart.info.message.component';

import { NgxScrollPositionRestorationModule } from 'ngx-scroll-position-restoration';
import { WelcomePopupComponent } from './common.components/welcome-popup/welcome-popup.component';
import { CookieService } from 'ngx-cookie-service';
import { PreHeaderComponent } from './common.components/pre-header/pre-header.component';



@NgModule({
	declarations: [
		AppComponent,
		CartInfoWindowComponent,
		CartInfoMessageComponent,
    WelcomePopupComponent,
    PreHeaderComponent
	],
	imports: [
    	BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		NgxScrollPositionRestorationModule.forRoot(),
		HttpClientModule,
		CacheModule,
		ServicesModule,
		HomeModule,
		ErrorsModule,
		CCModule,
	],
  	providers: [CookieService],
  	bootstrap: [AppComponent]
})
export class AppModule {}
