import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"]
})
export class CheckboxComponent {
    @Input() value: boolean;    
    @Input() label: string;    
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

    public onClick(): void {
        this.valueChange.emit(!this.value);
    }
}
