import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: "the-pre-header",
  templateUrl: "./pre-header.component.html",
  styleUrls: ["./pre-header.component.scss"],
})
export class PreHeaderComponent {
  @Input() data: any;
}
