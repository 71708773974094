import { Injectable } from '@angular/core';

import { IAllDataDTO } from 'src/app/model/dto/alldata.dto';
import { DataService } from '../data.service';
import { SimpleRepository } from './_simple.repository';
import { IAllDataReqDTO } from 'src/app/model/dto/alldata.get.req';

@Injectable()
export class AlldataRepository extends SimpleRepository<IAllDataDTO> {
    public alldata: IAllDataDTO = {};
    constructor(protected dataService: DataService) {
        super();
    }
    
    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (new Date().getTime() - this.loadedAt < this.ttl) {
                resolve();
            } else {
                const dto: IAllDataReqDTO = {
                    langSortBy: "pos", 
                    langSortDir: this.sortDir,
                    currencySortBy: "pos",
                    currencySortDir: this.sortDir,
                    countrySortBy: this.sortBy,
                    countrySortDir: this.sortDir,
                    pageSortBy: "pos",
                    pageSortDir: this.sortDir,
                    galSortBy: "name",
                    galSortDir: this.sortDir,
                    catSortBy: "pos",
                    catSortDir: this.sortDir,
                    collectionSortBy: "pos",
                    collectionSortDir: this.sortDir
                };
                this.dataService.allData(dto).subscribe(res => {                    
                    if (res.statusCode === 200) {
                        this.alldata  = res.data;                        
                        this.loadedAt = new Date().getTime();
                        resolve();
                    } else {                        
                        reject(res.statusCode+": "+res.error);
                    }
                }, err => {
                    reject(err.message);
                });
            }
        });
    }    
}
