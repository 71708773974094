import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { Page } from 'src/app/model/orm/page.model';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Lang } from 'src/app/model/orm/lang.model';
import { CartService } from 'src/app/services/cart.service';
import { Cart } from 'src/app/model/cart';
import { Item } from 'src/app/model/orm/item.model';
import { Currency } from 'src/app/model/orm/currency.model';
import { ICartRecord } from 'src/app/model/cartrecord.interface';

import { StatisticsGoogleService } from 'src/app/services/statistics-google.service';
import { StatisticsFacebookService } from 'src/app/services/statistics-facebook.service';
import { StatisticsConverterService } from 'src/app/services/statistics-converter.service';

import { StatisticsFacebook } from 'src/app/model/statistics_Facebook';
import { StatisticsGoogleCheckoutStep } from 'src/app/model/statistics_GoogleCheckoutStep';
import { StatisticsCheckoutStep } from 'src/app/model/statistics_CheckoutStep';
import { SettingsRepository } from "src/app/services/repositories/settings.repository";
import { DataService } from '../../../services/data.service';

@Component({
    selector: "cart-page",
    templateUrl: "./cart.page.html",
    styleUrls: ["./cart.page.scss"]
})
export class CartPage implements OnInit {
    public page: Page = null;
    public pageReady: boolean = true;
    public cart_enabled: boolean = true;
    constructor(
        private dataService: DataService,
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private pagesRepository: PagesRepository,
        private cartService: CartService,
        private statisticsGoogleService: StatisticsGoogleService,
        private statisticsFacebookService: StatisticsFacebookService,
        private statisticsConverterService: StatisticsConverterService,
        private settingsRepository: SettingsRepository
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}
    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
    get cart(): Cart {return this.cartService.cart;}
    get cartQ(): number {return this.cartService.q;}
    get cartS(): number {return this.cartService.sum;}
    get cartRelated(): Item[] {return this.cartService.related;}
    get bagLabel(): string {return `${this.currentLang.s['cart']['bag']} (${this.cartService.bagCost} ${this.currentCurrency.name})`;}

    public ngOnInit(): void {
        let cart_enabled = this.settingsRepository.xl.find(x => x.p === "cart_enabled");
        this.cart_enabled = cart_enabled.v === "1" ? true : false;
        this.appService.headerAlwaysOpaque = true;
        this.appService.catmenumobileActive = false;
        this.appService.collmenumobileActive = false;
        // page
        this.page = this.pagesRepository.xl.find(x => x.slug === "cart") || null;

        if (!this.page) {
            this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
        } else {
            this.pageReady = true;
            this.route.params.subscribe(p => {
                this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);
                this.sendCheckoutEvent();
            });
        }
    }
    sendCheckoutEvent() {
        let records = this.cart.records;
        if (records.length) {
            let step = StatisticsCheckoutStep.VIEW_CART;

            let googleData: StatisticsGoogleCheckoutStep
                = this.statisticsConverterService.toStatisticsGoogleCheckoutStep(
                    step,
                    this.currentCurrency.name,
                    this.cart.records,
                    this.currentLang.slug);

            this.statisticsGoogleService.checkoutStep(googleData);
        }
    }

    onCheckout(): void {
      const { records } = this.cart;
      const data = [];

      [...records].forEach(item => {
        data.push({
          item_id: item.item.id,
          q: item.q,
          id: item.id,
          size: {
            id: item.size.id
          }
        });
      });

      this.dataService.createReserve(data, this.cartService.cartUserId).subscribe(
        response => {
          const timeNow = new Date();

          this.cart.records.forEach(record => {
            record.errorBeforeCheckout = false;
            record.errorBeforeCheckoutQuantity = null;
          });

          this.cart.reserve = [...this.cart.records];
          this.cart.newTimer = timeNow.getTime() + 600000;

          this.router.navigate([`${this.currentLang.slug}/shop/checkout-continue`]);
        },
        error => {

          this.cart.records.forEach(record => {
            record.errorBeforeCheckout = false;
            record.errorBeforeCheckoutQuantity = null;
          });

          error.error.forEach(item => {
            this.cart.records.forEach(record => {
              if (record.id === item.id) {
                record.errorBeforeCheckout = true;
                record.errorBeforeCheckoutQuantity = item.max_quantity;
              }
            });
          });
        });
    }

    public getItemImg(item: Item): string {
        return item.pictures[0].img_s ? `url(/images/${item.pictures[0].img_s})` : `url(/images/noimage.jpg)`;
    }

    public getItemPrice(item: Item): number {
        return Math.ceil(item.price * this.currentCurrency.rate);
    }

    public decrease(r: ICartRecord): void {
        this.cartService.decreaseRecord(r);
    }

    public increase(r: ICartRecord): void {
        this.cartService.increaseRecord(r);
    }

    public remove(r: ICartRecord): void {
        this.cartService.removeRecord(r);
    }

    public saveCart(): void {
        this.cartService.save();
    }
}
