<div id="itemspage">
    <!-- head -->
    <div id="ip-head" [style.background-image]="'url(\'/images/collections/'+collection.img+'\')'" *ngIf="collectionReady">
        <div id="ip-head-content">
            <h1 [innerHTML]="collection.h1[currentLang.slug] || collection.name[currentLang.slug]"></h1>
        </div>        
    </div>
    <!-- filter -->
    <the-filter [ready]="filterReady" [filter]="filter" [showInARow]="showInARow" (showInARowChanged)="onShowInARowChanged($event)" (apply)="applyFilters();"></the-filter>
    <!-- items -->      
    <ng-container *ngIf="itemsReady">
        <div id="ip-items" [class.one-in-a-row]="showInARow==1">
            <div *ngFor="let item of filteredItems">
                <cat-item [item]="item"></cat-item>
            </div>        
        </div>  
    </ng-container>    
    <ng-container *ngIf="!itemsReady">
        <div class="compact smallpadded">
            <div class="loading">{{currentLang.s['common']['loading']}}</div>
        </div>
    </ng-container>  
    <ng-container *ngIf="collection.pictures.length">
        <div id="colldetails" class="compact">
            <h2 class="centered">{{collection.contentshort[currentLang.slug]}}</h2>
            <div id="cd-content" [innerHTML]="collection.content[currentLang.slug]"></div>
            <gal-collection [pictures]="collection.pictures"></gal-collection>
        </div>
    </ng-container>        
</div>
