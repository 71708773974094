import { Component, Optional, Inject, OnInit } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

import { AppService } from '../../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Page } from 'src/app/model/orm/page.model';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
	selector: 'notfound-page',
	templateUrl: './notfound.page.html',	
})
export class NotfoundPage implements OnInit {	
	public pageReady: boolean = false;
	public page: Page = null;
	
	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private pagesRepository: PagesRepository,
        @Optional() @Inject(RESPONSE) private response: any,
	) {}

	get currentLang(): Lang {return this.appService.currentLang.value;}

	public ngOnInit(): void {
		this.appService.headerAlwaysOpaque = true;
		this.appService.catmenumobileActive = false;
		this.appService.collmenumobileActive = false;		
		// page
		this.page = this.pagesRepository.xl.find(x => x.slug === "404") || null;
		
		if (!this.page) {
			this.page = new Page();
			this.page.name[this.currentLang.slug] = this.page.h1[this.currentLang.slug] = "404";
			this.page.content[this.currentLang.slug] = "not found";
		}
		
		this.pageReady = true;		
		this.route.params.subscribe(p => {
			this.appService.setTitle(this.page.name[this.currentLang.slug]);			
		});		
		// response 404
		this.appService.isServer ? this.response.statusCode = 404 : null;
	}
}
