import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { AuthService } from 'src/app/services/auth.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { User } from 'src/app/model/orm/user.model';
import { OrdersRepository } from 'src/app/services/repositories/orders.repository';
import { Order } from 'src/app/model/orm/order.model';

@Component({
	selector: 'history-page',
	templateUrl: './history.page.html',
	styleUrls: ['./history.page.scss']
})
export class HistoryPage implements OnInit {
    public page: Page = null;
    public pageReady: boolean = false;
    public ordersReady: boolean = false;
	
	constructor(
		private appService: AppService,		
		private route: ActivatedRoute,
		private router: Router,
        private pagesRepository: PagesRepository,	
        private ordersRepository: OrdersRepository,		
		private authService: AuthService,
	) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}	
    get authenticated(): boolean {return this.authService.authenticated;}
	get pagesAcc(): Page[] {return this.pagesRepository.pagesAcc;}
    get user(): User {return this.authService.user;}	
    get orders(): Order[] {return this.ordersRepository.xl;}
	
	public async ngOnInit(): Promise<void> {
        try {
            this.appService.headerAlwaysOpaque = true;		
            this.appService.catmenumobileActive = false;	
            this.appService.collmenumobileActive = false;
                
            // page
            this.page = this.pagesRepository.xl.find(x => x.slug === "history") || null;
    
            if (!this.page) {
                this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
            } else {
                this.pageReady = true;
                this.route.params.subscribe(p => {
                    this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);				
                });	
                
                if (this.authenticated) {
                    await this.ordersRepository.load(this.user.id);
                    this.ordersReady = true;
                }
            }		
        } catch (err) {
            this.appService.showNotification(err, "error");
        }        
    }
}