<ng-container *ngIf="pageReady && authenticated">    
    <div class="compact padded">    
        <div class="page">
            <div class="page-left">
                <div class="simplemenu">
                    <ul>
                        <li *ngFor="let p of pagesAcc"><a [routerLink]="'/'+currentLang.slug+'/account/'+p.slug">{{p.name[currentLang.slug]}}</a></li>
                    </ul>
                </div>
                <div class="slidemenu">
                    <slide-menu [head]="page.name[currentLang.slug]" urlprefix="account" [pages]="pagesAcc"></slide-menu>
                </div>
            </div>
            <div class="page-right">
                <div id="breadcrumbs">
                    <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>            
                </div>        
                <h1 [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></h1>                
                <div class="userdata">
                    <form (submit)="update();">
                        <div class="ud-content">
                            <div class="ud-item">
                                <label>{{currentLang.s['user']['email']}}</label>
                                <strong>{{user.email}}</strong>
                                <div class="udi-msg">&nbsp;</div>
                            </div>
                            <div class="ud-item">
                                <label>{{currentLang.s['user']['password']}}</label>
                                <strong class="link" (click)="recover();">{{currentLang.s['user']['recover']}}</strong>
                                <div class="udi-msg" [class.error]="errorRecover" [innerHTML]="msgRecover"></div>
                            </div>
                            <div class="ud-item">
                                <label>{{currentLang.s['user']['name']}}</label>
                                <div class="udi-inputwrap"><input type="text" name="name" [(ngModel)]="user.name"></div>
                                <div class="udi-error" [innerHTML]="errorName"></div>
                            </div>
                            <div class="ud-item">
                                <label>{{currentLang.s['user']['surname']}}</label>
                                <div class="udi-inputwrap"><input type="text" name="surname" [(ngModel)]="user.surname"></div>
                                <div class="udi-error" [innerHTML]="errorSurname"></div>
                            </div>
                        </div>
                        <div class="ud-btnwrap"><button type="submit" [disabled]="formLoading">{{currentLang.s['common']['save']}}</button></div>
                        <div class="ud-msg" [innerHTML]="formMsg" [class.error]="formError"></div>                                                
                    </form>                        
                </div>                           
            </div>        
        </div>            
    </div>        
</ng-container>
