import { Injectable } from "@angular/core";

import { SimpleRepository } from './_simple.repository';
import { Item } from 'src/app/model/orm/item.model';
import { DataService } from '../data.service';

@Injectable()
export class ItemsBySlugRepository extends SimpleRepository<Item> {
    public slug: string = "";

    constructor(protected dataService: DataService) {
        super();
    }

    public load(): Promise<Item> {
        return new Promise((resolve, reject) => {
          let short = '';
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          if (urlParams.has('short')) {
            short = '/'+urlParams.get('short');
          }
            this.dataService.itemBySlug(this.slug+short).subscribe(res => {
                if (res.statusCode === 200) {
                    let x: Item = new Item().build(res.data);
                    resolve(x);
                } else {
                    reject(res.statusCode+": "+res.error);
                }
            }, err => {
                reject(err.message);
            });
        });
    }
}
