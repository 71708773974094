<ng-container *ngIf="pageReady">
    <div class="compact padded">
        <!--<div id="breadcrumbs">
            <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>
            <span><a routerLink="/{{currentLang.slug}}/shop/cart">{{currentLang.s['common']['cart']}}</a></span>
        </div>-->
        <h1 [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></h1>

        <div class="chc-disclaimer">
          Звертаємо вашу увагу, що покидаючи цю сторінку, резерв буде автоматично скасований.
        </div>

        <ng-container *ngIf="cart.reserve.length">
            <div id="checkout-continue" >
                <div id="chc-left">
                    <form (submit)="finish();">
                        <div class="chc-inputwrap">
                            <label [class.active]="cart.email.length">{{currentLang.s['user']['email']}}</label>
                            <input required type="email" name="email" placeholder="{{currentLang.s['user']['email']}}" [(ngModel)]="cart.email" (focus)="hint.email = currentLang.s['user']['hint-email']" (blur)="hint.email = '&nbsp;';" #email="ngModel" [class.invalid]="email.invalid && (email.dirty || email.touched || submitted)">
                        </div>
                        <div class="chc-error" [innerHTML]="errorEmail"></div>
                        <div class="chc-hint" [innerHTML]="hint.email"></div>

                        <div class="chc-inputwrap" style="position: relative;">
                            <label [class.active]="cart.phone.length">{{currentLang.s['user']['phone']}}</label>
                            <input required type="text" name="phone" (input)="validatePhoneNo($event.target)" placeholder="+_ _ _ _ _ _ _ _ _ _ _" [(ngModel)]="cart.phone" (focus)="hint.phone = currentLang.s['user']['hint-phone']" (blur)="hint.phone = '&nbsp;';" #phone="ngModel" [class.invalid]="phone.invalid && (phone.dirty || phone.touched || submitted)" maxlength="19">
                            <span *ngIf="cart.country_id === 2 && cart.phone !== ''" class="phone-mask" (click)="focusPhone($event.target)" [innerHTML]="phoneMask"></span>
                        </div>
                        <div class="chc-error" [innerHTML]="errorPhone"></div>
                        <div class="chc-hint" [innerHTML]="hint.phone"></div>

                        <div class="chc-inputwrap">
                            <label [class.active]="cart.instagram?.length">{{currentLang.s['user']['instagram']}}</label>
                            <input type="text" name="instagram" placeholder="{{currentLang.s['user']['instagram']}}" [(ngModel)]="cart.instagram" (focus)="hint.instagram = currentLang.s['user']['hint-instagram']" (blur)="hint.instagram = '&nbsp;';" #instagram="ngModel">
                        </div>
                        <div class="chc-hint" [innerHTML]="hint.instagram"></div>

                        <div id="chc-callme">
                            <checkbox [(value)]="cart.dont_call_me" (valueChange)="dontCallMeChanged();" [label]="currentLang.s['checkout']['dont-call-me']"></checkbox>
                        </div>

                        <h2>{{currentLang.s['checkout']['shipping-data']}}</h2>

                        <div class="chc-inputwrap">
                            <label [class.active]="cart.name.length">{{currentLang.s['user']['name']}}</label>
                            <input required type="text" name="name" placeholder="{{currentLang.s['user']['name']}}" [(ngModel)]="cart.name" (focus)="hint.name = currentLang.s['user']['hint-name']" (blur)="hint.name = '&nbsp;';" #name="ngModel" [class.invalid]="name.invalid && (name.dirty || name.touched || submitted)">
                        </div>
                        <div class="chc-error" [innerHTML]="errorName"></div>
                        <div class="chc-hint" [innerHTML]="hint.name"></div>

                        <div class="chc-inputwrap">
                            <label [class.active]="cart.surname.length">{{currentLang.s['user']['surname']}}</label>
                            <input required type="text" name="surname" placeholder="{{currentLang.s['user']['surname']}}" [(ngModel)]="cart.surname" (focus)="hint.surname = currentLang.s['user']['hint-surname']" (blur)="hint.surname = '&nbsp;';" #surname="ngModel" [class.invalid]="surname.invalid && (surname.dirty || surname.touched || submitted)">
                        </div>
                        <div class="chc-error" [innerHTML]="errorSurname"></div>
                        <div class="chc-hint" [innerHTML]="hint.surname"></div>

                        <div class="chc-inputwrap">
                            <label [class.active]="cart.address.length">{{currentLang.s['user']['address']}}</label>
                            <input required type="text" name="address" placeholder="{{currentLang.s['user']['address']}}" [(ngModel)]="cart.address" (focus)="hint.address = currentLang.s['user']['hint-address']" (blur)="hint.address = '&nbsp;';" #address="ngModel" [class.invalid]="address.invalid && (address.dirty || address.touched || submitted)">
                        </div>
                        <div class="chc-error" [innerHTML]="errorAddress"></div>
                        <div class="chc-hint" [innerHTML]="hint.address"></div>

                        <div class="chc-inputwrap">
                            <label [class.active]="cart.city.length">{{currentLang.s['user']['city']}}</label>
                            <input required type="text" name="city" placeholder="{{currentLang.s['user']['city']}}" [(ngModel)]="cart.city" (focus)="hint.city = currentLang.s['user']['hint-city']" (blur)="hint.city = '&nbsp;';" #city="ngModel" [class.invalid]="city.invalid && (city.dirty || city.touched || submitted)">
                        </div>
                        <div class="chc-error" [innerHTML]="errorCity"></div>
                        <div class="chc-hint" [innerHTML]="hint.city"></div>

                        <div class="chc-inputwrap">
                            <label [class.active]="cart.province.length">{{currentLang.s['user']['province']}}</label>
                            <input type="text" name="province" placeholder="{{currentLang.s['user']['province']}}" [(ngModel)]="cart.province" (focus)="hint.province = currentLang.s['user']['hint-province']" (blur)="hint.province = '&nbsp;';" #province="ngModel">
                        </div>
                        <div class="chc-hint" [innerHTML]="hint.province"></div>

                        <div class="margined">
                            <div class="chc-selectwrap">
                                <label [class.active]="cart.country_id">{{currentLang.s['user']['country']}}</label>
                                <div class="chc-selectwrap2">
                                    <select required [(ngModel)]="cart.country_id" name="country_id" (ngModelChange)="onCountryChanged();" #country="ngModel" [class.invalid]="country.invalid && (country.dirty || country.touched || submitted)">
                                        <option [ngValue]="null"></option>
                                        <option *ngFor="let c of countries" [ngValue]="c.id">{{c.name[currentLang.slug]}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="chc-error" [innerHTML]="errorCountry"></div>
                        </div>

                        <h2>{{currentLang.s['checkout']['payment-method']}}</h2>
                        <div class="chc-promo" *ngIf="!cart.promocode">
                            <div class="chc-promo-left">
                                <input type="text" name="promocode" [(ngModel)]="promocode" placeholder="{{currentLang.s['checkout']['promo-code']}} {{currentLang.s['checkout']['not-required']}}" (keydown)="onPromocodeKeyDown($event);">
                                <div class="chc-error" [innerHTML]="errorPromocode"></div>
                            </div>
                            <div class="chc-promo-right"><button type="button" (click)="applyPromocode();">{{currentLang.s['checkout']['promo-apply']}}</button></div>
                        </div>
                        <div class="chc-promo-applied" *ngIf="cart.promocode">
                            <div><strong>{{currentLang.s['checkout']['promo-code']}}:</strong> {{cart.promocode.code}}</div>
                            <div>(<span class="lowercase">{{currentLang.s['checkout']['discount']}}</span> - {{discount}} {{currentCurrency.name}})</div>
                        </div>

                        <div class="chc-radios">
                            <div class="chc-radiowrap">
                                <!--
                                <input type="radio" name="payment-online" id="payment-online" [value]="1" [(ngModel)]="cart.paymentmethod_id">
                                <label for="payment-online">{{currentLang.s['checkout']['payment-online']}}</label>
                                -->
                                <radio [value]="cart.paymentmethod_id === 1" [label]="currentLang.s['checkout']['payment-online']" (click)="cart.paymentmethod_id = 1;"></radio>
                            </div>
<!--                            <div class="chc-radiowrap" *ngIf="currentCountry && currentCountry.home">-->
                            <div class="chc-radiowrap">
                                <!--
                                <input type="radio" name="payment-post" id="payment-post" [value]="2" [(ngModel)]="cart.paymentmethod_id">
                                <label for="payment-post">{{currentLang.s['checkout']['payment-post']}} *</label>
                                -->
<!--                                <radio [value]="cart.paymentmethod_id === 2" [label]="currentLang.s['checkout']['payment-post'] + '&nbsp;*'" (click)="cart.paymentmethod_id = 2;"></radio>-->
                              <ng-container *ngIf="!saleSetting">
                                <radio [value]="cart.paymentmethod_id === 2" label="Оплата при отриманні (лише для України, з передплатою 300 грн) *" (click)="cart.paymentmethod_id = 2;"></radio>
                              </ng-container>

                              <ng-container *ngIf="saleSetting">
                                <ng-container *ngIf="saleSetting.day_type === 'sale'">
                                  <radio [value]="cart.paymentmethod_id === 2" [label]="'Оплата при отриманні (лише для України, з передплатою в ' + saleSetting.prepay_cost + '%, але не менше ніж ' + saleSetting.min_prepay_sum + ' грн) *'" (click)="cart.paymentmethod_id = 2;"></radio>
                                </ng-container>

                                <ng-container *ngIf="saleSetting.day_type === 'regular'">
                                  <radio [value]="cart.paymentmethod_id === 2" [label]="'Оплата при отриманні (лише для України, з передплатою ' + saleSetting.prepay_cost + ' грн) *'" (click)="cart.paymentmethod_id = 2;"></radio>
                                </ng-container>
                              </ng-container>
                            </div>
                        </div>

                        <div class="chc-note">* {{currentLang.s['checkout']['cash-note']}}</div>

                        <div class="chc-btnwrap hide-mobile">
                            <button class="" [disabled]="formSending">{{currentLang.s['checkout']['finish']}}</button>
                        </div>
                        <div class="chc-msg" [innerHTML]="formMsg" [class.error]="formError"></div>
                    </form>
                </div>
                <div id="chc-right" #cartblk>
                    <div class="chc-btnwrap chc-btnwrap-flex">
                      <button (click)="isDeleteReservePopupOpen = true">Редагувати замовлення</button>

                      <div class="chc-timeleft" *ngIf="cart.newTimer">
<!--                        <span>{{currentLang.s['cart']['reserve']}} </span>-->
                        <span>Резерв на : </span>
                        <span>{{ '0' + newTimer.minutes }}</span>
                        <span>:</span>
                        <span>{{ newTimer.seconds >= 10 ? newTimer.seconds : '0' + newTimer.seconds }}</span>
                      </div>
                    </div>
                    <div id="chc-right-content">
<!--                        <div class="chc-timeleft" *ngIf="cart.timer.secondsLeft > 0">-->
<!--                            <span>{{currentLang.s['cart']['reserve']}} </span>-->
<!--                            <span>{{ timeFilter(cart.timer.minutes) }}</span>-->
<!--                            <span>:</span>-->
<!--                            <span>{{ timeFilter(cart.timer.seconds) }}</span>-->
<!--                        </div>-->

                        <!--<div class="chc-timeleft" *ngIf="cart.newTimer">
                          <span>{{currentLang.s['cart']['reserve']}} </span>
                          <span>{{ '0' + newTimer.minutes }}</span>
                          <span>:</span>
                          <span>{{ newTimer.seconds >= 10 ? newTimer.seconds : '0' + newTimer.seconds }}</span>
                        </div>-->
                        <div id="chc-items">
                            <div class="chc-item" *ngFor="let r of cart.reserve">
                                <div class="chci-img"><div class="chci-imgwrap" [style.background-image]="getItemImg(r.item)"></div></div>
                                <div class="chci-details">
                                    <div class="chci-nameprice">
                                        <div class="chci-name">{{r.item.name[currentLang.slug]}}</div>
                                        <div class="chci-price">{{getItemPrice(r.item)}} {{currentCurrency.name}}</div>
                                    </div>
                                    <div class="chci-sizecolor">
                                        {{r.size.name}} <span *ngIf="r.item.color">/ {{r.item.color.name[currentLang.slug]}}</span>
                                    </div>
                                    <div class="chci-sizecolor">{{ r.q }} шт.</div>
                                    <!--<div class="chci-q">
                                        <div class="chciq-incdec">
                                            <div class="chciq-adjust" (click)="decrease(r);">&ndash;</div>
                                            <div>{{r.q}}</div>
                                            <div class="chciq-adjust" (click)="increase(r);">+</div>
                                        </div>
                                        <div class="chciq-remove" (click)="remove(r);">{{currentLang.s['cart']['remove']}}</div>
                                        <div class="chciq-max" *ngIf="r.q > r.size.q">{{currentLang.s['checkout']['maxq']}}: {{r.size.q}}</div>
                                    </div>-->
                                </div>
                            </div>
                        </div>
                        <!--
                        <div id="chc-bag">
                            <checkbox [(value)]="cart.bag" (valueChange)="saveCart();" [label]="bagLabel"></checkbox>
                        </div>
                        -->
                        <div id="chc-amounts">
                            <div class="chc-amount">
                                <div>{{currentLang.s['checkout']['subtotal']}}</div>
                                <div>{{subTotal}} {{currentCurrency.name}}</div>
                            </div>
                            <div class="chc-amount">
                                <div>{{currentLang.s['checkout']['shipping-cost']}}</div>
                                <div>
                                    <ng-container *ngIf="shippingCost">
                                        {{shippingCost}} {{currentCurrency.name}}
                                    </ng-container>
                                    <ng-container *ngIf="!shippingCost">
                                        <span *ngIf="freeShippingLimit - subTotal > 0">{{currentLang.s['checkout']['postrate']}} *</span>
                                        <span *ngIf="freeShippingLimit - subTotal <= 0">{{currentLang.s['checkout']['free']}}</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="chc-amount" *ngIf="discount">
                                <div>{{currentLang.s['checkout']['discount']}}</div>
                                <div>&ndash;{{discount}} {{currentCurrency.name}}</div>
                            </div>
                        </div>
                        <div id="chc-total">
                            <div>{{currentLang.s['checkout']['total']}}</div>
                            <div>{{total}} {{currentCurrency.name}}</div>
                        </div>
                    </div>
                    <div id="chc-note" *ngIf="!shippingCost && freeShippingLimit - subTotal > 0">
                        <div id="chc-notecontent">
                            {{currentLang.s['checkout']['free1']}} {{freeShippingLimit}} {{currentCurrency.name}}. {{currentLang.s['checkout']['free2']}} {{freeShippingLimit - subTotal}} {{currentCurrency.name}}.
                        </div>
                    </div>
                </div>

                <div class="chc-btnwrap-custom">
                  <button (click)="finish()" [disabled]="formSending">{{currentLang.s['checkout']['finish']}}</button>
                </div>
            </div>

            <form method="post" action="https://www.liqpay.ua/api/3/checkout" accept-charset="utf-8" #liqpayForm>
                <input type="hidden" name="data" [(ngModel)]="liqpayFormData">
                <input type="hidden" name="signature" [(ngModel)]="liqpayFormSignature">
            </form>
        </ng-container>
        <ng-container *ngIf="!cart.reserve.length">{{currentLang.s['cart']['empty']}}</ng-container>
        <the-delete-reserve-popup [(active)]="isDeleteReservePopupOpen" (deleteReserve)="onDeleteReserve()"></the-delete-reserve-popup>
    </div>
</ng-container>
