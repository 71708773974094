<ng-container *ngIf="pageReady">
    <div class="pageimg" *ngIf="page.img" [style.background-image]="'url(\'/images/pages/'+page.img+'\')'"></div>    
    <div class="compact" [class.padded]="!page.img">    
        <div class="page">
            <div *ngIf="page.section === 2" class="page-left">
                <div class="simplemenu">
                    <ul>
                        <li *ngFor="let p of pagesHelp"><a [routerLink]="'/'+currentLang.slug+'/'+p.slug">{{p.name[currentLang.slug]}}</a></li>
                    </ul>
                </div>
                <div class="slidemenu">
                    <slide-menu [head]="page.name[currentLang.slug]" [pages]="pagesHelp"></slide-menu>
                </div>
            </div>
            <div class="page-right">
                <div id="breadcrumbs">
                    <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>            
                </div>        
                <h1 [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></h1>
                <div class="content" [innerHTML]="page.content[currentLang.slug]"></div>
                <accordion-section *ngFor="let qa of page.qas" [head]="qa.question[currentLang.slug]" [content]="qa.answer[currentLang.slug]"></accordion-section>
                <!-- контакты - модульная страница, поэтому она определяется урлом однозначно -->
                <ng-container *ngIf="page.slug === 'contacts'">
                    <div id="contacts">
                        <div id="contacts-content">
                            <a [href]="currentLang.s['contacts']['viber'] | nosanitize: 'url'"><div class="contact"><span class="c-viber">Viber</span></div></a>
                            <a [href]="currentLang.s['contacts']['telegram'] | nosanitize: 'url'"><div class="contact"><span class="c-telegram">Telegram</span></div></a>                            
                            <a [href]="currentLang.s['contacts']['instagram'] | nosanitize: 'url'" target="_blank"><div class="contact"><span class="c-instagram">Instagram</span></div></a>
                            <a href="mailto:{{currentLang.s['contacts']['email']}}"><div class="contact"><span class="c-email">E-mail</span></div></a>
                        </div>                
                    </div>
                </ng-container>

                <ng-container *ngIf="page.slug === 'oplata'">
                    <div class="visa-n-mastercard">
                        <img class="visa" src="/images/payments/visa.jpg" alt="Visa" height="50">
                        <img class="mastercard" src="/images/payments/mc_vrt_opt_pos_73_3x.png" alt="Mastercard" width="73" srcset="/images/payments/mc_vrt_opt_pos_73_1x.png, /images/payments/mc_vrt_opt_pos_73_2x.png.png, /images/payments/mc_vrt_opt_pos_73_3x.png.png">
                    </div>
                </ng-container>
            </div>        
        </div>            
    </div>        
</ng-container>
