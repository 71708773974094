import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { IRegisterDTO } from 'src/app/model/dto/register.dto';

@Component({
    selector: "register-panel",
    templateUrl: "./registerpanel.component.html",
    styleUrls: ["../../common.styles/account.panel.scss"]
})
export class RegisterPanelComponent {
    public name: string = "";
    public surname: string = "";
    public email: string = "";    
    public password: string = "";
    public errorName: string = "&nbsp;";
    public errorSurname: string = "&nbsp";
    public errorEmail: string = "&nbsp;";
    public errorPassword: string = "&nbsp;";

    public formLoading: boolean = false;
    public formMsg: string = "&nbsp;"
    public formError: boolean = false;    
    
    constructor(
        private appService: AppService,
        private authService: AuthService,
    ) {}

    get active(): boolean {return this.appService.registerpanelActive;}
    set active(v: boolean) {this.appService.registerpanelActive = v;}
    get currentLang(): Lang {return this.appService.currentLang.value;}
    set loginpanelActive(v: boolean) {this.appService.loginpanelActive = v;}

    public async register(): Promise<void> {
        if (!this.validate()) {
            return;
        }

        try {
            this.formLoading = true;
            let dto: IRegisterDTO = {name: this.name, surname: this.surname, email: this.email, password: this.password, lang_id: this.currentLang.id};
            const status: number = await this.authService.register(dto);
            this.formLoading = false;

            if (status === 200) {  
                this.formMsg = this.currentLang.s['user']['register-ok'];  
                this.authService.registerNotification(dto);
                setTimeout(() => {this.formMsg = "&nbsp;"; this.active = false;}, 1000);                
            } else if (status === 409) {
                this.formMsg = this.currentLang.s['user']['email-used'];
                this.formError = true;
                setTimeout(() => {this.formMsg = "&nbsp;"; this.formError = false;}, 3000);
            } else {
                this.formMsg = this.currentLang.s['common']['error'];
                this.formError = true;
                setTimeout(() => {this.formMsg = "&nbsp;"; this.formError = false;}, 3000);
            }
        } catch (err) {
            this.appService.showNotification(err, "error");
            this.formLoading = false;
        }
    }

    private validate(): boolean {
        this.name = this.name.trim();
        this.surname = this.surname.trim();  
        this.email = this.email.trim();
        this.password = this.password.trim();        
        let error: boolean = false;
        
        if (!this.name.length) {
            error = true;
            this.errorName = this.currentLang.s['common']['error-required'];
        } else {
            this.errorName = "&nbsp;";
        }

        if (!this.surname.length) {
            error = true;
            this.errorSurname = this.currentLang.s['common']['error-required'];
        } else {
            this.errorSurname = "&nbsp;";
        }

        if (!this.email.length) {
            error = true;
            this.errorEmail = this.currentLang.s['common']['error-required'];
        } else if (!this.appService.validateEmail(this.email)) {
            error = true;
            this.errorEmail = this.currentLang.s['common']['error-emailincorrect'];
        } else {
            this.errorEmail = "&nbsp;";
        }

        if (!this.password.length) {
            error = true;
            this.errorPassword = this.currentLang.s['common']['error-required'];
        } else {
            this.errorPassword = "&nbsp;";
        }

        return !error;
    }
}
