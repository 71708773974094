import { Component, OnInit } from "@angular/core";

import { Cat } from 'src/app/model/orm/cat.model';
import { Lang } from 'src/app/model/orm/lang.model';
import { AppService } from 'src/app/services/app.service';
import { CatsRepository } from 'src/app/services/repositories/cats.repository';

@Component({
    selector: "cats-slider",
    templateUrl: "./catsslider.component.html",
    styleUrls: ["./catsslider.component.scss"]
})
export class CatsSliderComponent implements OnInit {
    public cats: Cat[] = [];

    constructor(
        private appService: AppService,
        private catsRepository: CatsRepository,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}

    public ngOnInit(): void {        
        this.cats = this.catsRepository.xl.filter(c => c.inhome);
    }
}
