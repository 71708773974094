import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { IFilterActivity } from 'src/app/model/filteractivity.interface';
import { IFilter } from 'src/app/model/filter.interface';
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
    selector: "the-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit, OnChanges {    
    @Input() filter: IFilter;
    @Input() ready: boolean;
    @Output() apply: EventEmitter<void> = new EventEmitter();
    @Output() showInARowChanged: EventEmitter<number> = new EventEmitter();
    @Input() showInARow = 2;
    public filterActive: IFilterActivity;


    constructor(private appService: AppService) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}

    public ngOnInit(): void {
        this.resetActivity();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.ready) {
            this.resetActivity();
        }
    }

    private resetActivity(): void {
        this.filterActive = {size: false, color: false, collection: false, features: false, price: false, sortBy: false};
    }

    public applyFilters(): void {
        this.apply.emit();
    }

    public toggleFilter(filterName): void {
        if (this.filterActive[filterName]) {
            this.filterActive[filterName] = false;    
        } else {
            this.filterActive[filterName] = true;
        
            for (let filter in this.filterActive) {
                if (filter != filterName) {
                    this.filterActive[filter] = false;
                }
            }
        }       
    }
    public setShowInARowCount(count) {
        this.showInARow = count;
        this.showInARowChanged.emit(this.showInARow);
    }

    public setImageView(mode: string) {
      this.appService.setImageView(mode);
    }

    public getImageView(): string {
      return this.appService.currentImageView;
    }
}
