import { Injectable } from '@angular/core';

import { DataService } from '../data.service';
import { SimpleRepository } from './_simple.repository';
import { Setting } from 'src/app/model/orm/setting.model';

@Injectable()
export class SettingsRepository extends SimpleRepository<Setting> {
    constructor(protected dataService: DataService) {
        super();        
    }

    get bagCost(): number {
        let setting: Setting = this.xl.find(x => x.p === "bag-cost") || null;
        return setting ? parseFloat(setting.v) : 0;
    }
    get siteActive(): boolean {
        let setting: Setting = this.xl.find(x => x.p === "active") || null;
        return setting ? setting.v === "1" : false;
    }
    
    public load(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (new Date().getTime() - this.loadedAt < this.ttl) {
                resolve();
            } else {                
                this.dataService.settingsAll().subscribe(res => {                                                                                
                    if (res.statusCode === 200) {
                        this.build(res.data);
                        this.loadedAt = new Date().getTime();
                        resolve();
                    } else {                        
                        reject(res.statusCode+": "+res.error);
                    }
                }, err => {
                    reject(err.message);
                });
            }            
        });
    }

    public build(settings: Array<Setting> = []): void{
        this.xl = settings.length ? settings.map(d => new Setting().build(d)) : [];
    }
}
