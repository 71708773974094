import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener, OnDestroy, Input, ViewEncapsulation } from "@angular/core";

import { AppService } from 'src/app/services/app.service';
import { IGalitem } from 'src/app/model/orm/galitem.interface';
import { GalsRepository } from 'src/app/services/repositories/gals.repository';
import { Gal } from 'src/app/model/orm/gal.model';
import { Lang } from 'src/app/model/orm/lang.model';
import { Discount } from 'src/app/model/orm/discount.model';

@Component({
    selector: "gal-simple",
    templateUrl: "./galsimple.component.html",
    styleUrls: ["./galsimple.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class GalSimpleComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() discount: Discount = null;
    public timeLeft: {days: string, hours: string, minutes: string, seconds: string};
    private n: number = 0;
    public w: number = 0;    
    @ViewChild("galwrap", {static: false}) galwrapRef: ElementRef;
    @ViewChild("galcontainer", {static: false}) galcontainerRef: ElementRef;
    private galwrap: HTMLElement = null;
    private galcontainer: HTMLElement = null;
    public items: IGalitem[] = [];
    public xl: any[] = [];
    public ready: boolean = false;
    public visible: boolean = false;
    private winWidth: number = 0;
    // movement
    public step: number = 0;
    public left: number = 0;
    public active: boolean = false;   
    public moving: boolean = false; 
    private startX: number = 0;
    private startY: number = 0;
    private startXOffsetted: number = 0;
    private prevX: number = 0;
    private interval: number = null;

    constructor (
        private appService: AppService,
        private galsRepository: GalsRepository,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}
    get isBrowser(): boolean {return this.appService.isBrowser;}
    get isServer(): boolean {return this.appService.isServer;}

    public ngOnInit(): void {
        let gal: Gal = this.galsRepository.xl.find(x => x.name === "home");

        if (this.discount && this.discount.id) {
            this.timeLeft = this.discount.getTimeLeft();
            if(this.appService.isBrowser){
                setInterval(() => {
                    this.timeLeft = this.discount.getTimeLeft()
                }, 1000);
            }
        }
        if (gal) {
            this.items = gal.items;
            this.items.forEach((item, index) => item['i'] = index)
            this.items.forEach(item => this.xl.push(item));
            this.xl.unshift(this.items[this.items.length-1]);
            this.xl.push(this.items[0]);
            this.n = this.items.length;
            this.ready = true;
    
            if (this.appService.isBrowser) {
                this.interval = window.setInterval(() => this.moveNext(), 5000);
            }
        } else {
            this.appService.showNotification("no home gal found", "error");
        }

        this.onDragMove = this.onDragMove.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);        
    }

    public ngOnDestroy():void {
        if (this.interval) {
            window.clearInterval(this.interval);
        }
    }

    public ngAfterViewInit(): void {
        if (this.isBrowser) {
            setTimeout(() => {
                this.galwrap = this.galwrapRef.nativeElement;
                this.galcontainer = this.galcontainerRef.nativeElement;
                this.initLayout();
                this.visible = true;
            }, 1);
        }        
    }

    private initLayout() {
        this.winWidth = window.innerWidth;
        this.w = this.galwrap.offsetWidth;
        this.galcontainer.style.transition = "none";
        this.left = this.w;
        setTimeout(() => {this.galcontainer.style.transition = "left 0.3s ease 0s";}, 100);  
        this.step = 0;
        this.moving = false;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth != this.winWidth) {
            this.initLayout();
        }        
    }

    public moveTo(i: number): void {
        if (!this.moving && this.step !== i) {
            this.moving = true;
            this.step = i;
            this.left = this.w * (this.step+1);
        }
    }

    private moveNext() {
        if (!this.moving) {
            this.moving = true;
            this.step < this.n - 1 ? this.step++ : this.step = 0;
            this.left = this.w * (this.step+1);
        }
    }

    public moveLeft() {
        if (!this.moving) {
            this.moving = true;
            this.step--;
            this.left = this.w * (this.step+1);
        }
    }

    public moveRight() {
        if (!this.moving) {
            this.moving = true;
            this.step++;
            this.left = this.w * (this.step+1);
        }
    }

    public onDragStart (event: TouchEvent | MouseEvent): void {           
        if (!this.moving) {            
            this.startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
            this.startY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
            this.startXOffsetted = this.startX - this.galcontainer.offsetLeft;	            
            
            if (event instanceof MouseEvent) { // just start moving
                this.galcontainer.style.transition = "none";
                this.active = true;
                window.addEventListener("mousemove", this.onDragMove, {passive: false});
                window.addEventListener("mouseup", this.onDragEnd);              
            } else { // detect if moving is more by X than by Y
                window.addEventListener("touchmove", this.onTouchMove);                
            }
        }    
    }

    public onTouchMove(event: TouchEvent): void {
        let deltaX = Math.abs(this.startX - event.touches[0].clientX);
        let deltaY = Math.abs(this.startY - event.touches[0].clientY);
        window.removeEventListener("touchmove", this.onTouchMove);

        if (deltaX > deltaY && !this.moving) {            
            this.galcontainer.style.transition = "none";
            this.active = true;
            window.addEventListener("touchmove", this.onDragMove, {passive: false});
            window.addEventListener("touchend", this.onDragEnd);  
        }
    } 

    public onDragEnd (): void {
        window.removeEventListener("mousemove", this.onDragMove);
        window.removeEventListener("touchmove", this.onDragMove);
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("touchend", this.onDragEnd);
        this.galcontainer.style.transition = "left 0.3s ease 0s";
        this.active = false;

        // доводка   
        if (this.moving) {
            if (this.prevX < this.startX - 30) {
                this.step++;
            } else if (this.prevX > this.startX + 30) {
                this.step--;
            }
            
            this.left = this.w * (this.step+1);            
        }        
    }    

    public onDragMove (event: TouchEvent | MouseEvent): void {                
        event.cancelable ? event.preventDefault() : null;
        const x: number = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        let nextX: number = this.startXOffsetted - x;            
                
        if (nextX > 0 && nextX < (this.xl.length - 1) * this.w) {
            this.moving = true;
            this.left = nextX;            
        }            
                
        this.prevX = x;                               
    }

    public adjustInfiniteMotion() {        
        this.galcontainer.style.transition = "none";            

        if (this.step === -1) {                
            this.step = this.n - 1;
            this.left = (this.step+1) * this.w;                
        }

        if (this.step === this.n) {
            this.step = 0;
            this.left = (this.step+1) * this.w;  
        }

        setTimeout(() => {
            this.galcontainer.style.transition = "left 0.3s ease 0s"; 
            this.moving = false           
        }, 100);            
    }
}