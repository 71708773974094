import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { StaticPage } from './static.page';
import { CCModule } from 'src/app/common.components/cc.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

let routes = RouterModule.forChild ([        
    {path:"", component: StaticPage},        
]);

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        routes,
        CCModule,
        PipesModule,
    ],
    declarations: [
        StaticPage,        
    ],
    exports: [
        StaticPage,        
    ],
    providers: []
})
export class StaticModule {}
