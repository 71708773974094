import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Lang } from 'src/app/model/orm/lang.model';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { Currency } from 'src/app/model/orm/currency.model';
import { Cart } from 'src/app/model/cart';

import { StatisticsGoogleService } from 'src/app/services/statistics-google.service';
import { StatisticsConverterService } from 'src/app/services/statistics-converter.service';

import { StatisticsGoogleCheckoutStep } from 'src/app/model/statistics_GoogleCheckoutStep';
import { StatisticsCheckoutStep } from 'src/app/model/statistics_CheckoutStep';

@Component({
	selector: 'checkoutstart-page',
	templateUrl: './checkoutstart.page.html',
	styleUrls: ['./checkoutstart.page.scss']
})
export class CheckoutStartPage implements OnInit {
    public page: Page = null;
    public pageReady: boolean = false;
	public email: string = "";
    public password: string = "";
    public loading: boolean = false;
    public msg: string = "&nbsp;"
    public error: boolean = false;

	constructor(
		private appService: AppService,		
		private route: ActivatedRoute,
		private router: Router,
        private pagesRepository: PagesRepository,
        private authService: AuthService,
        private cartService: CartService,
        private statisticsGoogleService: StatisticsGoogleService,
        private statisticsConverterService: StatisticsConverterService,
	) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}	
    set recoverpanelActive(v: boolean) {this.appService.recoverpanelActive = v;}
	
    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
    get cart(): Cart {return this.cartService.cart;}

	public ngOnInit(): void {
        if (this.authService.authenticated) {
            this.router.navigateByUrl(`/${this.currentLang.slug}/shop/checkout-continue`);
        } else {
            this.appService.headerAlwaysOpaque = true;		
            this.appService.catmenumobileActive = false;	
            this.appService.collmenumobileActive = false;	
            
            // page
            this.page = this.pagesRepository.xl.find(x => x.slug === "checkout-start") || null;
    
            if (!this.page) {
                this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
            } else {
                this.pageReady = true;
                this.route.params.subscribe(p => {
                    this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]); 
                    this.sendCheckoutEvent();                   
                });					
            }		
        }        
    }

    sendCheckoutEvent() {
        let step = StatisticsCheckoutStep.CHECKOUT_START;

        let googleData: StatisticsGoogleCheckoutStep
            = this.statisticsConverterService.toStatisticsGoogleCheckoutStep(
                step,
                this.currentCurrency.name, 
                this.cart.records,
                this.currentLang.slug);

        this.statisticsGoogleService.checkoutStep(googleData);
    }
    
    public async login(): Promise<void> {
        if (!this.validate()) {
            return;
        }

        try {
            this.loading = true;
            const status: number = await this.authService.login(this.email, this.password);
            this.loading = false;

            if (status === 200) {  
                this.msg = this.currentLang.s['user']['login-ok'];              
                setTimeout(() => {
                    this.msg = "&nbsp;";
                    this.router.navigateByUrl(`/${this.currentLang.slug}/shop/checkout-continue`);
                }, 1000);                
            } else {
                this.msg = this.currentLang.s['user']['login-failed'];
                this.error = true;
                setTimeout(() => {
                    this.msg = "&nbsp;";
                    this.error = false;
                }, 3000);
            }
        } catch (err) {
            this.appService.showNotification(err, "error");
            this.loading = false;
        }
    }

    private validate(): boolean {
        this.email = this.email.trim();
        this.password = this.password.trim();        
        
        if (!this.email.length || !this.password.length) {
            return false;
        }

        return true;
    }
}