<gal-simple *ngIf="discountReady" [discount]="discount"></gal-simple>
<cats-slider></cats-slider>
<div class="compact">
    <h2 class="small">{{currentLang.s['home']['bestsellers']}}</h2>
    <gal-items [items]="items" *ngIf="itemsReady"></gal-items>
    <div class="home-splash" *ngIf="splash" [style.background-image]="'url(\'/images/gals/'+splash.img+'\')'">
        <a target="_blank" href="{{splash.link[currentLang.slug]}}">
            <div class="hs-head">{{splash.header[currentLang.slug]}}</div>
            <div class="hs-text">{{splash.title[currentLang.slug]}}</div>
        </a>
    </div>
</div>
