import { Component, Input, AfterViewInit, ViewChild, ElementRef, OnInit, Output, EventEmitter } from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { Currency } from 'src/app/model/orm/currency.model';
import {Lang} from "../../model/orm/lang.model";

@Component({
    selector: "costperwear-slider",
    templateUrl: "./costperwearslider.component.html",
    styleUrls: ["./costperwearslider.component.scss"]
})
export class CostPerWearSliderComponent implements OnInit, AfterViewInit {
    @Input() price: number;
    @Input() min: number;
    @Input() max: number;
    @Input() current: number = 20;
    public ready: boolean = false;
    private w: number;
    @ViewChild("wrap", {static: false}) wrapRef: ElementRef;
    private wrap: HTMLElement = null;
    public left: number = -1;
    private a: number;
    private b: number;
    // movement
    private startX: number = 0;
    private startXOffsetted: number = 0;

    constructor(private appService: AppService) {}

    get currentCurrency(): Currency {return this.appService.currentCurrency.value;}
    get currentLang(): Lang {return this.appService.currentLang.value;}

    public ngOnInit(): void {
        this.onDragMove = this.onDragMove.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.current = this.current;
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.max !== this.min) {
                this.wrap = this.wrapRef.nativeElement;
                this.w = this.wrap.offsetWidth;

                if (this.w) {
                    this.b = -this.min;
                    this.a = this.w / (this.max - this.min);
                    this.left = Math.round(this.toLocalCoord(this.current));
                    this.ready = true;
                }
            }
        }, 1);
    }

    public toLocalCoord(x: number): number {
        return this.a * (x + this.b);
    }

    public onDragStart(event: MouseEvent | TouchEvent) {
        this.startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        this.startXOffsetted = this.startX - this.wrap.offsetLeft;
        event.cancelable ? event.preventDefault() : null; // sometimes touch events throws errors on "preventDefault" call without such check

        if (event instanceof MouseEvent) {
            window.addEventListener("mousemove", this.onDragMove);
            window.addEventListener("mouseup", this.onDragEnd);
        } else {
            window.addEventListener("touchmove", this.onDragMove);
            window.addEventListener("touchend", this.onDragEnd);
        }
    }

    private onDragMove(event: MouseEvent | TouchEvent): void {
        const x: number = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
        let nextX: number = x - (this.startX - this.startXOffsetted);
        let canMove: boolean = false;


        if (nextX >= Math.round(this.toLocalCoord(this.min)) && nextX <= this.w) {
          canMove = true;
        }

        if (canMove) {
            this.left = nextX;
            this.current = Math.round(nextX / this.a - this.b);
        }
    }

    private onDragEnd(): void {
        window.removeEventListener("mousemove", this.onDragMove);
        window.removeEventListener("touchmove", this.onDragMove);
        window.removeEventListener("mouseup", this.onDragEnd);
        window.removeEventListener("touchend", this.onDragEnd);
    }

    public formatPrice(p: number): string {
        return `${Math.ceil(p * this.currentCurrency.rate)} ${this.currentCurrency.name}`;
    }
}
