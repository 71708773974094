import { Component, ViewEncapsulation, OnInit, AfterViewInit } from '@angular/core';

import { AppService } from './services/app.service';
import { LangsRepository } from './services/repositories/langs.repository';
import { Router, RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Lang } from './model/orm/lang.model';
import { PagesRepository } from './services/repositories/pages.repository';
import { GalsRepository } from './services/repositories/gals.repository';
import { CatsRepository } from './services/repositories/cats.repository';
import { CurrenciesRepository } from './services/repositories/currencies.repository';
import { Currency } from './model/orm/currency.model';
import { CollectionsRepository } from './services/repositories/collections.repository';
import { CountriesRepository } from './services/repositories/countries.repository';
import { Country } from './model/orm/country.model';
import { SettingsRepository } from './services/repositories/settings.repository';
import { AlldataRepository } from './services/repositories/alldata.repository';
import { DataService } from './services/data.service';
import { CookieService } from 'ngx-cookie-service';

declare var gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
  public langsReady: boolean = false;
  public pagesReady: boolean = false;
  public galsReady: boolean = false;
  public catsReady: boolean = false;
  public currenciesReady: boolean = false;
  public collectionsReady: boolean = false;
  public countriesReady: boolean = false;
  public settingsReady: boolean = false;
  public cart_enabled: boolean = true;
  public isShowWelcomePopup: boolean = false;
  public welcomePopupContent: string = '';
  public preHeaderData: any = null;
  // public isShowPreHeader: boolean = false;

  constructor(
    private appService: AppService,
    private router: Router,
    private langsRepository: LangsRepository,
    private pagesRepository: PagesRepository,
    private galsRepository: GalsRepository,
    private catsRepository: CatsRepository,
    private currenciesRepository: CurrenciesRepository,
    private collectionsRepository: CollectionsRepository,
    private countriesRepository: CountriesRepository,
    private settingsRepository: SettingsRepository,
    private alldataRepository: AlldataRepository,
    private dataService: DataService,
    private cookieService: CookieService
  ) {
  }

  get currentLang(): Lang {
    return this.appService.currentLang.value;
  }

  get active(): boolean {
    return this.settingsRepository.siteActive;
  }

  public async ngOnInit(): Promise<void> {
    await this.initData();
    let cart_enabled = this.settingsRepository.xl.find(x => x.p === 'cart_enabled');
    this.cart_enabled = cart_enabled.v === '1' ? true : false;
    if (!this.cart_enabled) {
      console.log('removed');
      localStorage.removeItem('cart');
    }
  }

  public ngAfterViewInit() {
    if (this.appService.isBrowser) {
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(event => {
          if (event instanceof NavigationEnd) {
            window.scrollY ? setTimeout(() => {
              window.scrollTo(0, 0);
            }, 1) : null;
          }

        });
    }
  }

  private async initLangs(langs = []): Promise<void> {
    if (langs.length > 0) {
      this.langsRepository.build(langs);
    } else {
      await this.langsRepository.load();
    }

    if (this.langsRepository.xl.length) {
      this.buildCurrentLang(this.router.url.split('/')[1]);
      this.langsReady = true;
      this.router.events
        .pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
          this.buildCurrentLang(event.url.split('/')[1]);
        });
    }
  }

  private buildCurrentLang(urlPart: string): void {
    if (urlPart && urlPart != '404' && urlPart != '403') {
      let currentLang: Lang | null = this.langsRepository.xl.find(x => x.slug === urlPart) || null;

      if (currentLang) {
        if (!this.appService.currentLang.value || this.appService.currentLang.value.id !== currentLang.id) { // first set or change
          this.appService.currentLang.next(currentLang);
        }
      } else {
        this.appService.currentLang.next(this.langsRepository.xl[0]);
        this.router.navigateByUrl(`/${ this.appService.currentLang.value.slug }/404`);
      }
    } else {
      this.appService.currentLang.next(this.langsRepository.xl[0]);
    }
  }

  private async initCurrencies(currencies = []): Promise<void> {
    if (currencies.length > 0) {
      this.currenciesRepository.build(currencies);
    } else {
      await this.currenciesRepository.load();
    }

    if (this.appService.isServer) { // server
      this.appService.currentCurrency.next(this.currenciesRepository.xl[0]);
    } else { // browser
      let strCurrencyId: string = localStorage.getItem('currency');

      if (strCurrencyId) { // currency saved in localStorage
        let nCurrencyId: number = parseInt(strCurrencyId);
        let currency: Currency = this.currenciesRepository.xl.find(x => x.id === nCurrencyId);

        if (currency) { // saved currency found in repo
          this.appService.currentCurrency.next(currency);
        } else { // saved currency not found in repo
          this.appService.currentCurrency.next(this.currenciesRepository.xl[0]);
          localStorage.setItem('currency', this.currenciesRepository.xl[0].id.toString());
        }
      } else { // no currency in localStorage
        this.appService.currentCurrency.next(this.currenciesRepository.xl[0]);
        localStorage.setItem('currency', this.currenciesRepository.xl[0].id.toString());
      }
    }

    this.currenciesReady = true;
  }

  public initImageView() {
    this.appService.currentImageView = 'product';
    if (this.appService.isBrowser) {
      let imageView: string = localStorage.getItem('imageView');
      if (['model', 'product'].includes(imageView)) {
        this.appService.currentImageView = imageView;
      }
    }
    if (localStorage) localStorage.setItem('imageView', this.appService.currentImageView);
  }

  private initCountries(countries = []): void {
    try {
      this.appService.currentLang.subscribe(async lang => {
        if (lang) {
          this.countriesRepository.sortBy = `name_${ lang.slug }`;
          if (countries.length > 0) {
            this.countriesRepository.build(countries);
          } else {
            await this.countriesRepository.load();
          }

          this.countriesRepository.xl.sort((a: Country, b: Country) => {
            if (a.name[lang.slug] < b.name[lang.slug]) {
              return -1;
            }
            if (a.name[lang.slug] > b.name[lang.slug]) {
              return 1;
            }
            return 0;
          });
          this.countriesReady = true;
        }
      });
    } catch (err) {
      this.appService.showNotification(err, 'error');
    }
  }

  private async initData(): Promise<void> {
    this.dataService.getPreHeaderData().subscribe(response => {
      if (response.data) {
        this.preHeaderData = response.data;
      }
    });

    this.dataService.getWelcomeModalData().subscribe(response => {
      if (response.data) {
        this.welcomePopupContent = response.data.content;

        const cookie = this.cookieService.get('isShowWelcomePopup');

        if (!cookie) {
          this.startWelcomePopupTimer(response.data.wait_to_show);
        }
      }
    });

    await this.alldataRepository.load();
    try {
      if (this.alldataRepository.alldata.langs.length > 0) {
        this.initLangs(this.alldataRepository.alldata.langs);
      } else {
        this.initLangs();
      }

      if (this.alldataRepository.alldata.currencies.length > 0) {
        this.initCurrencies(this.alldataRepository.alldata.currencies);
      } else {
        this.initCurrencies();
      }

      this.initImageView();

      if (this.alldataRepository.alldata.countries.length > 0) {
        this.initCountries(this.alldataRepository.alldata.countries);
      } else {
        this.initCountries();
      }

      if (this.alldataRepository.alldata.pages.length > 0) {
        this.pagesRepository.build(this.alldataRepository.alldata.pages);
        this.pagesReady = true;
      } else {
        this.pagesRepository
          .load()
          .then(() => this.pagesReady = true)
          .catch(err => this.appService.showNotification(err, 'error'));
      }


      if (this.alldataRepository.alldata.gals.length > 0) {
        this.galsRepository.build(this.alldataRepository.alldata.gals);
        this.galsReady = true
      } else {
        this.galsRepository
          .load()
          .then(() => this.galsReady = true)
          .catch(err => this.appService.showNotification(err, 'error'));
      }

      if (this.alldataRepository.alldata.cats.length > 0) {
        this.catsRepository.build(this.alldataRepository.alldata.cats);
        this.catsReady = true
      } else {
        this.catsRepository
          .load()
          .then(() => this.catsReady = true)
          .catch(err => this.appService.showNotification(err, 'error'));
      }

      if (this.alldataRepository.alldata.collections.length > 0 || this.alldataRepository.alldata.collections.length === 0) {
        this.collectionsRepository.build(this.alldataRepository.alldata.collections);
        this.collectionsReady = true;
      } else {
        this.collectionsRepository
          .load()
          .then(() => this.collectionsReady = true)
          .catch(err => this.appService.showNotification(err, 'error'));
      }

      if (this.alldataRepository.alldata.settings.length > 0) {
        this.settingsRepository.build(this.alldataRepository.alldata.settings);
        this.settingsReady = true
      } else {
        this.settingsRepository
          .load()
          .then(() => this.settingsReady = true)
          .catch(err => this.appService.showNotification(err, 'error'));
      }
    } catch (err) {
      this.appService.showNotification(err, 'error');
    }
  }

  public startWelcomePopupTimer(time: number) {
    setTimeout(() => {
      this.isShowWelcomePopup = true;

      const today = new Date();
      const nextThreeDays = new Date(today.setDate(today.getDate() + 3));

      document.cookie = 'isShowWelcomePopup=true;path=/; expires=' + nextThreeDays + ';';
      // this.cookieService.set('isShowWelcomePopup', 'true', nextThreeDays, '/');
    }, time * 1000);
  }

  public onPopupActiveChange(data) {}
}
