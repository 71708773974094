import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Page } from 'src/app/model/orm/page.model';
import { AppService } from 'src/app/services/app.service';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
	selector: 'checkoutfinish-page',
	templateUrl: './checkoutfinish.page.html',	
})
export class CheckoutFinishPage implements OnInit {
    public page: Page = null;
    public pageReady: boolean = false;
    public orderNo: string = "";
	
	constructor(
		private appService: AppService,		
		private route: ActivatedRoute,
		private router: Router,
        private pagesRepository: PagesRepository,        
	) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}	    
	
	public ngOnInit(): void {
        this.appService.headerAlwaysOpaque = true;		
        this.appService.catmenumobileActive = false;	
        this.appService.collmenumobileActive = false;	
        
        // page
        this.page = this.pagesRepository.xl.find(x => x.slug === "checkout-finish") || null;

        if (!this.page) {
            this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
        } else {
            this.pageReady = true;
            this.route.params.subscribe(p => {
                this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);
                this.orderNo = p["order"];
            });					
        }        
    }
}
