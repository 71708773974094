import { Injectable } from "@angular/core";

import { SimpleRepository } from './_simple.repository';
import { DataService } from '../data.service';
import { Review } from 'src/app/model/orm/review.model';
import { IGetchunkDTO } from 'src/app/model/dto/getchunk.dto';

@Injectable()
export class ReviewsByItemRepository extends SimpleRepository<Review> {
    public filterItem: number = 0;    

    constructor(protected dataService: DataService) {
        super();         
        this.chunkLength = 5;        
    }

    public loadReviews(): Promise<void> {
        return new Promise((resolve, reject) => {
            let dto: IGetchunkDTO = {
                from: this.currentPart * this.chunkLength,
                q: this.chunkLength,                
            };
            this.dataService.reviewsChunkByItem(this.filterItem, dto).subscribe(res => {                
                if (res.statusCode === 200) {
                    const data: Review[] =  res.data.length ? res.data.map(d => new Review().build(d)) : [];                      
                    this.xl = [...this.xl, ...data];                    
                    resolve();
                } else {                        
                    reject(res.error);
                }
            }, err => {
                reject(err.message);
            });            
        });
    }    
}
