import { Component } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
    selector: "recover-panel",
    templateUrl: "./recoverpanel.component.html",
    styleUrls: ["../../common.styles/account.panel.scss"]
})
export class RecoverPanelComponent {
    public email: string = "";        
    public errorEmail: string = "&nbsp;";
    
    public formLoading: boolean = false;
    public formMsg: string = "&nbsp;"
    public formError: boolean = false;    
    
    constructor(
        private appService: AppService,
        private authService: AuthService,
    ) {}

    get active(): boolean {return this.appService.recoverpanelActive;}
    set active(v: boolean) {this.appService.recoverpanelActive = v;}
    get currentLang(): Lang {return this.appService.currentLang.value;}
    set loginpanelActive(v: boolean) {this.appService.loginpanelActive = v;}

    public async recover(): Promise<void> {
        if (!this.validate()) {
            return;
        }

        try {
            this.formLoading = true;
            const status: number = await this.authService.recover(this.email);
            this.formLoading = false;

            if (status === 200) {  
                this.formMsg = this.currentLang.s['user']['recover-ok'];              
                setTimeout(() => {this.formMsg = "&nbsp;";}, 3000);                
            } else if (status === 404) {
                this.formMsg = this.currentLang.s['user']['recover-notfound'];
                this.formError = true;
                setTimeout(() => {this.formMsg = "&nbsp;"; this.formError = false;}, 3000);
            } else {
                this.formMsg = this.currentLang.s['common']['error'];
                this.formError = true;
                setTimeout(() => {this.formMsg = "&nbsp;"; this.formError = false;}, 3000);
            }
        } catch (err) {
            this.appService.showNotification(err, "error");
            this.formLoading = false;
        }
    }

    private validate(): boolean {
        this.email = this.email.trim();        
        let error: boolean = false;        

        if (!this.email.length) {
            error = true;
            this.errorEmail = this.currentLang.s['common']['error-required'];
        } else if (!this.appService.validateEmail(this.email)) {
            error = true;
            this.errorEmail = this.currentLang.s['common']['error-emailincorrect'];
        } else {
            this.errorEmail = "&nbsp;";
        }
        
        return !error;
    }
}
