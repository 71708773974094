import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, AfterViewInit, HostListener } from "@angular/core";
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: "accordion-section",
    templateUrl: "./accordionsection.component.html",
    styleUrls: ["./accordionsection.component.scss"]
})
export class AccordionSectionComponent implements OnChanges, AfterViewInit {
    @Input() head: string;
    @Input() content: string;
    @ViewChild("contentDiv", {static: false}) contentRef: ElementRef;
    private contentElement: HTMLElement;
    private layoutReady: boolean;
    private h: number = 0;
    public active: boolean = false;
    private winWidth: number = 0;

    constructor(private appService: AppService) {}

    get isBrowser(): boolean {return this.appService.isBrowser;}
    get isServer(): boolean {return this.appService.isServer;}

    public ngOnChanges(changes: SimpleChanges): void {
        this.layoutReady ? this.reinit() : null;
    }

    public ngAfterViewInit(): void {
        if (this.isBrowser) {
            setTimeout(() => {
                this.winWidth = window.innerWidth;
                this.contentElement = this.contentRef.nativeElement;
                this.h = this.contentElement.scrollHeight;            
                this.layoutReady = true;
            }, 1);
        }        
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth != this.winWidth) {
            this.reinit();
        } 
    }

    private reinit(): void {
        this.deactivate();
        setTimeout(() => {this.h = this.contentElement.scrollHeight;}, 400);
        this.winWidth = window.innerWidth;
    }

    private activate(): void {
        this.contentElement.style.height = `${this.h+10}px`;
        this.active = true;
    }

    private deactivate(): void {
        this.contentElement.style.height = "0px";
        this.active = false;
    }

    public toggle(): void {
        this.active ? this.deactivate() : this.activate();
    }
}
