<div id="itemspage">
    <!-- head -->
    <div id="ip-head" [style.background-image]="'url(\'/images/pages/'+page.img+'\')'" *ngIf="pageReady">
        <div id="ip-head-content">
            <h1>{{currentLang.s['search']['results']}} "{{search}}"</h1>
        </div>        
    </div>
    <!-- filter -->
    <the-filter [ready]="filterReady" [filter]="filter" [showInARow]="showInARow" (showInARowChanged)="onShowInARowChanged($event)" (apply)="applyFilters();"></the-filter>
    <!-- items -->    
    <ng-container *ngIf="itemsReady">
        <ng-container *ngIf="filteredItems.length">
            <div id="ip-items" [class.one-in-a-row]="showInARow==1">        
                <div *ngFor="let item of filteredItems">
                    <cat-item [item]="item"></cat-item>            
                </div>               
            </div>    
        </ng-container>
        <ng-container *ngIf="!filteredItems.length">
            <div class="compact">
                <h2 class="padded">{{currentLang.s['search']['notfound']}}</h2>
            </div>            
        </ng-container>        
    </ng-container> 
    <ng-container *ngIf="!itemsReady">
        <div class="compact smallpadded">
            <div class="loading">{{currentLang.s['common']['loading']}}</div>
        </div>
    </ng-container>
</div>
