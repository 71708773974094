import { Component } from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { INotification } from 'src/app/model/notification.interface';

@Component({
    selector: "the-notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"]
})
export class NotificationComponent {
    constructor(private appService: AppService) {}

    get notification(): INotification {return this.appService.notification;}
}