import { NgModule } from '@angular/core';
import { NoSanitizePipe } from './nosanitize.pipe';

@NgModule({
    imports: [],
    declarations: [NoSanitizePipe],
    exports: [NoSanitizePipe],
    providers: [],
})
export class PipesModule {}
