<ng-container *ngIf="pageReady && authenticated">    
    <div class="compact padded">    
        <div class="page">
            <div class="page-left">
                <div class="simplemenu">
                    <ul>
                        <li *ngFor="let p of pagesAcc"><a [routerLink]="'/'+currentLang.slug+'/account/'+p.slug">{{p.name[currentLang.slug]}}</a></li>
                    </ul>
                </div>
                <div class="slidemenu">
                    <slide-menu [head]="page.name[currentLang.slug]" urlprefix="account" [pages]="pagesAcc"></slide-menu>
                </div>
            </div>
            <div class="page-right">
                <div id="breadcrumbs">
                    <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>            
                </div>        
                <h1 [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></h1>                
                <ng-container *ngIf="ordersReady">
                    <div id="orders">
                        <ng-container *ngIf="orders.length">
                            <table>
                                <tr><th>#</th><th class="left">{{currentLang.s['history']['created_at']}}</th><th class="right">{{currentLang.s['history']['total']}}</th></tr>
                                <tr *ngFor="let order of orders" routerLink="/{{currentLang.slug}}/account/history/{{order.id}}">
                                    <td class="underline">{{order.id}}</td>
                                    <td class="left">{{order.created_at}}</td>
                                    <td class="right">{{order.total}} {{order.currency}}</td>
                                </tr>
                            </table>                        
                        </ng-container>
                        <ng-container *ngIf="!orders.length">{{currentLang.s['history']['empty']}}</ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="!ordersReady">
                    <div class="compact smallpadded"><div class="loading">{{currentLang.s['common']['loading']}}</div></div>
                </ng-container>  
            </div>        
        </div>            
    </div>        
</ng-container>
