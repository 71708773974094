import { Component, OnInit, NgModule } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';
import { CatsRepository } from 'src/app/services/repositories/cats.repository';
import { Cat } from 'src/app/model/orm/cat.model';
import { ItemsByCatRepository } from 'src/app/services/repositories/itemsbycat.repository';
import { Item } from 'src/app/model/orm/item.model';
import { FilterByCatRepository } from 'src/app/services/repositories/filterbycat.repository';
import { IFilter } from 'src/app/model/filter.interface';
import { ItemsService } from 'src/app/services/items.service';

@Component({
    selector: "cat-page",
    templateUrl: "./cat.page.html",
    styleUrls: ["../../../common.styles/items.page.scss"]
})

export class CatPage implements OnInit {
    public catReady: boolean = false;
    public itemsReady: boolean = false;
    public filterReady: boolean = false;    
    public cat: Cat = null;
    public filteredItems: Item[] = [];
    private slug: string = null;
    public showInARow = 2;
    
    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private catsRepository: CatsRepository,
        private itemsByCatRepository: ItemsByCatRepository,
        private filterByCatRepository: FilterByCatRepository,
        private itemsService: ItemsService,
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}    
    get items(): Item[] {return this.itemsByCatRepository.xl;}
    get filter(): IFilter {return this.filterByCatRepository.filter;}    

    public ngOnInit(): void {
        this.appService.headerAlwaysOpaque = false;
        this.appService.catmenumobileActive = true;	
        this.appService.collmenumobileActive = false;		
        this.route.params.subscribe(async p => {            
            if (p["cat"] !== this.slug) {
                try {
                    this.catReady = false;    
                    this.itemsReady = false;   
                    this.filterReady = false;                                             
                    this.slug = p["cat"];
                    this.cat = this.catsRepository.xl.find(x => x.slug === this.slug);
                    if (!this.cat) {
                        this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
                    } else {
                        this.setMeta();
                        this.catReady = true;
                        this.itemsByCatRepository.xl = [];                        
                        this.itemsByCatRepository.filterCat = this.cat.id;				
                        await this.itemsByCatRepository.load();	
                        this.filteredItems = this.items;
                        this.filteredItems.sort((a: Item, b: Item) => {
                            if(a.sort < b.sort){
                                return -1;
                            }
                            if(a.sort > b.sort){
                                return 1;
                            }
                            return 0;
                        });				
                        this.itemsReady = true;  
                        this.filterByCatRepository.catId = this.cat.id;                         
                        await this.filterByCatRepository.load();
                        this.filterReady = true;                 
                    } 
                } catch (err) {
                    this.appService.showNotification(err, "error");
                }              
            } else {
                this.setMeta();
            }           
        });
    }
    
    private setMeta(): void {
        this.appService.setTitle(this.cat.title[this.currentLang.slug] || this.cat.name[this.currentLang.slug]);
        this.appService.setMeta("keywords", this.cat.keywords[this.currentLang.slug]);
        this.appService.setMeta("description", this.cat.description[this.currentLang.slug]);
    }

    public applyFilters(): void {
        this.filteredItems = this.itemsService.applyFilters(this.items, this.filter);
        this.filteredItems.sort((a: Item, b: Item) => {
            if(a.sort < b.sort){
                return -1;
            }
            if(a.sort > b.sort){
                return 1;
            }
            return 0;
        });
    }

    public onShowInARowChanged(count): void {
        this.showInARow = count;
    }
}
