<div id="cartpanel" [class.active]="active">
    <div id="cp-left" (click)="active = false;"></div>
    <div id="cp-right">
        <div id="cp-head1">
            <span *ngIf="cart.records.length">{{currentLang.s['cart']['head1']}}: {{cartQ}} {{currentLang.s['cart']['pcs']}}</span>
            <div id="cp-close" (click)="active = false;"></div>
        </div>
        <ng-container *ngIf="cart.records.length">
            <div id="cp-head2"></div>
            <div id="cp-items">
                <div class="cp-item" *ngFor="let r of cart.records">
                    <div class="cpi-left">
                        <div class="cpi-imgwrap" [style.background-image]="getItemImg(r.item)"></div>
                    </div>
                    <div class="cpi-right">
                        <div class="cpi-name">{{r.item.name[currentLang.slug]}}</div>
                        <div class="cpi-price">{{getItemPrice(r.item)}} {{currentCurrency.name}}</div>
                        <div class="cpi-size">{{currentLang.s['cart']['size']}}: <span>{{r.size.name}}</span></div>
                        <div class="cpi-color" *ngIf="r.item.color">{{currentLang.s['cart']['color']}}: <span>{{r.item.color.name[currentLang.slug]}}</span></div>
                        <div class="cpi-q">
                            <div class="cpiq-adjust" (click)="decrease(r);">&ndash;</div>
                            <div>{{r.q}}</div>
                            <div class="cpiq-adjust" (click)="increase(r);">+</div>
                        </div>
                        <div class="cpi-error" *ngIf="r.errorBeforeCheckout">
                          {{ r.errorBeforeCheckoutQuantity === 0 ? 'На жаль, це був last size у цьому розмірі. Наразі виріб на етапі оформлення замовлення у іншого клієнта' : 'На жаль, наразі доступних виробів до замовлення: ' + r.errorBeforeCheckoutQuantity }}
                        </div>
                        <div class="cpi-delete" (click)="remove(r);"></div>
                    </div>
                </div>
            </div>
            <!--
            <div id="cp-bag">
                <checkbox [(value)]="cart.bag" (valueChange)="saveCart();" [label]="bagLabel"></checkbox>
            </div>
            -->
            <div id="cp-checkout">
              <div class="cp-checkout-title">Для резерву замовлення перейдіть на наступний етап</div>

              <button type="button" (click)="onCheckout()">{{cartS}} {{currentCurrency.name}} - {{currentLang.s['cart']['checkout']}}</button>
            </div>
            <div *ngIf="!anyCheckoutCountError" id="cp-cart" routerLink="/{{currentLang.slug}}/shop/cart" (click)="active = false; mobmenuActive = false;">{{currentLang.s['cart']['view']}}</div>
            <div *ngIf="anyCheckoutCountError" class="cp-checkout-error">Щоб перейти до оформлення замовлення, видаліть не доступний виріб з кошику</div>
            <ng-container *ngIf="cartRelated.length">
                <div id="cp-recommended-head">{{currentLang.s['cart']['recommended']}}</div>
                <div (click)="active = false; mobmenuActive = false;">
                    <cp-recommended [items]="cartRelated"></cp-recommended>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!cart.records.length">
            <div id="cp-empty">{{currentLang.s['cart']['empty']}}</div>
        </ng-container>
    </div>
</div>
