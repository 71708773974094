<div class="waitlist" [class.active]="active" (click)="onClick($event);">
    <div class="wl-win">
        <div class="wl-close" (click)="close();"></div>
        <div class="wl-content">
            <div class="wl-head">{{currentLang.s['waitlist']['join']}}</div>
            <div class="wl-info">{{currentLang.s['waitlist']['info']}}</div>
            <div class="wl-form">
                <form (submit)="send();">
                    <div class="wl-form-row" *ngIf="item.sizes.length">
                        <label>{{currentLang.s['waitlist']['size']}}:</label>
                        <div class="wl-form-elem select">
                            <select [(ngModel)]="sizeId" name="sizeId">
                                <option *ngFor="let s of sizes" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="wl-form-row">
                        <div class="wl-form-elem">
                            <input type="text" [(ngModel)]="phone" (input)="validatePhoneNo($event, $event.target)" placeholder="(___) ___-____" max="14" name="phone" placeholder="{{currentLang.s['waitlist']['phone']}}">
                        </div>
                    </div>
<!--                    <div class="wl-form-row">-->
<!--                        <div class="wl-subscribe">-->
<!--                            <checkbox [(value)]="subscribe" [label]="currentLang.s['waitlist']['subscribe']"></checkbox>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="wl-form-row">
                        <div class="wl-form-btnwrap">
                            <button type="submit" *ngIf="!sent" [disabled]="sending">OK</button>
                            <div *ngIf="sent" class="wl-info">{{currentLang.s['waitlist']['response']}}</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
