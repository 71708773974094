import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from "@angular/core";
import { AppService } from 'src/app/services/app.service';
import { Lang } from 'src/app/model/orm/lang.model';

@Component({
  selector: "the-nosizemodal",
  templateUrl: "./nosizemodal.component.html",
  styleUrls: ["./nosizemodal.component.scss"]
})
export class NosizemodalComponent implements OnChanges {
  @Output() activeChange: EventEmitter<boolean> = new EventEmitter();
  @Input() active: boolean;
  @Input() noizeModalClose: EventEmitter<boolean>;

  constructor(
    private appService: AppService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {

  }

  get currentLang(): Lang {return this.appService.currentLang.value;}

  public close() {
    this.activeChange.emit(false);
    this.noizeModalClose.emit();
  }

  public onClick(event: MouseEvent | TouchEvent): void {
    let target: HTMLElement = event.target as HTMLElement;
    target.classList.contains("nosizemodal") ? this.close() : null;
  }

  public async send(): Promise<void> {
    this.close()
  }
}
