<div class="gip-popup" [class.shown]="shown">
    <div class="gip">
        <div class="gip-left">
            <ng-container *ngIf="dataReady">
                <div class="gip-thumb" *ngFor="let p of pictures; let i = index;" [class.active]="step === i" (click)="moveTo(i);   ">
                    <div class="gip-thumb-content" [style.background-image]="'url(/images/'+p.img_s+')'"></div>
                </div>
            </ng-container>        
        </div>
        <div class="gip-right">
            <div class="gip-wrap" #galwrap>            
                <div 
                    class="gip-container" 
                    #galcontainer
                    [style.left]="-left+'px'"
                    (touchstart)="onDragStart($event);"
                    (mousedown)="onDragStart($event);"
                    (transitionend)="adjustInfiniteMotion()"
                >
                    <ng-container *ngIf="dataReady">
                        <div class="gip-item"
                                *ngFor="let x of xl">

                            <img class="gip-item-image"
                                [src]="'/images/'+x?.img" [style.width]="w+'px'" />
                        </div>
                    </ng-container>                
                </div>
                <div class="gip-toleft" (click)="moveLeft();"></div>
                <div class="gip-toright" (click)="moveRight();"></div>
                <div class="gip-switch">
                    <ng-container *ngIf="dataReady">
                        <div class="gip-switch-item" *ngFor="let p of pictures; let i = index;" [class.active]="step === i" (click)="moveTo(i);"></div>        
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="close" (click)="close()"></div>
</div>
