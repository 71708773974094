<ng-container *ngIf="pageReady">    
    <div class="compact padded">    
        <div id="breadcrumbs">
            <span><a routerLink="/{{currentLang.defended ? '' : currentLang.slug}}">{{currentLang.s['common']['home']}}</a></span>            
            <span><a routerLink="/{{currentLang.slug}}/shop/cart">{{currentLang.s['common']['cart']}}</a></span>            
        </div>        
        <h1 [innerHTML]="page.h1[currentLang.slug] || page.name[currentLang.slug]"></h1>
        <div id="checkout-start">
            <div id="chs-top">
                <button type="button" routerLink="/{{currentLang.slug}}/shop/checkout-continue">{{currentLang.s['checkout']['asguest']}}</button>
            </div>
            <div id="chs-or"><span>{{currentLang.s['common']['or']}}</span></div>
            <div id="chs-bottom">
                <h2>{{currentLang.s['checkout']['login']}}</h2>
                <form (submit)="login();">
                    <div class="chs-inputwrap"><input type="text" name="email" placeholder="{{currentLang.s['user']['email']}}" [(ngModel)]="email"></div>
                    <div class="chs-inputwrap"><input type="password" name="password" placeholder="{{currentLang.s['user']['password']}}" [(ngModel)]="password"></div>                    
                    <div id="chs-btnwrap"><button type="submit" [disabled]="loading">{{currentLang.s['user']['signin']}}</button></div>
                    <div id="chs-msg" [innerHTML]="msg" [class.error]="error"></div>
                    <div id="chs-forgot" (click)="recoverpanelActive = true;">{{currentLang.s['user']['forgot']}}</div>
                </form>
            </div>
        </div>
    </div>        
</ng-container>
