import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePage } from './section.components/home/home.page';
import { StaticModule } from './section.components/static/static.module';
import { NotfoundPage } from './section.components/errors/notfound.page';
import { ShopModule } from './section.components/shop/shop.module';
import { CollectionsModule } from "./section.components/collections/collections.module";
import { AboutModule } from './section.components/about/about.module';
import { AccountModule } from './section.components/account/account.module';

const routes: Routes = [
	{path:"", component: HomePage},
	{path:":lang", component: HomePage},	
	{path:":lang/404", component: NotfoundPage, pathMatch: "full"},	
	{path:":lang/shop", loadChildren: () => ShopModule},	
	{path:":lang/collections", loadChildren: () => CollectionsModule},	
	{path:":lang/about", loadChildren: () => AboutModule},	
	{path:":lang/account", loadChildren: () => AccountModule},	
	{path:":lang/:page", loadChildren: () => StaticModule}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {initialNavigation: 'enabled', scrollPositionRestoration: 'disabled'})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
