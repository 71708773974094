import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';

import { AppService } from 'src/app/services/app.service';
import { Page } from 'src/app/model/orm/page.model';
import { PagesRepository } from 'src/app/services/repositories/pages.repository';
import { Lang } from 'src/app/model/orm/lang.model';
import { WishlistService } from 'src/app/services/wishlist.service';
import { Wishlist } from 'src/app/model/wishlist';

@Component({
    selector: "wishlist-page",
    templateUrl: "./wishlist.page.html",
    styleUrls: ["../../../common.styles/items.page.scss"]
})
export class WishlistPage implements OnInit {
    public page: Page = null;
    public pageReady: boolean = true;
    public showInARow = 3;
    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private pagesRepository: PagesRepository,
        private wishlistService: WishlistService
    ) {}

    get currentLang(): Lang {return this.appService.currentLang.value;}
    get wishlist(): Wishlist {return this.wishlistService.wishlist;}

    public ngOnInit(): void {
        this.appService.headerAlwaysOpaque = false;
        this.appService.catmenumobileActive = false;
        this.appService.collmenumobileActive = true;
        // page
        this.page = this.pagesRepository.xl.find(x => x.slug === "wishlist") || null;

        if (!this.page) {
            this.router.navigateByUrl(`/${this.currentLang.slug}/404`);
        } else {
            this.pageReady = true;
            this.route.params.subscribe(p => {
                this.appService.setTitle(this.page.title[this.currentLang.slug] || this.page.name[this.currentLang.slug]);
            });
        }
    }
}
